import { Box } from "@material-ui/core"
import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  required,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  useGetOne,
} from "react-admin"

export const GlobalsEdit = (props) => (
  <Edit {...props} title="Modifica">
    <SimpleForm redirect="list">
      <ValueInput />
    </SimpleForm>
  </Edit>
)

const ValueInput = ({ record = {}, resource }) => {
  const { data } = useGetOne("Globals", record.id)
  return (
    <Box>
      <Box display="flex">
        <Box flex={1} mr="1.5em">
          <TextInput
            source="description"
            label="Descrizione"
            record={record}
            fullWidth
            multiline
            resource={resource}
            validate={required()}
          />
        </Box>
        <Box flex={1} ml="1.5em">
          {record.type === "number" && (
            <NumberInput
              source="value"
              label="Valore"
              record={record}
              resource={resource}
              inputProps={
                record.id.toLowerCase().includes("percentage")
                  ? { min: 0.01, step: 0.01, max: 1 }
                  : record.id.toLowerCase().includes("amount")
                  ? { min: 0.01, step: 0.01 }
                  : {}
              }
              validate={required()}
            />
          )}
          {record.type === "string" && (
            <TextInput
              source="value"
              record={record}
              fullWidth
              multiline
              resource={resource}
              validate={required()}
            />
          )}
        </Box>
      </Box>
      {record.type === "array" && (
        <ArrayInput fullWidth source="value" record={record}>
          <SimpleFormIterator disableAdd disableRemove>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => (
                <>
                  {data &&
                    Object.keys(
                      data?.value[
                        getSource("order").split("[")[1].split("]")[0]
                      ] ?? []
                    )
                      .filter((e) => e !== "order")
                      .map((e) =>
                        data?.value[getSource(e).split("[")[1].split("]")[0]]?.[
                          e
                        ] ?? false ? (
                          <TextInput
                            key={e}
                            source={getSource(e)}
                            validate={required()}
                            fullWidth
                            label={`resources.${resource}.fields.array_values.${e}`}
                            record={scopedFormData}
                            resource={resource}
                            multiline
                          />
                        ) : null
                      )}
                </>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      )}
    </Box>
  )
}
