import {
  Create,
  DateInput,
  Edit,
  FileField,
  FileInput,
  NumberInput,
  SimpleForm,
} from "react-admin"

const redirectCreatePayment = (basePath, id, data) =>
  `/Quotes/${data.quote_ID}/show/payments`

export const InvoiceCreate = (props) => {
  return (
    <Create {...props} title="Crea Pagamento">
      <SimpleForm redirect={redirectCreatePayment}>
        <NumberInput label="Numero fattura" source="number" />
        <DateInput label="Data di fatturazione" source="date" />
        <FileInput
          source="files"
          label="File"
          multiple={false}
          accept="application/pdf"
        >
          <FileField source="fileUrl" title="title" />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}

export const InvoiceEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect={redirectCreatePayment}>
      <DateInput label="Data di fatturazione" source="" />
      <NumberInput label="Numero fattura" source="" />
      <FileInput source="files" label="Related files" accept="application/pdf">
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Edit>
)
