import { AvatarNameField } from "../../Resources/User/customFields"
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core"
import { Link as LinkIcon } from "@material-ui/icons"
import {
  Button,
  Datagrid,
  DateField,
  Link,
  ListBase,
  ReferenceField,
  ResourceContextProvider,
  TextField,
} from "react-admin"

export const QuoteReview = () => {
  return (
    <Card>
      <CardHeader
        title={<Typography variant="h5">Ultime richieste ricevute</Typography>}
        action={
          <Button
            size="small"
            color="primary"
            component={Link}
            label="Vai alle richieste"
            to={{
              pathname: "/Quotes",
            }}
          >
            <LinkIcon />
          </Button>
        }
      />
      <CardContent>
        <ResourceContextProvider resource="Quotes">
          <ListBase
            basePath="/Quotes"
            resource="Quotes"
            filter={{
              status: 0,
            }}
            perPage={10}
            sort={{ field: "requestDate", order: "DESC" }}
          >
            <Datagrid rowClick="show">
              <DateField
                label="Data richiesta"
                source="requestDate"
                showTime
                emptyText="-"
              />

              <ReferenceField
                label="Utente"
                source="driver_ID"
                reference="Drivers"
                link="show"
              >
                <AvatarNameField label="Utente" />
              </ReferenceField>
              <ReferenceField
                label="Veicolo"
                source="car_ID"
                reference="Cars"
                link="show"
              >
                <TextField label="plate" />
              </ReferenceField>
            </Datagrid>
          </ListBase>
        </ResourceContextProvider>
      </CardContent>
    </Card>
  )
}
