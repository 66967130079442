import { Create, required, SimpleForm, TextInput } from "react-admin"

/*
    Fake Resource per avere la pagina custom di condivisione notifica dell'offerta (Accessibile da dettagli quotes)
    C'è cambio di indirizzo richiesta in 
*/

const redirectShareOfferNotificationToUser = (basePath, id, data) => {
  console.log({ basePath, id, data })
  return `/Quotes`
}

export const shareOfferNotificationToUserCreate = (props) => (
  <Create {...props} title="Condividi la notifica dell'offerta all'utente">
    <SimpleForm redirect={redirectShareOfferNotificationToUser}>
      <TextInput label="Titolo notifica" source="title" validate={required()} />
      <TextInput label="Testo notifica" source="body" validate={required()} />
    </SimpleForm>
  </Create>
)
