import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import { Cancel as IconCancel, Send as IconSend } from "@material-ui/icons"
import { useState } from "react"
import {
  Button,
  FormWithRedirect,
  ReferenceArrayInput,
  SaveButton,
  SelectArrayInput,
  useCreate,
  useNotify,
} from "react-admin"

const validateCreation = (values) => {
  const errors = {}

  const strCampoObbligatorio = "Campo obbligatorio"

  return errors
}
export const ShareToRepShopButton = ({
  label,
  onChange,
  disabled,
  quote_ID,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("ShareLinkToRepairShops")
  //const form = useForm()
  const notify = useNotify()
  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }
  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false)
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          onChange()
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label={"Condividi a carrozzeria"}
        disabled={disabled}
      >
        <IconSend />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        maxWidth="sm"
        onClose={handleCloseClick}
        aria-label="Invia notifiche"
      >
        <DialogTitle>Condividi a Carrozziere</DialogTitle>
        <Divider />
        <FormWithRedirect
          resource="ShareLinkToRepairShops"
          initialValues={{ quote_ID }}
          validate={validateCreation}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ReferenceArrayInput
                  label="Scegli Carrozzerie a cui inviare il preventivo"
                  source="RepairShop_ids"
                  reference="RepairShops"
                  fullWidth
                >
                  <SelectArrayInput
                    className="selectSShareLinkToRepairShopsCreate"
                    optionText="name"
                  />
                </ReferenceArrayInput>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}
