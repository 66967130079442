import DocumentationDialog from "./DocumentationDialog"
import { Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Help as HelpIcon } from "@material-ui/icons"
import { useState } from "react"
import { useRouteMatch } from "react-router-dom"

const useStyles = makeStyles({
  fab: {
    "z-index": 1000,
  },
})

const DocumentationButton = () => {
  const classes = useStyles()
  const [openDialogState, setOpenDialogState] = useState(false)
  const match = useRouteMatch("/:resourceName")

  const resourceName = match?.params.resourceName ?? "Dashboard"

  const openDialog = () => {
    setOpenDialogState(true)
  }

  const closeDialog = () => {
    setOpenDialogState(false)
  }

  return (
    <>
      <Button
        color="black"
        className={classes.fab}
        onClick={openDialog}
        startIcon={<HelpIcon />}
        variant="text"
      >
        Guida
      </Button>
      <DocumentationDialog
        resourceName={resourceName}
        openState={openDialogState}
        closeDialog={closeDialog}
      />
    </>
  )
}

export default DocumentationButton
