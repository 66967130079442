import DocumentationButton from "../Documentation/DocumentationButton"
import { CustomUserMenu } from "./userMenu"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import { AppBar } from "react-admin"

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxHeight: "40px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
}
const CustomAppBar = ({ classes, ...props }) => (
  <AppBar
    {...props}
    userMenu={<CustomUserMenu />}
    style={{ boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)" }}
  >
    <Typography
      variant="h6"
      color="inherit"
      className={classes.title}
      id="react-admin-title"
    />
    <span className={classes.spacer} />
    {/*
    <DocumentationButton />
*/}
    <span className={classes.spacer} />

    <img
      src={process.env.PUBLIC_URL + "/logo.png"}
      alt="logo"
      className={classes.logo}
      style={{ padding: "2px" }}
    />
  </AppBar>
)

export default withStyles(styles)(CustomAppBar)
