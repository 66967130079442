import { deepOrange, lightGreen, red, yellow } from "@material-ui/core/colors"

export const PhoneCallSettings = [
  { id: "disconnected", name: "Disconnesso" },
  { id: "timedOut", name: "Occupato" },
  { id: "success", name: "Chiamata effettuata" },
]

export const PhoneCallRowStyle = (record, index, defaultStyle = {}) => {
  let color = "transparent"
  switch (record?.feedback) {
    case 0:
      color = red[500]
      break

    case 1:
      color = deepOrange[500]
      break

    case 2:
      color = yellow[500]
      break

    case 3:
      color = lightGreen[500]
      break
  }
  return {
    ...defaultStyle,
    borderLeftColor: color,
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    backgroundColor: color,
  }
}
