import { findRegexZIP } from "../../../Helpers/regex"
import { Receipt as QuoteIcon } from "@material-ui/icons"
import { Button, Link } from "react-admin"

export const CreateRelatedQuoteButton = ({ record, resource }) => {
  let data = {}
  switch (resource) {
    case "TowTruckQuotes": {
      let address, zipCode
      let foundZIPs = record.location.match(findRegexZIP) ?? []
      if (foundZIPs.length > 0) {
        zipCode = foundZIPs[0]
        address = record.location.replace(zipCode + " - ", "")
      }
      data = {
        requestDate: record.date,
        driver_ID: record.driver_ID,
        towTruck: true,
        userDetails: `Dettaglio richiesta carroattrezzi: ${
          record.details ?? ""
        }`,
        actualAddress: address,
        actualCAP: zipCode,
        actualLocation: record.actualLocation,
      }
      break
    }
    case "Drivers": {
      data = {
        driver_ID: record.id,
      }
      break
    }
    case "Cars": {
      data = {
        driver_ID: record.driver_ID,
        car_ID: record.id,
      }
      break
    }

    default:
      break
  }
  return (
    record && (
      <Button
        component={Link}
        label="Crea Preventivo"
        to={{
          pathname: "/Quotes/create",
          state: {
            record: data,
          },
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <QuoteIcon />
      </Button>
    )
  )
}
