import { Box, Typography } from "@material-ui/core"
import {
  Create,
  email,
  PasswordInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"

const OperatorCreateForm = ({ resource }) => (
  <Box p="1em">
    <Typography variant="h6" gutterBottom>
      Informazioni operatore
    </Typography>
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput
          source="name"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={2} ml="0.5em" mr="0.5em">
        <TextInput
          source="surname"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={2} ml="0.5em"></Box>
    </Box>
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput
          source="email"
          fullWidth
          validate={(required(), email("Email non valida"))}
          resource={resource}
        />
      </Box>
      <Box flex={1.5} ml="0.5em" mr="0.5em">
        <PasswordInput
          source="password"
          fullWidth
          inputProps={{ minLength: 8 }}
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={1.5} ml="0.5em">
        <PasswordInput
          source="repeat_password"
          fullWidth
          inputProps={{ minLength: 8 }}
          validate={required()}
          resource={resource}
        />
      </Box>
    </Box>
  </Box>
)

const validateOperatorCreation = (values) => {
  const errors = {}
  if (values.repeat_password && values.password !== values.repeat_password) {
    errors.repeat_password = ["Le due password non combaciano"]
  }
  return errors
}

export const OperatorCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      redirect="list"
      warnWhenUnsavedChanges
      validate={validateOperatorCreation}
    >
      <OperatorCreateForm />
    </SimpleForm>
  </Create>
)
