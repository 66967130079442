import { TowTruckQuoteIcon } from "./towTruckQuoteIcon"
import { Button, Link } from "react-admin"

export const CreateRelatedTowTruckQuoteButton = ({ record, resource }) => {
  let data = {}
  switch (resource) {
    case "Drivers":
      data = {
        driver_ID: record.id,
      }
      break

    default:
      break
  }
  return (
    record && (
      <Button
        component={Link}
        label="Richiesta Carrattrezzi"
        to={{
          pathname: "/TowTruckQuotes/create",
          state: {
            record: data,
          },
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <TowTruckQuoteIcon />
      </Button>
    )
  )
}
