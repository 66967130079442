import { ChipCustomField } from "../../Customs/chipCustomField"
import { RatingField } from "../../Customs/ratingField"
import { EmailRowStyle } from "../Email/components/emailSettings"
import { PhoneCallSettings } from "./components/phonecallSettings"
import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  SelectField,
  TextField,
} from "react-admin"

export const PhoneCallGrid = ({ record }) => (
  <ReferenceManyField
    target="driver_ID"
    reference="PhoneCalls"
    sort={{ field: "date", order: "DESC" }}
    addLabel={false}
    pagination={<Pagination />}
    perPage={10}
    record={record}
  >
    <Datagrid rowStyle={EmailRowStyle}>
      <ChipCustomField label="Tipo">
        <SelectField
          source="status"
          emptyText="Nessun tipo"
          choices={PhoneCallSettings}
        />
      </ChipCustomField>
      <DateField source="startTime" showTime={true} allowEmpty emptyText="-" />
      <TextField source="duration" allowEmpty emptyText="-" />
      <RatingField source="feedback" label="Feedback" max={3} />
    </Datagrid>
  </ReferenceManyField>
)
