import { AvatarNameField, TelephoneField } from "./customFields"
import { Divider, Tab, Tabs } from "@material-ui/core"
import { grey, red } from "@material-ui/core/colors"
import { Fragment, useCallback, useEffect, useState } from "react"
import {
  Datagrid,
  EditButton,
  Filter,
  FilterLiveSearch,
  List,
  ListContextProvider,
  TextField,
  useGetList,
  useListContext,
} from "react-admin"

const rowStyle = (record, index, defaultStyle = {}) => {
  return {
    ...defaultStyle,
    borderLeftColor:
      (record?.active ?? true) === false ? red[500] : "transparent",
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    backgroundColor:
      (record?.active ?? true) === false ? grey[100] : "transparent",
  }
}
const useGetTotals = (filterValues) => {
  const { total: totalCompanies } = useGetList(
    "Drivers",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, isCompany: true }
  )
  const { total: totalPeople } = useGetList(
    "Drivers",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, isCompany: false }
  )

  return {
    Privati: totalPeople,
    Aziende: totalCompanies,
  }
}
const UserTabbedList = (props) => {
  const listContext = useListContext()
  const { ids, filterValues, setFilters, displayedFilters } = listContext
  const [people, setPeople] = useState([])
  const [companies, setCompanies] = useState([])

  useEffect(() => {
    if (ids && ids !== filterValues.isCompany) {
      if (filterValues.isCompany === true) setCompanies(ids)
      else setPeople(ids)
    }
  }, [ids, filterValues.isCompany])

  const handleChange = useCallback(
    (event, value) => {
      setFilters &&
        setFilters({ ...filterValues, isCompany: value }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )
  const tabs = [
    { id: 0, name: "Privati" },
    { id: 1, name: "Aziende" },
  ]
  const totals = useGetTotals(filterValues)
  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={!filterValues.isCompany ? 0 : 1}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.id}
            label={
              totals[choice.name]
                ? `${choice.name} (${totals[choice.name]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        <ListContextProvider
          value={{
            ...listContext,
            ids: filterValues.isCompany === false ? people : companies,
          }}
        >
          <Datagrid {...props} optimized rowClick="show" rowStyle={rowStyle}>
            {filterValues.isCompany === true && (
              <TextField label="Azienda" source="companyName" />
            )}
            <AvatarNameField
              label={filterValues.isCompany === false ? "Nome" : "Riferimento"}
            />
            <TextField source="email" sortable={false} />
            <TelephoneField label="Telefono" />
            <EditButton label="Modifica" />
          </Datagrid>
        </ListContextProvider>
      </div>
    </Fragment>
  )
}
export const UserList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={
      <Filter {...props} title="Filtri">
        <FilterLiveSearch source="q" alwaysOn />
      </Filter>
    }
    filterDefaultValues={{ isCompany: false }}
    title="Utenti"
    exporter={false}
  >
    <UserTabbedList />
  </List>
)
