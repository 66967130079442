import { Box } from "@material-ui/core"
import {
  ArrayInput,
  DateInput,
  Edit,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin"

const redirectEditOffer = (basePath, id, data) =>
  `/Quotes/${data.quote_ID}/show/offers`

const validateOfferCreation = (values) => {
  const errors = {}

  const strCampoObbligatorio = "Campo obbligatorio"

  if (!values.repShop_ID) {
    errors.repShop_ID = [strCampoObbligatorio]
  }
  if (!values.total) {
    errors.total = [strCampoObbligatorio]
  } else if (values.discountTotal && values.discountTotal > values.total) {
    errors.discountTotal = ["Il valore scontato è maggiore del valore totale"]
  }

  const dateRitiro = new Date(values.pickupTime)
  const dateConsegna = new Date(values.deliveryTime)

  if (dateRitiro && dateConsegna && dateRitiro > dateConsegna) {
    errors.deliveryTime = ["Data consegna precedente a data ritiro!"]
  }

  return errors
}

export const OfferEdit = (props) => (
  <Edit {...props} title="Crea Offerta">
    <SimpleForm validate={validateOfferCreation} redirect={redirectEditOffer}>
      <DataForm />
    </SimpleForm>
  </Edit>
)

const DataForm = ({ resource }) => {
  return (
    <Box>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <ReferenceInput
            label="Preventivo"
            source="quote_ID"
            reference="Quotes"
            resource={resource}
            disabled
            fullWidth
          >
            <SelectInput
              label="Preventivo"
              optionText="quoteIdentifier"
              fullWidth
            />
          </ReferenceInput>
        </Box>
        <Box flex={3} mr="0.5em" ml="0.5em">
          <ReferenceInput
            label="Carrozzeria"
            source="repShop_ID"
            reference="RepairShops"
            resource={resource}
            fullWidth
          >
            <SelectInput optionText="name" fullWidth />
          </ReferenceInput>
        </Box>
        <Box flex={1} mr="0.5em" ml="0.5em">
          <NumberInput
            label="Totale riparazione"
            source="total"
            resource={resource}
            fullWidth
          />
        </Box>
        <Box flex={1} ml="0.5em">
          <NumberInput
            label="Totale scontato"
            source="discountTotal"
            resource={resource}
            fullWidth
          />
        </Box>
      </Box>

      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <DateInput
            label="Data Ritiro"
            source="pickupTime"
            resource={resource}
            fullWidth
          />
        </Box>
        <Box flex={1} mr="0.5em" ml="0.5em">
          <DateInput
            label="Data di Consegna"
            source="deliveryTime"
            resource={resource}
            fullWidth
          />
        </Box>
        <Box flex={2} ml="0.5em"></Box>
      </Box>
      <ArrayInput source="customAddOns" label="Servizi aggiuntivi">
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => (
              <Box display="flex">
                <Box flex={2} mr="0.5em">
                  <ReferenceInput
                    label="Servizi Aggiuntivi Offerti"
                    source={getSource("addOns")}
                    record={scopedFormData}
                    reference="AddOns"
                    resource={resource}
                    fullWidth
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                </Box>
                <Box flex={3} mr="0.5em" ml="0.5em">
                  <TextInput
                    source={getSource("details")}
                    label="Dettagli"
                    record={scopedFormData}
                    resource={resource}
                    fullWidth
                  />
                </Box>
                <Box flex={1} ml="0.5em">
                  <NumberInput
                    source={getSource("price")}
                    label="Prezzo"
                    resource={resource}
                    record={scopedFormData}
                    fullWidth
                  />
                </Box>
              </Box>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  )
}
