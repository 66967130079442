module.exports = {
  ra: {
    action: {
      add_filter: "Aggiungi un filtro",
      add: "Aggiungi",
      back: "Indietro",
      bulk_actions: "%{smart_count} selezionati",
      cancel: "Annulla",
      clear_input_value: "Svuota il modulo",
      clone: "Duplica",
      confirm: "Conferma",
      create: "Crea",
      delete: "Cancella",
      edit: "Modifica",
      export: "Esporta",
      list: "Elenco",
      refresh: "Aggiorna",
      remove_filter: "Rimuovi questo filtro",
      remove: "Rimuovi",
      save: "Salva",
      search: "Ricerca",
      show: "Mostra",
      sort: "Ordina",
      undo: "Annulla",
      expand: "Espandi",
      close: "Chiudi",
      unselect: "Deseleziona",
    },
    boolean: {
      true: "Si",
      false: "No",
    },
    page: {
      create: "Aggiungi %{name}",
      dashboard: "Home",
      edit: "%{name} %{id}",
      error: "Qualcosa non ha funzionato",
      list: "Lista %{name}",
      loading: "Caricamento in corso",
      not_found: "Non trovato",
      show: "%{name} %{id}",
      empty: "Non esiste alcun record",
      invite: "Aggiungi un nuovo record",
    },
    input: {
      file: {
        upload_several:
          "Trascina i files da caricare, oppure clicca per selezionare.",
        upload_single:
          "Trascina il file da caricare, oppure clicca per selezionarlo.",
      },
      image: {
        upload_several:
          "Trascina le immagini da caricare, oppure clicca per selezionarle.",
        upload_single:
          "Trascina l'immagine da caricare, oppure clicca per selezionarla.",
      },
      references: {
        all_missing: "Impossibile trovare i riferimenti associati.",
        many_missing:
          "Almeno uno dei riferimenti associati non sembra più disponibile.",
        single_missing: "Il riferimento associato non sembra più disponibile.",
      },
      password: {
        toggle_hidden: "Mostra password",
        toggle_visible: "Nascondi password",
      },
    },
    message: {
      about: "Informazioni",
      are_you_sure: "Sei sicuro ?",
      bulk_delete_content:
        "Sei sicuro di voler cancellare questo %{name}? |||| Sei sicuro di voler eliminare questi %{smart_count}?",
      bulk_delete_title:
        "Delete %{name} |||| Delete %{smart_count} %{name} items",
      delete_content: "Sei sicuro di cancellare questo elemento?",
      delete_title: "Cancella %{name} #%{id}",
      details: "Dettagli",
      error:
        "Un errore locale è occorso e la tua richiesta non è stata completata.",
      invalid_form:
        "Il modulo non è valido. Si prega di verificare la presenza di errori.",
      loading: "La pagina si sta caricando, solo un momento per favore",
      no: "No",
      not_found:
        "Hai inserito un URL errato, oppure hai cliccato un link errato",
      yes: "Si",
      unsaved_changes:
        "Stai lasciando la pagina, le modifiche non salvate verranno perse",
    },
    navigation: {
      no_results: "Nessun risultato trovato",
      no_more_results:
        "La pagina numero %{page} è fuori dell'intervallo. Prova la pagina precedente.",
      page_out_of_boundaries: "Il numero di pagina %{page} è fuori dei limiti",
      page_out_from_end: "Fine della paginazione",
      page_out_from_begin: "Il numero di pagina deve essere maggiore di 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} di %{total}",
      page_rows_per_page: "Righe per pagina",
      next: "Successivo",
      prev: "Precedente",
    },
    auth: {
      user_menu: "Profile",
      username: "Nome utente",
      password: "Password",
      sign_in: "Login",
      sign_in_error: "Autenticazione fallita, riprovare.",
      logout: "Disconnessione",
      auth_check_error: "Autenticazione fallita, riprovare.",
    },
    notification: {
      updated: "Record aggiornato |||| %{smart_count} records aggiornati",
      created: "Record creato",
      deleted: "Record eliminato |||| %{smart_count} records eliminati",
      bad_item: "Record errato",
      item_doesnt_exist: "Record inesistente",
      http_error: "Errore di comunicazione con il server dati",
      data_provider_error:
        "Errore del data provider. Controlla la console per i dettagli.",
      canceled: "Azione annullata",
      logged_out: "La sessione è scaduta. Effettua nuovamente l'accesso.",
    },
    validation: {
      required: "Campo obbligatorio",
      minLength: "Deve essere lungo %{min} caratteri almeno",
      maxLength: "Deve essere lungo %{max} caratteri al massimo",
      minValue: "Deve essere almeno %{min}",
      maxValue: "Deve essere al massimo %{max}",
      number: "Deve essere un numero",
      email: "Deve essere un valido indirizzo email",
      oneOf: "Deve essere uno di: %{options}",
      regex: "Deve rispettare il formato (espressione regolare): %{pattern}",
    },
    sort: {
      sort_by: "Ordina per",
      ASC: "ASC",
      DESC: "DESC",
    },
  },
  resources: {
    Quotes: {
      name: "Preventivo |||| Preventivi",
      fields: {},
    },
    WhatsappMessages: {
      name: "Whatsapp |||| Whatsapp",
      fields: {},
    },
    WhatsappConversations: {
      name: "Whatsapp |||| Whatsapp",
      fields: {},
    },
    Cars: {
      name: "Veicolo |||| Veicoli",
      fields: {
        plate: "Targa",
        brand: "Marchio",
        model: "Modello",
        version: "Versione",
        engine_displacement: "Cilindrata",
        carType_ID: "Tipo veicolo",
        driver_ID: "Utente",
      },
    },
    Drivers: {
      name: "Utente |||| Utenti",
      fields: {
        name: "Nome",
        surname: "Cognome",
        email: "Indirizzo email",
        password: "Password",
        repeat_password: "Ripeti password",
        registrationDate: "Data di registrazione",
      },
    },
    Operators: {
      name: "Operatore |||| Operatori",
      fields: {
        name: "Nome",
        surname: "Cognome",
        email: "Indirizzo email",
        password: "Password",
        repeat_password: "Ripeti password",
        registrationDate: "Data di registrazione",
      },
    },
    TowTruckQuotes: {
      name: "Richiesta Carroattrezzi |||| Richieste Carroattrezzi",
      fields: {
        phoneCode: "Prefisso",
        telephone: "Telefono",
        driver_ID: "Utente",
        location: "Posizione del veicolo",
        details: "Dettagli",
        called: "Chiamato",
        date: "Data richiesta",
      },
    },
    Globals: {
      name: "Impostazione |||| Impostazioni",
      fields: {
        description: "Descrizione",
        array_values: {
          title: "Titolo",
          content: "Contenuto",
        },
      },
    },
    AddOns: {
      name: "Servizio Aggiuntivo |||| Servizi Aggiuntivi",
      fields: {
        name: "Nome",
        details: "Dettagli",
      },
    },
    RepairShops: {
      name: "Carrozzeria |||| Carrozzerie",
      fields: {
        intIdentifier: "Codice",
        name: "Denominazione",
        VAT: "Partita IVA",
        telephone: "Telefono",
        phoneCode: "Prefisso",
        waTelephone: "Cellulare",
        waCountryPhonecode: "Prefisso",
        address: "Indirizzo",
        city: "Città",
        ZIP: "CAP",
        province: "Provincia",
        emailAddress: "Indirizzo email",
      },
    },
    Notifications: {
      name: "Notifica |||| Notifiche",
      fields: {
        date: "Data di invio",
        plannedAt: "Data di invio",
        sent: "Inviata",
        received: "Ricevuta",
        actionFired: "Aperta",
        type: "Tipo",
        title: "Titolo",
        body: "Messaggio",
        driver_ID: "Utente",
        quote_ID: "Richiesta",
      },
    },
    SibEmails: {
      name: "Email |||| Email",
      fields: {
        date: "Data di invio",
        plannedAt: "Data di invio",
        sent: "Inviata",
        received: "Ricevuta",
        open: "Aperta",
        type: "Tipo",
        driver_ID: "Utente",
        quote_ID: "Richiesta",
      },
    },
    BillingAddresses: {
      name: "Fatturazione |||| Fatturazioni",
      fields: {
        city: "Città",
        address: "Indirizzo",
        secondLine: "Info aggiuntive",
        ZIPcode: "CAP",
        country: "Paese",
        province: "Provincia",
        fullName: "Nome",
      },
    },
    Payments: {
      name: "Pagamento |||| Pagamenti",
      fields: {},
    },
  },
}
