import { StatusField } from "../../Customs/customFields"
import OperatorChangePasswordButton from "./components/operatorChangePassword"
import { useTranslate } from "ra-core"
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  FunctionField,
  NumberField,
  Pagination,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
} from "react-admin"

const OperatorTitle = ({ record }) => {
  return (
    <span>{record ? `Operatore: ${record.name} ${record.surname}` : ""}</span>
  )
}
const ListActions = ({ basePath, data }) => (
  <TopToolbar>
    <EditButton basePath={basePath} record={data} />
    {data && <OperatorChangePasswordButton basePath={basePath} record={data} />}
    <DeleteButton basePath={basePath} record={data} undoable={false} />
  </TopToolbar>
)
export const OperatorShow = (props) => {
  const translate = useTranslate() // returns the i18nProvider.translate() method
  return (
    <Show
      {...props}
      title={<OperatorTitle />}
      actions={props.permissions === "superadmin" ? <ListActions /> : null}
    >
      <TabbedShowLayout act>
        <Tab label="Anagrafica">
          <TextField source="name" />
          <TextField source="surname" />
          <TextField source="email" />
          <DateField source="registrationDate" />
        </Tab>
        <Tab label="Preventivi gestiti">
          <ReferenceManyField
            target="operator_ID"
            reference="Quotes"
            addLabel={false}
            pagination={<Pagination />}
            perPage={10}
            sort={{ field: "requestDate", order: "DESC" }}
          >
            <Datagrid rowClick="show">
              <DateField
                label="Data richiesta"
                source="requestDate"
                allowEmpty
                showTime={true}
              />
              <StatusField label="Stato" source="status" allowEmpty />
              <TextField label="Identificativo" source="quoteIdentifier" />
              <TextField label="Dettagli" source="operatorDetails" />
              <NumberField
                label="Importo"
                emptyText="-"
                source="finalTotal"
                options={{
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 6,
                }}
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        {props.permissions === "superadmin" && (
          <Tab label="Storia">
            <ReferenceManyField
              target="operator_ID"
              reference="Histories"
              addLabel={false}
              pagination={<Pagination />}
              perPage={10}
              sort={{ field: "timestamp", order: "DESC" }}
            >
              <Datagrid rowClick="show">
                <DateField
                  label="Data richiesta"
                  source="timestamp"
                  allowEmpty
                  showTime={true}
                />
                <FunctionField
                  label="Tipologia di accesso"
                  render={(rec) =>
                    translate(`resources.${rec.originType}s.name`, {
                      smart_count: 1,
                    })
                  }
                />
                <FunctionField
                  label="Operazione"
                  render={(rec) =>
                    rec.method == "GET"
                      ? "Ricerca"
                      : rec.method == "PUT" || rec.method == "POST"
                      ? "Modifica dati / Inserimento"
                      : "Eliminazione"
                  }
                />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  )
}
