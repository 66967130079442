import { RepairShopForm } from "./components/repairShopForm"
import { Create, SimpleForm } from "react-admin"

export const RepairShopCreate = ({ ...props }) => (
  <Create {...props} red>
    <SimpleForm
      initialValues={{ phoneCode: "+39", waCountryPhonecode: "+39" }}
      redirect="list"
    >
      <RepairShopForm />
    </SimpleForm>
  </Create>
)
