import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import { Cancel as IconCancel, Add as IconContentAdd } from "@material-ui/icons"
import { useState } from "react"
import {
  Button,
  FormWithRedirect,
  NumberInput,
  SaveButton,
  useCreate,
  useGetManyReference,
  useNotify,
} from "react-admin"

const validateCreation = (values) => {
  const errors = {}

  const strCampoObbligatorio = "Campo obbligatorio"

  if (values.toAll === false && values.driver_ID === null) {
    errors.driver_ID = [strCampoObbligatorio]
  }
  return errors
}
export const PaymentCreateButton = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("Payments")
  const notify = useNotify()
  const handleClick = () => {
    setShowDialog(true)
  }
  const { ids, data } = useGetManyReference(
    "Payments",
    "quote_ID",
    record.id,
    { perPage: 5000, page: 1 },
    { field: "id", order: "ASC" },
    {}
  )
  if (!record) return null
  const dati = ids.map((id) => data[id])
  const totallyPayed =
    dati.filter((e) => e.confirmed === true).length === dati.length

  const handleCloseClick = () => {
    setShowDialog(false)
  }
  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false)
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="Aggiungi tranche di pagamento"
        disabled={totallyPayed}
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Aggiungi tranche di pagamento"
      >
        <DialogTitle>Aggiungi tranche di pagamento</DialogTitle>
        <Divider />
        <FormWithRedirect
          resource="Payments"
          initialValues={{
            quote_ID: record.id,
          }}
          validate={validateCreation}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <NumberInput label="Ammontare" source="paidTotal" fullWidth />
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}
