import { AvatarNameField } from "../User/customFields"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import { Cancel as IconCancel, Add as IconContentAdd } from "@material-ui/icons"
import { useState } from "react"
import {
  Button,
  FormDataConsumer,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
} from "react-admin"
import { useForm } from "react-final-form"

const WhatsappConversationCreateButton = ({
  onChange,
  disabled,
  driver_ID,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("WhatsappConversations")
  const notify = useNotify()
  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }
  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false)
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          onChange()
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="ra.action.create"
        disabled={disabled}
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Crea conversazione"
      >
        <DialogTitle>Crea conversazione</DialogTitle>

        <FormWithRedirect
          resource="WhatsappConversations"
          initialValues={{ date: new Date() }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => {
            return (
              <>
                <DialogContent>
                  <ReferenceInput
                    fullWidth
                    label="Carrozziere"
                    source="repShop_ID"
                    reference="RepairShops"
                    perPage={5000}
                    sort={{ field: "name", order: "ASC" }}
                  >
                    <SelectInput
                      fullWidth
                      optionText={(currentRecord) => {
                        return `${currentRecord.name} - ${currentRecord.city}`
                      }}
                    />
                  </ReferenceInput>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                    disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            )
          }}
        />
      </Dialog>
    </>
  )
}

export default WhatsappConversationCreateButton
