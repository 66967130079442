import { AttachmentGrid } from "../Attachment/attachmentGrid"
import { NotificationQuoteButton } from "../Notification/components/notificationQuoteButton"
import { Box, Divider, Typography } from "@material-ui/core"
import {
  DateField,
  Labeled,
  NumberField,
  ReferenceField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin"

const PaymentDetails = ({ record }) => (
  <>
    <Labeled label="Richiesta">
      <NotificationQuoteButton record={record} />
    </Labeled>
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <Labeled label="Data">
          <DateField source="date" record={record} showTime={true} />
        </Labeled>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={1} ml="0.5em" mr="0.5em">
        <Labeled label="Tranche">
          <TextField source="paymentType" record={record} />
        </Labeled>
      </Box>
      <Divider orientation="vertical" flexItem />

      <Box flex={3} ml="0.5em">
        <Labeled label="Dettagli">
          <TextField source="details" record={record} />
        </Labeled>
      </Box>
    </Box>
    <Divider />
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <Labeled label="Metodo di pagamento">
          <TextField source="paymentMethod" record={record} />
        </Labeled>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={1} ml="0.5em" mr="0.5em">
        <Labeled label="Dettaglio">
          <TextField
            source={
              record.paymentMethod === "Addebito SEPA"
                ? "sepa_bank"
                : "card_brand"
            }
            record={record}
          />
        </Labeled>
      </Box>
      <Divider orientation="vertical" flexItem />

      <Box flex={1} ml="0.5em">
        <Labeled label="Ultime cifre">
          <TextField
            source={
              record.paymentMethod === "Addebito SEPA"
                ? "sepa_lastDigits"
                : "card_lastDigits"
            }
            record={record}
          />
        </Labeled>
      </Box>
    </Box>
    <Divider />
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <Labeled label="Totale">
          <NumberField
            source="paidTotal"
            options={{ style: "currency", currency: "EUR" }}
            record={record}
          />
        </Labeled>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={1} ml="0.5em" mr="0.5em">
        <Labeled label="Netto">
          <NumberField
            source="paidNet"
            options={{ style: "currency", currency: "EUR" }}
            record={record}
          />
        </Labeled>
      </Box>

      <Divider orientation="vertical" flexItem />
      <Box flex={1} ml="0.5em">
        <Labeled label="Credito usato">
          <NumberField
            source="creditUsed"
            options={{ style: "currency", currency: "EUR" }}
            record={record}
          />
        </Labeled>
      </Box>
    </Box>
    <Divider />
    <Typography variant="h6" style={{ paddingTop: "1em" }}>
      Fatturazione
    </Typography>
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <Labeled label="Nome">
          <ReferenceField
            source="billingAddress_ID"
            reference="BillingAddresses"
            record={record}
            link={false}
          >
            <TextField source={"fullName"} />
          </ReferenceField>
        </Labeled>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={1} ml="0.5em">
        <Labeled label="Seconda linea">
          <ReferenceField
            source="billingAddress_ID"
            reference="BillingAddresses"
            record={record}
            link={false}
          >
            <TextField source={"secondLine"} />
          </ReferenceField>
        </Labeled>
      </Box>
    </Box>
    <Divider />
    <Box display="flex">
      <Box flex={3} mr="0.5em">
        <Labeled label="Indirizzo">
          <ReferenceField
            source="billingAddress_ID"
            reference="BillingAddresses"
            record={record}
            link={false}
          >
            <TextField source={"address"} />
          </ReferenceField>
        </Labeled>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={2} ml="0.5em" mr="0.5em">
        <Labeled label="Città">
          <ReferenceField
            source="billingAddress_ID"
            reference="BillingAddresses"
            record={record}
            link={false}
          >
            <TextField source={"city"} />
          </ReferenceField>
        </Labeled>
      </Box>

      <Divider orientation="vertical" flexItem />
      <Box flex={1} ml="0.5em" mr="0.5em">
        <Labeled label="CAP">
          <ReferenceField
            source="billingAddress_ID"
            reference="BillingAddresses"
            record={record}
            link={false}
          >
            <TextField source={"ZIPcode"} />
          </ReferenceField>
        </Labeled>
      </Box>

      <Divider orientation="vertical" flexItem />
      <Box flex={1} ml="0.5em">
        <Labeled label="Città">
          <ReferenceField
            source="billingAddress_ID"
            reference="BillingAddresses"
            record={record}
            link={false}
          >
            <TextField source={"country"} />
          </ReferenceField>
        </Labeled>
      </Box>
    </Box>
    <Divider />
    {record && record.isCompany && (
      <>
        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <Labeled label="Partita IVA">
              <ReferenceField
                source="billingAddress_ID"
                reference="BillingAddresses"
                record={record}
                link={false}
              >
                <TextField source={"VAT"} />
              </ReferenceField>
            </Labeled>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1} ml="0.5em" mr="0.5em">
            <Labeled label="Codice fiscale">
              <ReferenceField
                source="billingAddress_ID"
                reference="BillingAddresses"
                record={record}
                link={false}
              >
                <TextField source={"fiscalCode"} />
              </ReferenceField>
            </Labeled>
          </Box>

          <Divider orientation="vertical" flexItem />
          <Box flex={3} ml="0.5em" mr="0.5em">
            <Labeled label="PEC">
              <ReferenceField
                source="billingAddress_ID"
                reference="BillingAddresses"
                record={record}
                link={false}
              >
                <TextField source={"certifiedEmail"} />
              </ReferenceField>
            </Labeled>
          </Box>

          <Divider orientation="vertical" flexItem />
          <Box flex={1} ml="0.5em">
            <Labeled label="Codice fatturazione">
              <ReferenceField
                source="billingAddress_ID"
                reference="BillingAddresses"
                record={record}
                link={false}
              >
                <TextField source={"eiCode"} />
              </ReferenceField>
            </Labeled>
          </Box>
        </Box>
        <Divider />
      </>
    )}
  </>
)

export const PaymentShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Dettagli">
        <PaymentDetails />
      </Tab>
      <Tab label="Allegati">
        <AttachmentGrid originType="Payment" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)
