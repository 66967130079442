export const API_URL =
  "https://6r82pq0goi.execute-api.us-east-2.amazonaws.com/api"
// export const API_URL = "http://localhost:3003/api";

export const googleMapsKey = "AIzaSyA8iFLN42UxQXCpGitGN3czvRs5zi0T8cI"

// Se ritorna true vuol dire che il record è ancora ATTIVO, cioè non è nè cancellato nè completato
export function conditionQuoteActive(record) {
  return (
    record && (record.status === 0 || record.status === 1) && !record.deleted
  )
}
