import { ConditionalEditButton } from "../../Customs/customConditionalFields"
import { AvatarNameField } from "../User/customFields"
import { PanelShow } from "./quoteListPanel"
import { Divider, Tab, Tabs } from "@material-ui/core"
import { grey, red } from "@material-ui/core/colors"
import { Fragment, useCallback, useEffect, useState } from "react"
import {
  Datagrid,
  DateField,
  Filter,
  FilterLiveSearch,
  Link,
  List,
  ListContextProvider,
  NumberField,
  ReferenceField,
  SelectInput,
  TextField,
  useGetList,
  useGetOne,
  useListContext,
} from "react-admin"

const rowStyle = (record, index, defaultStyle = {}) => {
  return {
    ...defaultStyle,
    borderLeftColor: record?.deleted ? red[500] : "transparent",
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    backgroundColor: record?.deleted ? grey[100] : "transparent",
  }
}

const QuoteFilter = (props) => (
  <Filter {...props} title="Filtri">
    <FilterLiveSearch source="q" alwaysOn />
    <SelectInput
      label="Tipologia"
      source="deleted"
      choices={[
        { name: "Attivi", id: false },
        { name: "Eliminati", id: true },
      ]}
      alwaysOn
    />
  </Filter>
)

const tabs = [
  { id: 0, name: "Elaborazione" },
  { id: 1, name: "Analizzati" },
  { id: 2, name: "Pagati" },
  { id: 3, name: "Rifiutati" },
]

const useGetTotals = (filterValues) => {
  const { total: totElaborated } = useGetList(
    "Quotes",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, status: 0 }
  )
  const { total: totAnalyzed } = useGetList(
    "Quotes",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, status: 1 }
  )
  const { total: totPaid } = useGetList(
    "Quotes",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, status: 2 }
  )
  const { total: totRefused } = useGetList(
    "Quotes",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, status: 3 }
  )
  return {
    Elaborazione: totElaborated,
    Analizzati: totAnalyzed,
    Pagati: totPaid,
    Rifiutati: totRefused,
  }
}

const QuoteTabbedList = (props) => {
  const listContext = useListContext()
  const { ids, filterValues, setFilters, displayedFilters } = listContext
  const [elaborated, setElaborated] = useState([])
  const [analyzed, setAnalyzed] = useState([])
  const [paid, setPaid] = useState([])
  const [refused, setRefused] = useState([])
  const totals = useGetTotals(filterValues)

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      console.log("val" + filterValues.status)
      switch (filterValues.status) {
        case 0:
          setElaborated(ids)
          break
        case 1:
          setAnalyzed(ids)
          break
        case 2:
          setPaid(ids)
          break
        case 3:
          setRefused(ids)
          break
      }
    }
  }, [ids, filterValues.status])

  const handleChange = useCallback(
    (event, value) => {
      console.log(filterValues, value)
      setFilters &&
        setFilters({ ...filterValues, status: value }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )
  const selectedIds =
    filterValues.status === 0
      ? elaborated
      : filterValues.status === 1
      ? analyzed
      : filterValues.status === 2
      ? paid
      : refused
  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.name}
            label={
              totals[choice.name]
                ? `${choice.name} (${totals[choice.name]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        <ListContextProvider
          value={{
            ...listContext,
            ids: selectedIds,
          }}
        >
          <Datagrid
            {...props}
            optimized
            rowClick="show"
            expand={<PanelShow filterValues={filterValues} />}
            rowStyle={rowStyle}
          >
            <DateField
              label="Data di richiesta"
              source="requestDate"
              showTime
            />
            <TextField label="Identificativo" source="quoteIdentifier" />
            <QuoteUser label="Utente" />
            <ReferenceField
              label="Veicolo"
              source="car_ID"
              reference="Cars"
              link="show"
            >
              <TextField source="plate" />
            </ReferenceField>
            {filterValues.status !== 2 && (
              <DateField
                label="Scadenza"
                source="expDate"
                showTime
                allowEmpty
                emptyText="-"
              />
            )}
            {(filterValues.status === 0 || filterValues.status === 1) && (
              <ConditionalEditButton label="Modifica" />
            )}
            {filterValues.status === 2 && (
              <NumberField
                source="finalTotal"
                label="Pagato"
                options={{ style: "currency", currency: "EUR" }}
                allowEmpty
              />
            )}
          </Datagrid>
        </ListContextProvider>
      </div>
    </Fragment>
  )
}

//Necessario altrimenti per le troppe richieste va in loop e non funziona il sito
const QuoteUser = ({ record }) => {
  const { data } = useGetOne("Drivers", record?.driver_ID ?? 0)
  return !record || !data ? (
    "-"
  ) : (
    <Link
      to={{
        pathname: `/Drivers/${record.driver_ID}/show`,
      }}
    >
      <AvatarNameField record={data} />
    </Link>
  )
}
export const QuoteList = (props) => (
  <List
    exporter={false}
    {...props}
    filters={<QuoteFilter />}
    title="Preventivi"
    sort={{ field: "requestDate", order: "DESC" }}
    filter={{}}
    filterDefaultValues={{ status: 0 }}
  >
    <QuoteTabbedList />
  </List>
)
