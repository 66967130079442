import { ChipCustomField } from "../../Customs/chipCustomField"
import { AttachmentCreateButton } from "./attachmentCreate"
import {
  Datagrid,
  Pagination,
  ReferenceManyField,
  TextField,
} from "react-admin"

export const AttachmentGrid = ({ record, originType }) => (
  <>
    <AttachmentCreateButton record={record} originType={originType} />
    <ReferenceManyField
      target="originId"
      reference="Attachments"
      sort={{ field: "date", order: "DESC" }}
      addLabel={false}
      pagination={<Pagination />}
      perPage={10}
      record={record}
      filter={{ originType: originType }}
    >
      <Datagrid>
        <ChipCustomField label="Tipo">
          <TextField source="type" allowEmpty emptyText="-" />
        </ChipCustomField>
        <TextField source="details" label="Dettagli" allowEmpty emptyText="-" />
      </Datagrid>
    </ReferenceManyField>
  </>
)
