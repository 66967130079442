import { CustomDialogTitle } from "./customDialogWithClose"
import { Box, Dialog, DialogContent, Divider, Tooltip } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Fragment, useState } from "react"
import { ImageField } from "react-admin"

const useStyles = makeStyles(() => ({
  image: { maxHeight: "18rem" },
}))

export const CustomImageField = (props) => {
  const classes = useStyles()
  const [showDialog, setShowDialog] = useState(false)

  return (
    <Fragment>
      <Tooltip title={"Clicca per ingrandire"}>
        {props.url ? (
          <img
            src={props.url(props.record)}
            style={{ maxHeight: "10rem", cursor: "pointer" }}
            onClick={() => setShowDialog(true)}
          />
        ) : (
          <ImageField
            style={{ cursor: "pointer" }}
            classes={classes}
            {...props}
            onClick={() => setShowDialog(true)}
          />
        )}
      </Tooltip>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={() => setShowDialog(false)}
        onClick={(e) => e.stopPropagation()}
        aria-label="Foto"
      >
        <CustomDialogTitle onClose={() => setShowDialog(false)}>
          Visualizza immagine
        </CustomDialogTitle>
        <Divider />
        <DialogContent>
          <Box display="flex">
            <Box m="auto">
              <img
                src={
                  props.url
                    ? props.url(props.record)
                    : props.record[props.source]
                }
                style={{ objectFit: "cover", maxHeight: "40rem" }}
              />
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
