import { Box } from "@material-ui/core"
import { Edit, required, SimpleForm, TextInput } from "react-admin"

const DataForm = ({ resource, record }) => (
  <Box>
    <Box display="flex">
      <Box flex={1}>
        <TextInput
          source="name"
          fullWidth
          resource={resource}
          validate={required()}
          record={record}
        />
      </Box>
      <Box flex={1} />
    </Box>
    <TextInput
      source="details"
      multiline
      fullWidth
      resource={resource}
      rows={2}
      maxRows={4}
      record={record}
    />
    <TextInput
      source="iconUrl"
      fullWidth
      resource={resource}
      disabled
      record={record}
    />
  </Box>
)
export const AddOnEdit = ({ ...props }) => (
  <Edit {...props}>
    <SimpleForm>
      <DataForm />
    </SimpleForm>
  </Edit>
)
