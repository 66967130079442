import { CarTypeSelect } from "./components/carTypeField"
import { Box } from "@material-ui/core"
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"

const DataForm = ({ resource }) => {
  return (
    <Box display="flex">
      <Box flex={2}>
        <ReferenceInput
          fullWidth
          source="driver_ID"
          reference="Drivers"
          validate={required()}
          resource={resource}
          perPage={5000}
          sort={{ field: "surname", order: "ASC" }}
          filterToQuery={(searchText) => {
            ;[searchText]
          }}
        >
          <AutocompleteInput
            optionText={(rec) => `${rec?.surname} ${rec?.name} (${rec?.email})`}
          />
        </ReferenceInput>
        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <TextInput
              source="plate"
              validate={required()}
              resource={resource}
              fullWidth
              inputProps={{ maxLength: 7 }}
            />
          </Box>
          <Box flex={2} mr="0.5em">
            <ReferenceInput
              fullWidth
              source="carType_ID"
              reference="CarTypes"
              resource={resource}
              perPage={5000}
              validate={required()}
              sort={{ field: "typeName", order: "ASC" }}
            >
              <CarTypeSelect />
            </ReferenceInput>
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <TextInput
              source="brand"
              validate={required()}
              fullWidth
              resource={resource}
            />
          </Box>
          <Box flex={1} mr="0.5em">
            <TextInput
              source="model"
              validate={required()}
              fullWidth
              resource={resource}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <TextInput source="version" fullWidth resource={resource} />
          </Box>
          <Box flex={1} mr="0.5em">
            <TextInput
              source="engine_displacement"
              fullWidth
              resource={resource}
            />
          </Box>
        </Box>
      </Box>
      <Box flex={2}></Box>
    </Box>
  )
}
export const CarCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <DataForm />
    </SimpleForm>
  </Create>
)
