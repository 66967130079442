import Map from "../../Helpers/Map/Map"
import { TelephoneField } from "../User/customFields"
import { Box, Divider } from "@material-ui/core"
import {
  BooleanField,
  Datagrid,
  DateField,
  Labeled,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin"

const RepairShopTitle = ({ record }) => {
  return <span>{record ? `${record.name}` : ""}</span>
}

export const RepairShopShow = (props) => (
  <Show {...props} title={<RepairShopTitle />}>
    <TabbedShowLayout>
      <Tab label="Dettagli">
        <DataTab />
      </Tab>
      <Tab label="Offerte" path="offers">
        <ReferenceManyField
          target="repShop_ID"
          reference="Offers"
          addLabel={false}
          pagination={<Pagination />}
          perPage={10}
        >
          <Datagrid rowClick="show">
            <ReferenceField
              label="Identificativo preventivo"
              source="quote_ID"
              reference="Quotes"
              link="show"
              allowEmpty
            >
              <TextField source="quoteIdentifier" />
            </ReferenceField>
            <BooleanField source="chosen" label="" />
            <DateField source="pickupTime" showTime label="Data ritiro" />
            <DateField source="pickupTime" showTime label="Data restituzione" />
            <NumberField
              source="total"
              label="Totale"
              options={{ style: "currency", currency: "EUR" }}
              allowEmpty
            />
            <NumberField
              source="discountTotal"
              label="Totale scontato"
              options={{ style: "currency", currency: "EUR" }}
              allowEmpty
            />
            <ShowButton label="Dettagli" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
const DataTab = ({ record, ...rest }) => (
  <Box display="flex" pb="0.5em">
    <Box flex={2} mr="0.5em">
      <Box>
        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <Labeled label="Codice">
              <TextField
                source="intIdentifier"
                record={record}
                {...rest}
                emptyText="-"
              />
            </Labeled>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={3} mr="0.5em" ml="0.5em">
            <Labeled label="Denominazione">
              <TextField
                source="name"
                record={record}
                {...rest}
                emptyText="-"
              />
            </Labeled>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1} ml="0.5em">
            <Labeled label="Partita IVA">
              <TextField source="VAT" record={record} {...rest} emptyText="-" />
            </Labeled>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box display="flex">
          <Box flex={3} mr="0.5em">
            <Labeled label="Indirizzo">
              <TextField
                source="address"
                record={record}
                {...rest}
                emptyText="-"
              />
            </Labeled>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={2} mr="0.5em" ml="0.5em">
            <Labeled label="Città">
              <TextField
                source="city"
                record={record}
                {...rest}
                emptyText="-"
              />
            </Labeled>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1} mr="0.5em" ml="0.5em">
            <Labeled label="CAP">
              <TextField source="ZIP" record={record} {...rest} emptyText="-" />
            </Labeled>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1} ml="0.5em">
            <Labeled label="Provincia">
              <TextField
                source="province"
                record={record}
                {...rest}
                emptyText="-"
              />
            </Labeled>
          </Box>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box display="flex">
          <Box flex={3} mr="0.5em">
            <Labeled label="Indirizzo email">
              <TextField
                source="emailAddress"
                record={record}
                {...rest}
                emptyText="-"
              />
            </Labeled>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1} mr="0.5em" ml="0.5em">
            <Labeled label="Telefono">
              <TelephoneField
                countryField="phoneCode"
                phoneField="telephone"
                record={record}
                {...rest}
                emptyText="-"
              />
            </Labeled>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1} ml="0.5em">
            <Labeled label="Cellulare">
              <TelephoneField
                countryField="waCountryPhonecode"
                phoneField="waTelephone"
                record={record}
                {...rest}
                emptyText="-"
              />
            </Labeled>
          </Box>
        </Box>
      </Box>
    </Box>
    <Divider orientation="vertical" flexItem />
    <Box flex={1} ml="0.5em">
      <Map locationField="location" record={record} />
    </Box>
  </Box>
)
