import { Divider, Typography } from "@material-ui/core"
import { Login, LoginForm } from "react-admin"

export const MyLoginPage = (props) => (
  <Login
    {...props}
    backgroundImage={process.env.PUBLIC_URL + "/background.png"}
  >
    <CustomLoginForm {...props} />
  </Login>
)
const CustomLoginForm = (props) => (
  <div>
    <Typography variant="body1" align="center">
      <img
        src={process.env.PUBLIC_URL + "/logo.png"}
        alt="AVATAR"
        width={120}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    </Typography>
    <Typography variant="body1" align="center">
      Accesso
    </Typography>
    <Typography variant="h6" align="center" gutterBottom={true}>
      Console Amministrazione
    </Typography>
    <Divider variant="middle" />
    <LoginForm {...props} />
  </div>
)
