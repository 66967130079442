import { TotPaymentField } from "../../Customs/customFields"
import { conditionQuoteActive } from "../../Helpers/variabiliGlobali"
import { NotificationCreateButton } from "../Notification/notificationCreate"
import { ShareToRepShopButton } from "../Quote/components/shareQuoteToRepShop"
import { Box, Divider } from "@material-ui/core"
import {
  Add as IconContentAdd,
  NotificationImportant as IconNotif,
} from "@material-ui/icons"
import {
  BooleanField,
  Button,
  Datagrid,
  DateField,
  EditButton,
  Link,
  NumberField,
  ReferenceManyField,
} from "react-admin"

const CreateRelatedOfferButton = ({ record }) =>
  conditionQuoteActive(record) ? (
    <Button
      component={Link}
      to={{
        pathname: "/Offers/create",
        state: {
          record: {
            quote_ID: record.id,
            quoteIdentifier: record.quoteIdentifier,
            customAddOns:
              record.addOns &&
              record.addOns.map(({ id, name }) => ({
                addOns: id,
                name,
              })),
          },
        },
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
      label={"Aggiungi offerta"}
    >
      <IconContentAdd />
    </Button>
  ) : null

export const OfferGrid = ({ record }) => (
  <>
    <Box display={"flex"} style={{ paddingBottom: "10px" }}>
      <Box flex={1} mr="0.5rem">
        <CreateRelatedOfferButton record={record} label="Crea Offerta" />
      </Box>
      <Box flex={1} mr="0.5rem" ml="0.5rem">
        <NotificationCreateButton
          label={"Segnala offerte all'utente"}
          driver_ID={record.driver_ID}
          quote_ID={record.id}
          type={"updateQuote_notification"}
          icon={<IconNotif />}
        />
      </Box>
      <Box flex={1} ml="0.5rem">
        <ShareToRepShopButton quote_ID={record.id} />
      </Box>
    </Box>
    <Divider />
    <ReferenceManyField
      target="quote_ID"
      reference="Offers"
      addLabel={false}
      record={record}
    >
      <Datagrid rowClick={"show"}>
        <TotPaymentField
          options={{ style: "currency", currency: "EUR" }}
          originField="total"
        />
        <TotPaymentField
          options={{ style: "currency", currency: "EUR" }}
          originField="discountTotal"
        />
        <DateField
          label="Data di ritiro"
          source="pickupTime"
          options={{ year: "numeric", month: "long", day: "numeric" }}
          allowEmpty
        />
        <DateField
          label="Data di consegna"
          source="deliveryTime"
          options={{ year: "numeric", month: "long", day: "numeric" }}
          allowEmpty
        />
        <NumberField
          label="Prezzo carrozziere"
          source="repTotal"
          options={{ style: "currency", currency: "EUR" }}
        />
        <BooleanField
          source="chosen"
          label="Scelta"
          valueLabelTrue="Offerta scelta"
          valueLabelFalse="Offerta non selezionata"
        />

        <EditButton
          label="Modifica"
          disabled={(record && record.status !== 0) || record.status === 1}
        />
      </Datagrid>
    </ReferenceManyField>
  </>
)
