import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import {
  Cancel as IconCancel,
  Edit as IconContentEdit,
} from "@material-ui/icons"
import { useState } from "react"
import {
  Button,
  DateTimeInput,
  FormDataConsumer,
  FormWithRedirect,
  NumberInput,
  SaveButton,
  SelectInput,
  useNotify,
  useUpdate,
} from "react-admin"

const validateCreation = (values) => {
  const errors = {}

  const strCampoObbligatorio = "Campo obbligatorio"

  if (values.toAll === false && values.driver_ID === null) {
    errors.driver_ID = [strCampoObbligatorio]
  }
  return errors
}
export const PaymentEditButton = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [edit, { loading }] = useUpdate("Payments")
  const notify = useNotify()
  const handleClick = () => {
    setShowDialog(true)
  }
  const handleCloseClick = () => {
    setShowDialog(false)
  }
  const handleSubmit = async (values) => {
    edit(
      { payload: { data: values, id: record.id, previousData: record } },
      {
        onSuccess: () => {
          setShowDialog(false)
          // Update the comment form to target the newly Editd post
          // Updating the ReferenceInput value will force it to reload the available posts
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label={"ra.action.edit"}
        disabled={record.confirmed === true}
      >
        <IconContentEdit />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Modifica tranche di pagamento"
      >
        <DialogTitle>Modifica tranche di pagamento</DialogTitle>
        <Divider />
        <FormWithRedirect
          resource="Payments"
          initialValues={{
            ...record,
          }}
          validate={validateCreation}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <FormDataConsumer>
                  {() => {
                    return (
                      <>
                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <SelectInput
                              label="Segna pagamento"
                              source="confirmed"
                              choices={[
                                { id: true, name: "Pagato" },
                                { id: false, name: "Non pagato" },
                              ]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <DateTimeInput
                              label="Data Pagamento"
                              source="date"
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box display="flex">
                          <Box flex={2} mr="0.5em">
                            <SelectInput
                              label="Metodo Pagamento"
                              source="paymentMethod"
                              choices={[
                                {
                                  id: "Carta di credito",
                                  name: "Carta di credito",
                                },
                                { id: "Addebito SEPA", name: "Addebito SEPA" },
                              ]}
                              fullWidth
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <NumberInput
                              label="Quota totale pagata"
                              source="paidTotal"
                              disabled
                              fullWidth
                            />
                          </Box>
                        </Box>
                      </>
                    )
                  }}
                </FormDataConsumer>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}
