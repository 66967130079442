import { Box } from "@material-ui/core"
import { Create, required, SimpleForm, TextInput } from "react-admin"

const DataForm = ({ resource }) => (
  <Box>
    <Box display="flex">
      <Box flex={1}>
        <TextInput
          source="name"
          fullWidth
          resource={resource}
          validate={required()}
        />
      </Box>
      <Box flex={1} />
    </Box>
    <TextInput
      source="details"
      multiline
      fullWidth
      resource={resource}
      rows={2}
      maxRows={4}
    />
  </Box>
)
export const AddOnCreate = ({ ...props }) => (
  <Create {...props}>
    <SimpleForm>
      <DataForm />
    </SimpleForm>
  </Create>
)
