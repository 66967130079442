import { API_URL } from "../Helpers/variabiliGlobali"
import inMemoryJWTManager from "./jwtRefreshProvider"
import { queryStringCreator } from "./queryStringCreator"
import { stringify } from "query-string"
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from "react-admin"

// Esporto l'indirizzo dell'API in modo da scriverlo solo una volta in tutto il file
// Se serve in altri file basta importarlo ( import {API_URL} from './dataProvider')

const convertDataProviderRequestToHTTP = (type, resource, params) => {
  const tkn = {
    access_token: inMemoryJWTManager.getToken(),
  }

  // -------------------------------------------------------------------------------------

  /*
    Codice per inviare messaggi WhatsApp a carrozzerie (Viene fatta una CREATE per poter scrivere il form, ma
    da back end serve una GET in realtà)
  */
  if (resource === "ShareLinkToRepairShops" && type === CREATE) {
    console.log(params)

    const quote_ID = params.data.quote_ID
    const repairShops_ids = JSON.stringify(params.data.RepairShop_ids)
    console.log(params.data.RepairShop_ids)

    console.log(inMemoryJWTManager.getToken())
    return {
      access_token: inMemoryJWTManager.getToken(),
      url: `${API_URL}/Quotes/${quote_ID}/shareToRepairShops?ids=${repairShops_ids}&access_token=${inMemoryJWTManager.getToken()}`,
      options: { method: "GET" },
    }
  }

  // -------------------------------------------------------------------------------------

  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        access_token: inMemoryJWTManager.getToken(),
        order: [field, order],
        range: [(page - 1) * perPage, perPage],
        filter: params.filter,
      }
      return { url: queryStringCreator(resource, query) }
    }
    case GET_ONE: {
      const query = {
        access_token: inMemoryJWTManager.getToken(),
        filter: { id: params.id },
      }
      return { url: queryStringCreator(resource, query, "GET_ONE") }
    }
    case GET_MANY: {
      const query = {
        access_token: inMemoryJWTManager.getToken(),
        filter: { id: params.ids },
      }
      return { url: queryStringCreator(resource, query) }
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        access_token: inMemoryJWTManager.getToken(),
        sort: [field, order],
        range: [(page - 1) * perPage, perPage],
        filter: { ...params.filter, [params.target]: params.id },
      }

      return { url: queryStringCreator(resource, query) }
    }
    case UPDATE:
      // eslint-disable-next-line no-case-declarations
      let method = "PUT"
      if (resource === "Drivers" || resource === "Operators") method = "PATCH"
      return {
        url: `${API_URL}/${resource}/${params.id}?${stringify(tkn)}`,
        options: { method, body: JSON.stringify(params.data) },
      }
    case CREATE: {
      if (resource === "Quotes") {
        resource = "Quotes/consoleNewQuote" //In caso di nuovo preventivo endpoint diverso
      }
      if (resource === "Guides") {
        resource = "Guides/consoleNewGuide" //In caso di nuova guida endpoint diverso
      }

      /*
        Creazione del modello attachment (polimorfico con relazioni a Guide hasone e Payment hasmany) che carica qualsiasi
        file e lo attacca alla rispettiva tabella. è sostanzialmente uguale al modello Invoice per la semplice differenza
        che ora i file delle fatture vanno caricati su (api/Payments/{id}/attachments POST) indicando nel body type: "invoice",
        e i campi number date vanno inseriti lato client nel seguente modo: details: "Fatt. n. {{num}} del {{date ddMMyyyy}}"
      */
      if (resource === "Invoices") {
        let date = new Date(params.data.date)
        date = `${date.getDate()}${date.getMonth() + 1}${date.getFullYear()}`

        // La tabella Invoices non esiste nel db, ma esiste attachments che è collegata sia a Payments che a Guides
        resource = `Payments/${params.data.payment_ID}/attachments`
        params.data = {
          files: params.data.files,
          type: `invoice`,
          details: `Fatt. n. {{${params.data.number}}} del {{${date}}}}`,
          quote_ID: params.data.quote_ID,
          originType: "Payment",
        }
      }
      if (resource === "Attachments") {
        // Il create dell'attachment lo uso per l'aggiunta di altri file per un pagamento che non siano la fattura

        resource = `Payments/${params.data.originId}/attachments`
        params.data = {
          files: params.data.files,
          type: `other`,
          quote_ID: params.data.quote_ID,
          originType: "Payment",
        }
      }

      if (resource === "shareOfferNotificationToUser") {
        resource = `Quotes/${params.data.quote_ID}/updateQuote`
      }

      console.log(params)

      return {
        url: `${API_URL}/${resource}?${stringify(tkn)}`,
        options: { method: "POST", body: JSON.stringify(params.data) },
      }
    }
    case DELETE:
      return {
        access_token: inMemoryJWTManager.getToken(),
        url: `${API_URL}/${resource}/${params.id}?${stringify(tkn)}`,
        options: { method: "DELETE" },
      }
    case DELETE_MANY:
      // TODO: aggiungere params.ids dopo resource (manca l'endpoint)
      console.log(params)
      console.log(JSON.stringify({ id: params.ids }))

      console.log(`${API_URL}/${resource}?${stringify(tkn)}`)
      return {
        url: `${API_URL}/${resource}/deleteMany?id=${JSON.stringify(
          params.ids
        )}`,
        options: { method: "GET" },
      }

    default:
      throw new Error(`Unsupported fetch action type ${type}`)
  }
}

const convertHTTPResponseToDataProvider = (
  response,
  type,
  resource,
  params
) => {
  const { headers, json } = response

  switch (type) {
    case GET_LIST:
      return {
        data: json.map((x) => x),
        total: parseInt(headers.get("X-Total-Count")),
      }
    case GET_MANY_REFERENCE:
      return {
        data: json.map((x) => x),
        total: parseInt(headers.get("X-Total-Count")),
      }
    case CREATE:
      return { data: { ...params.data, id: json.id } }
    case DELETE:
      return { data: { ...params.data, id: json.id } }
    case DELETE_MANY:
      return { data: [] }
    default:
      return { data: json }
  }
}
const fetchJson = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" })
  }
  // add your own headers here
  options.headers.set("Platform-Identifier", "console")
  return fetchUtils.fetchJson(url, options)
}
export default (type, resource, params) => {
  const { url, options } = convertDataProviderRequestToHTTP(
    type,
    resource,
    params
  )
  return fetchJson(url, options).then((response) =>
    convertHTTPResponseToDataProvider(response, type, resource, params)
  )
}
