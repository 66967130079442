import { AvatarNameField } from "../User/customFields"
import OperatorChangePasswordButton from "./components/operatorChangePassword"
import {
  Datagrid,
  DateField,
  DeleteButton,
  EditButton,
  Filter,
  FilterLiveSearch,
  List,
  TextField,
} from "react-admin"

export const OperatorList = (props) => (
  <List
    {...props}
    filters={
      <Filter {...props} title="Filtri">
        <FilterLiveSearch source="q" alwaysOn />
      </Filter>
    }
    bulkActionButtons={false}
    title="Operatori"
    exporter={false}
  >
    <Datagrid rowClick="show">
      <AvatarNameField label="Nome" />
      <TextField source="email" sortable={false} />
      <DateField source="registrationDate" emptyText="-" showTime />
      {props.permissions === "superadmin" && <EditButton />}
      {props.permissions === "superadmin" && <OperatorChangePasswordButton />}
      {props.permissions === "superadmin" && <DeleteButton undoable={false} />}
    </Datagrid>
  </List>
)
