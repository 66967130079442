import { Button as FlatButton } from "@material-ui/core"
import { Call as CallIcon } from "@material-ui/icons"
import { Button, useRefresh, useUpdate } from "react-admin"

export const FlagCalledButton = ({ record, inList = false }) => {
  const refresh = useRefresh()
  const [update] = useUpdate(
    "TowTruckQuotes",
    record.id,
    { ...record, called: true },
    record,
    { onSuccess: () => refresh() }
  )
  if (!record) return null
  return inList ? (
    <Button
      label="Segna Chiamato"
      onClick={(e) => {
        e.stopPropagation()
        return update()
      }}
    >
      <CallIcon />
    </Button>
  ) : (
    <FlatButton color="inherit" size="small" onClick={update}>
      SEGNA CHIAMATO
    </FlatButton>
  )
}
