import { dateParser } from "../../Helpers/date"
import { regexTelephone, regexZIP } from "../../Helpers/regex"
import { Box, Divider, Typography } from "@material-ui/core"
import { diff } from "deep-object-diff"
import {
  DateInput,
  Edit,
  FormDataConsumer,
  regex,
  required,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin"

const UserEditToolbar = ({ record, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        submitOnEnter={true}
        transform={(data) => diff(record, data)}
      />
    </Toolbar>
  )
}

const UserEditForm = ({ resource }) => (
  <Box p="1em">
    <SelectInput
      label="Tipo account"
      source="isCompany"
      choices={[
        { name: "Privato", id: false },
        { name: "Azienda", id: true },
      ]}
      disabled
      resource={resource}
    />
    <Divider variant="middle" style={{ marginBottom: "1em" }} />

    <Typography variant="h6" gutterBottom>
      Informazioni utente
    </Typography>

    <FormDataConsumer>
      {({ formData }) =>
        formData.isCompany === true ? (
          <Box>
            <Box display="flex">
              <Box flex={2} mr="0.5em">
                <TextInput
                  source="companyName"
                  label="Denominazione azienda"
                  fullWidth
                  validate={required()}
                  resource={resource}
                />
              </Box>
              <Box flex={1} ml="0.5em" mr="0.5em">
                <TextInput
                  source="VAT"
                  label="Partita IVA"
                  fullWidth
                  validate={required()}
                  resource={resource}
                />
              </Box>
              <Box flex={2} ml="0.5em"></Box>
            </Box>
            <Box display="flex">
              formData
              <Box flex={2} mr="0.5em">
                <TextInput
                  source="name"
                  label="Nome contatto di riferimento"
                  fullWidth
                  validate={required()}
                  resource={resource}
                />
              </Box>
              <Box flex={2} ml="0.5em" mr="0.5em">
                <TextInput
                  source="surname"
                  label="Cognome contatto di riferimento"
                  fullWidth
                  validate={required()}
                  resource={resource}
                />
              </Box>
              <Box flex={2} ml="0.5em"></Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box display="flex">
              <Box flex={2} mr="0.5em">
                <TextInput
                  source="name"
                  label="Nome"
                  fullWidth
                  validate={required()}
                  resource={resource}
                />
              </Box>
              <Box flex={2} ml="0.5em" mr="0.5em">
                <TextInput
                  source="surname"
                  label="Cognome"
                  fullWidth
                  validate={required()}
                  resource={resource}
                />
              </Box>
              <Box flex={2} ml="0.5em"></Box>
            </Box>
          </Box>
        )
      }
    </FormDataConsumer>
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput
          source="email"
          label="Email"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={0.5} ml="0.5em" mr="0.5em">
        <TextInput
          source="ZIPcode"
          label="CAP"
          fullWidth
          inputProps={{ maxLength: 5 }}
          validate={[
            required(),
            regex(regexZIP, "Il formato del CAP è errato"),
          ]}
          resource={resource}
        />
      </Box>
      <Box flex={0.5} ml="0.5em" mr="0.5em">
        <TextInput
          source="countryPhonecode"
          label="Prefisso"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={1} ml="0.5em">
        <TextInput
          source="telephone"
          label="Telefono"
          fullWidth
          validate={[
            required(),
            regex(regexTelephone, "Formato errato. Es: 334 2030405"),
          ]}
          resource={resource}
        />
      </Box>
    </Box>
    <FormDataConsumer>
      {({ formData }) =>
        formData.isCompany === false && (
          <Box>
            <Divider variant="middle" style={{ marginBottom: "1em" }} />
            <Typography variant="h6" gutterBottom>
              Ulteriori dettagli
            </Typography>
            <Box display="flex">
              <Box flex={0.5} mr="0.5em">
                <SelectInput
                  label="Sesso"
                  source="sex"
                  choices={[
                    { name: "Maschio", id: "M" },
                    { name: "Femmina", id: "F" },
                    { name: "Altro", id: "A" },
                    { name: "Nessuno", id: "N" },
                  ]}
                  resource={resource}
                />
              </Box>
              <Box flex={1} mr="0.5em" ml="0.5em">
                <DateInput
                  label="Data di nascita"
                  source="dateOfBirth"
                  parse={dateParser}
                  fullWidth
                  resource={resource}
                />
              </Box>
              <Box flex={2} ml="0.5em">
                <TextInput
                  label="Luogo di nascita"
                  source="placeOfBirth"
                  fullWidth
                  resource={resource}
                />
              </Box>
            </Box>
          </Box>
        )
      }
    </FormDataConsumer>
  </Box>
)
export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm
      redirect="list"
      warnWhenUnsavedChanges
      toolbar={<UserEditToolbar />}
    >
      <UserEditForm />
    </SimpleForm>
  </Edit>
)
