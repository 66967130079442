import { SideField, StatusField } from "../../Customs/customFields"
import { CustomImageField } from "../../Customs/customImageField"
import Map from "../../Helpers/Map/Map"
import { EmailGrid } from "../Email/emailGrid"
import { QuoteFeedbackShow } from "../Feedback/quoteFeedbackShow"
import { NotificationGrid } from "../Notification/notificationGrid"
import { OfferGrid } from "../Offer/offerGrid"
import { PaymentGrid } from "../Payment/paymentGrid"
import { Box, Divider } from "@material-ui/core"
import {
  ArrayField,
  ChipField,
  Datagrid,
  FunctionField,
  Labeled,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  ShowController,
  ShowView,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin"

const QuoteTitle = ({ record }) => {
  return (
    <span>
      {record.quoteIdentifier
        ? `Preventivo numero: ${record.quoteIdentifier}`
        : "Nessun identificativo"}
    </span>
  )
}

export const QuoteShow = (props) => (
  <ShowController {...props}>
    {(controllerProps) => (
      <ShowView {...props} {...controllerProps} title={<QuoteTitle />}>
        <TabbedShowLayout>
          <Tab label="Dettagli Tecnici">
            <StatusField label="Stato" source="status" allowEmpty />
            <RichTextField
              label="Dettagli utente"
              source="userDetails"
              allowEmpty
            />
            <ReferenceField
              label="Targa auto"
              source="car_ID"
              reference="Cars"
              allowEmpty
              link="show"
            >
              <TextField source="plate" />
            </ReferenceField>
            <ReferenceField
              label="Carrozziere"
              source="repairShop_ID"
              reference="RepairShops"
              allowEmpty
              link="show"
            >
              <TextField emptyText="In attesa di associazione" source="name" />
            </ReferenceField>
            <ArrayField
              link={false}
              source="addOns"
              label="Servizi Aggiuntivi Richiesti"
            >
              <SingleFieldList>
                <ChipField link={false} source="name" />
              </SingleFieldList>
            </ArrayField>
            <TextField label="Posizione dell'auto" source="actualAddress" />
            <Map zoomLevel={14} locationString="actualAddress" />
            {/* <ShareNotificationSparePartsButton /> */}
          </Tab>
          <Tab label="Danni" path="damages">
            <ReferenceManyField
              target="quote_ID"
              reference="Damages"
              addLabel={false}
            >
              <Datagrid expand={<DamagePanel />}>
                <ReferenceField
                  label="Lato"
                  source="carPart_ID"
                  reference="CarParts"
                  link={false}
                >
                  <SideField source="side" />
                </ReferenceField>
                <ReferenceField
                  label="Parte danneggiata"
                  source="carPart_ID"
                  reference="CarParts"
                  link={false}
                >
                  <FunctionField
                    render={(currentRecord) =>
                      currentRecord.partIdentifier +
                      " - " +
                      currentRecord.partName
                    }
                  />
                </ReferenceField>
                <TextField
                  label="Tipo di danno"
                  source="damageType"
                  emptyText="-"
                />
              </Datagrid>
            </ReferenceManyField>
          </Tab>
          <Tab label="Offerte disponibili" path="offers">
            <OfferGrid record={controllerProps.record} />
          </Tab>
          {controllerProps.record?.status >= 1 && (
            <Tab label="Pagamenti" path="payments">
              <PaymentGrid />
            </Tab>
          )}
          <Tab label="Info Cliente" path="customer-details">
            <ReferenceField
              label="Nome"
              source="driver_ID"
              reference="Drivers"
              link="show"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              label="Cognome"
              source="driver_ID"
              reference="Drivers"
              link="show"
            >
              <TextField source="surname" />
            </ReferenceField>
            <ReferenceField
              label="Numero di Telefono"
              source="driver_ID"
              reference="Drivers"
              link={false}
            >
              <TextField source="telephone" />
            </ReferenceField>
            <ReferenceField
              label="E-Mail"
              source="driver_ID"
              reference="Drivers"
              link={false}
            >
              <TextField source="email" />
            </ReferenceField>
          </Tab>
          {controllerProps.record?.status === 2 && (
            <Tab label="Feedback" path="feedback">
              <QuoteFeedbackShow record={controllerProps.record} />
            </Tab>
          )}
          <Tab label="Notifiche" path="notifications">
            <NotificationGrid record={controllerProps.record} />
          </Tab>
          <Tab label="Email" path="emails">
            <EmailGrid record={controllerProps.record} />
          </Tab>
        </TabbedShowLayout>
      </ShowView>
    )}
  </ShowController>
)

const DamagePanel = ({ record, ...props }) => {
  return (
    <Box ml={"3.5em"}>
      <Box display="flex">
        <Box flex={1} mr={"0.5em"}>
          <Labeled label="Lato di interesse">
            <ReferenceField
              label="Lato"
              source="carPart_ID"
              reference="CarParts"
              link={false}
              record={record}
              {...props}
            >
              <SideFieldImage />
            </ReferenceField>
          </Labeled>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flex={2} ml={"0.5em"}>
          <Labeled label="Descrizione del danno">
            <TextField
              label="Descrizione"
              source="description"
              emptyText="-"
              record={record}
              {...props}
            />
          </Labeled>
        </Box>
      </Box>
      <Divider />
      <Labeled label="Foto">
        <ReferenceManyField
          target="damage_ID"
          reference="DamagePictures"
          label="Foto caricate"
          {...props}
          record={record}
        >
          <SingleFieldList linkType={false}>
            <CustomImageField source="pictureUrl" link={false} />
          </SingleFieldList>
        </ReferenceManyField>
      </Labeled>
    </Box>
  )
}
const SideFieldImage = ({ record }) => {
  return (
    <ReferenceField
      source="carType_ID"
      reference="CarTypes"
      link={false}
      record={record}
    >
      <CustomImageField
        url={(currentRecord) => currentRecord["imagePath_" + record.side]}
        link={false}
      />
    </ReferenceField>
  )
}
