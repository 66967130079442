import { makeStyles } from "@material-ui/core/styles"
import { FunctionField, SelectInput } from "react-admin"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))
export const CarTypeField = ({ record, ...props }) => {
  const classes = useStyles()
  return (
    <FunctionField
      render={(currentRecord) => (
        <div className={classes.root}>
          <img
            className={classes.avatar}
            src={currentRecord.blankIcon}
            width="50"
            alt={currentRecord.typePath}
            style={{ marginRight: "0.5em" }}
          />
          <span>{currentRecord.typeName}</span>
        </div>
      )}
      record={record}
      {...props}
    />
  )
}
export const CarTypeSelect = ({ record, ...props }) => {
  const classes = useStyles()
  return (
    <SelectInput
      /*optionText="typeName"*/
      optionText={(currentRecord) => (
        <div className={classes.root}>
          <img
            className={classes.avatar}
            src={currentRecord.blankIcon}
            width="50"
            alt={currentRecord.typePath}
            style={{ marginRight: "0.5em" }}
          />
          <span>{currentRecord.typeName}</span>
        </div>
      )}
      record={record}
      {...props}
    />
  )
}
