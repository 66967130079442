import CustomAppBar from "./appBar"
import { Layout } from "react-admin"

const CustomLayout = ({ children, ...props }) => {
  return (
    <Layout {...props} appBar={CustomAppBar}>
      {children}
    </Layout>
  )
}

export default CustomLayout
