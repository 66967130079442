import { Person as PersonIcon } from "@material-ui/icons"
import { MenuItemLink, useGetIdentity, UserMenu } from "react-admin"

export const CustomUserMenu = (props) => {
  const { identity } = useGetIdentity()
  return (
    <UserMenu {...props}>
      {identity && identity.id && (
        <MenuItemLink
          to={{
            pathname: `/Operators/${identity.id}/show`,
          }}
          primaryText="Profilo operatore"
          leftIcon={<PersonIcon />}
        />
      )}
    </UserMenu>
  )
}
