import { StatusField } from "../../Customs/customFields"
import { ExpandMenuButton } from "../../Helpers/expandButton"
import { BillingAddressGrid } from "../BillingAddress/billingaddressGrid"
import { EmailGrid } from "../Email/emailGrid"
import { PhoneCallGrid } from "../PhoneCall/phonecallGrid"
import { CreateRelatedQuoteButton } from "../Quote/components/createRelatedQuote"
import { CreateRelatedTowTruckQuoteButton } from "../TowTruckQuote/components/createRelatedTowTruckQuote"
import { AvatarField, AvatarNameField, TelephoneField } from "./customFields"
import { Box, Divider, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Alert, AlertTitle } from "@material-ui/lab"
import {
  Datagrid,
  DateField,
  EditButton,
  ImageField,
  Labeled,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  useGetManyReference,
} from "react-admin"

const useImageFieldStyles = makeStyles(() => ({
  image: {
    // This will override the style of the <img> inside the <div>
    height: 25,
  },
}))
const UserTitle = ({ record }) => {
  const title = !record
    ? ""
    : record.isCompany === false
    ? `Utente: ${record.name} ${record.surname}`
    : `Azienda: ${record.companyName}`
  return <span>{title}</span>
}

const CustomActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ExpandMenuButton>
      {data && <CreateRelatedQuoteButton record={data} resource={resource} />}
      {data && (
        <CreateRelatedTowTruckQuoteButton record={data} resource={resource} />
      )}
    </ExpandMenuButton>
    <EditButton resource={resource} basePath={basePath} record={data} />
  </TopToolbar>
)

export const UserShow = (props) => (
  <Show {...props} title={<UserTitle />} actions={<CustomActions />}>
    <TabbedShowLayout>
      <Tab label="Anagrafica">
        <DataTab />
        <Divider />
        <Typography variant="h6">Fatturazione</Typography>
        <BillingAddressGrid />
      </Tab>
      <Tab label="Preventivi" path="quotes">
        <QuoteTotals />
        <ReferenceManyField
          target="driver_ID"
          reference="Quotes"
          addLabel={false}
          pagination={<Pagination />}
          perPage={10}
          sort={{ field: "requestDate", order: "DESC" }}
        >
          <Datagrid rowClick="show">
            <DateField
              label="Data richiesta"
              source="requestDate"
              allowEmpty
              showTime={true}
            />
            <StatusField label="Stato" source="status" allowEmpty />
            <TextField label="Identificativo" source="quoteIdentifier" />
            <ReferenceField
              label="Targa Veicolo"
              source="car_ID"
              reference="Cars"
              link="show"
            >
              <TextField source="plate" />
            </ReferenceField>
            <TextField label="Dettagli" source="userDetails" />
            <NumberField
              label="Importo"
              emptyText="-"
              source="finalTotal"
              options={{
                style: "currency",
                currency: "EUR",
                minimumFractionDigits: 2,
                maximumFractionDigits: 6,
              }}
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Auto registrate" path="cars">
        <ReferenceManyField
          target="driver_ID"
          reference="Cars"
          addLabel={false}
          pagination={<Pagination />}
          perPage={10}
          sort={{ field: "plate", order: "ASC" }}
        >
          <Datagrid rowClick="show">
            <ReferenceField
              label=""
              source="carType_ID"
              reference="CarTypes"
              link={false}
            >
              <ImageField source="blankIcon" classes={useImageFieldStyles()} />
            </ReferenceField>
            <TextField label="Targa" source="plate" />
            <TextField label="Modello" source="model" emptyText="-" />
            <TextField label="Marca" source="brand" emptyText="-" />
            <ReferenceField
              label="Tipo di veicolo"
              source="carType_ID"
              reference="CarTypes"
              link={false}
            >
              <TextField source="typeName" />
            </ReferenceField>
            <TextField
              label="Motore"
              source="engine_displacement"
              emptyText="-"
            />
            <TextField label="Versione" source="version" emptyText="-" />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Utenti referenziati" path="referrals">
        <ReferralCodeBanner />
        <ReferenceManyField
          target="usedRef_ID"
          reference="Drivers"
          addLabel={false}
          pagination={<Pagination />}
          perPage={10}
        >
          <Datagrid rowClick="show">
            <AvatarNameField label="Nome" />
            <TextField source="email" sortable={false} />
            <TelephoneField label="Telefono" />
            <DateField
              source="registrationDate"
              showTime
              label="Registrazione"
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Email" path="emails">
        <EmailGrid />
      </Tab>
      <Tab label="Chiamate" path="phonecalls">
        <PhoneCallGrid />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

const DataTab = ({ record, ...rest }) =>
  !record ? null : (
    <>
      <Box pb="1em">
        <Alert
          icon={false}
          severity="warning"
          style={{ marginRight: "1em", marginBottom: "1em" }}
        >
          Tipologia account:
          <b>{record.isCompany === false ? " PRIVATO" : " AZIENDA"}</b>
        </Alert>
        <Typography variant="h6" gutterBottom>
          Generalità utente
        </Typography>
        {record.isCompany && (
          <>
            <Box display="flex">
              <Box flex={4} mr="0.5em">
                <Labeled label="Azienda">
                  <TextField
                    source="companyName"
                    record={record}
                    {...rest}
                    emptyText="-"
                  />
                </Labeled>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box flex={1} ml="0.5em">
                <Labeled label="Partita IVA">
                  <TextField
                    source="VAT"
                    record={record}
                    {...rest}
                    emptyText="-"
                  />
                </Labeled>
              </Box>
            </Box>
            <Divider />
          </>
        )}
        <Box display="flex">
          <Box flex={5}>
            <Box>
              <Box display="flex">
                <Box flex={2} mr="0.5em">
                  <Labeled
                    label={`Cognome ${record.isCompany ? " Referente" : ""}`}
                  >
                    <TextField
                      source="surname"
                      record={record}
                      {...rest}
                      emptyText="-"
                    />
                  </Labeled>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box flex={2} mr="0.5em" ml="0.5em">
                  <Labeled
                    label={`Nome ${record.isCompany ? " Referente" : ""}`}
                  >
                    <TextField
                      source="name"
                      record={record}
                      {...rest}
                      emptyText="-"
                    />
                  </Labeled>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box flex={1} ml="0.5em">
                  <Labeled label="CAP">
                    <TextField
                      source="ZIPcode"
                      record={record}
                      {...rest}
                      emptyText="-"
                    />
                  </Labeled>
                </Box>
              </Box>
              <Divider />
              <Box display="flex">
                <Box flex={2} mr="0.5em">
                  <Labeled label="Email">
                    <TextField
                      source="email"
                      record={record}
                      inShow={true}
                      {...rest}
                    />
                  </Labeled>
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box flex={1} ml="0.5em">
                  <Labeled label="Telefono">
                    <TelephoneField record={record} inShow={true} {...rest} />
                  </Labeled>
                </Box>
              </Box>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box flex={1} p="0.5em">
            <AvatarField
              record={record}
              {...rest}
              size={110}
              source="profilePicture"
            />
          </Box>
        </Box>
        {!record.isCompany && (
          <>
            <Divider />
            <Box display="flex">
              <Box flex={1} mr="0.5em">
                <Labeled label="Sesso">
                  <SelectField
                    record={record}
                    {...rest}
                    emptyText="-"
                    source="sex"
                    choices={[
                      { name: "Maschio", id: "M" },
                      { name: "Femmina", id: "F" },
                      { name: "Altro", id: "A" },
                      { name: "Nessuno", id: "N" },
                    ]}
                  />
                </Labeled>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box flex={1} ml="0.5em" mr="0.5em">
                <Labeled label="Data di nascita">
                  <DateField
                    source="dateOfBirth"
                    record={record}
                    {...rest}
                    emptyText="-"
                  />
                </Labeled>
              </Box>
              <Divider orientation="vertical" flexItem />
              <Box flex={3} ml="0.5em">
                <Labeled label="Luogo di nascita">
                  <TextField
                    source="placeOfBirth"
                    record={record}
                    {...rest}
                    emptyText="-"
                  />
                </Labeled>
              </Box>
            </Box>
          </>
        )}
        {record.usedRef_iD && (
          <Alert
            severity="info"
            variant="outlined"
            style={{
              marginRight: "1em",
              marginBottom: "1em",
              marginTop: "3em",
            }}
          >
            <AlertTitle>Iscrizione referenziata da:</AlertTitle>
            <ReferenceField
              source="usedRef_ID"
              reference="Drivers"
              link="show"
              record={record}
              {...rest}
            >
              <AvatarNameField />
            </ReferenceField>
          </Alert>
        )}
      </Box>
    </>
  )
const QuoteTotals = ({ record }) => {
  const { data } = useGetManyReference(
    "Quotes",
    "driver_ID",
    record.id,
    { perPage: 10000, page: 1 },
    { field: "id", order: "ASC" }
  )
  const values = Object.values(data ?? {}) ?? []
  const totRequests = values.length ?? 0
  const openRequests = values.filter((e) => e.status <= 1).length ?? 0
  const successPercentage =
    (values.filter((e) => e.status === 2).length ?? 0) / totRequests ?? 0
  const totPaid = values
    .filter((e) => e.status === 2)
    .reduce((a, b) => (a.finalTotal ?? 0) + (b.finalTotal ?? 0), 0)
  return (
    <Box p="1em 1em 1em 0em" display="flex">
      <Box flex={1} mr="1em">
        <Typography variant="h4" align="right">
          {openRequests}
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="right">
          Richieste aperte
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={1} mr="1em" ml="1em">
        <Typography variant="h4" align="right">
          {totRequests}
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="right">
          Totale richieste
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={1} mr="1em" ml="1em">
        <Typography variant="h4" align="right">
          {((successPercentage ?? 0) * 100).toFixed(0)} %
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="right">
          Richieste pagate
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={1} ml="1em">
        <Typography variant="h4" align="right">
          € {totPaid.toFixed(2)}
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="right">
          Totale pagato
        </Typography>
      </Box>
    </Box>
  )
}
const ReferralCodeBanner = ({ record }) => (
  <Alert
    severity="info"
    variant="outlined"
    style={{ marginRight: "1em", marginBottom: "1em" }}
  >
    <AlertTitle>Codice di referenza:</AlertTitle>
    {record.referralCode}
  </Alert>
)
