import { QuoteReview } from "./components/quoteReview"
import { DashBoardTotals } from "./components/totals"
import { TowTruckQuoteReview } from "./components/towTruckQuoteReview"
import { Box } from "@material-ui/core"
import { Title } from "react-admin"

export const Dashboard = () => (
  <Box p="1em" title>
    <Title title="ra.page.dashboard" />
    <Box display="flex" m="1.5em 0em 1.5em 0em">
      <DashBoardTotals />
    </Box>
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <QuoteReview />
      </Box>

      <Box flex={1} ml="0.5em">
        <TowTruckQuoteReview />
      </Box>
    </Box>
  </Box>
)
