// in addUploadFeature.js
/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertFileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file.rawFile)

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = (requestHandler) => (type, resource, params) => {
  if (type === "CREATE" && resource === "Quotes") {
    console.log(params)
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    if (
      params.data.damages[0].pictures &&
      params.data.damages[0].pictures.length
    ) {
      // only freshly dropped pictures are instance of File

      let formerPictures = []
      let newPictures = []

      for (let i in params.data.damages) {
        formerPictures.push(
          params.data.damages[i].pictures.filter(
            (p) => !(p.rawFile instanceof File)
          )
        )
        for (let j in params.data.damages[i].pictures) {
          params.data.damages[i].pictures[j].indiceDanno = i
          newPictures.push(params.data.damages[i].pictures[j])
        }
      }

      console.log(newPictures)

      // formerPictures = formerPictures[0];
      // newPictures = newPictures[0];

      return Promise.all(newPictures.map(convertFileToBase64))
        .then((base64Pictures) =>
          base64Pictures.map((picture64, index) => ({
            base64: picture64,
            title: `${newPictures[index].title}`,
            indiceDanno: newPictures[index].indiceDanno,
          }))
        )
        .then((transformedNewPictures) => {
          const nuovoJson = {
            ...params,
            data: {
              ...params.data,
            },
          }

          for (let i in nuovoJson.data.damages) {
            nuovoJson.data.damages[i].pictures = []
          }

          for (let i in transformedNewPictures) {
            nuovoJson.data.damages[
              transformedNewPictures[i].indiceDanno
            ].pictures.push({ base64: transformedNewPictures[i].base64 })
          }

          console.log(nuovoJson)

          return requestHandler(type, resource, nuovoJson)
        })
    }
  }

  // In caso di creazione di nuova fattura, allegato a fattura o nuova guida
  // Caricamento di un file unico
  if (
    type === "CREATE" &&
    (resource === "Invoices" ||
      resource === "Attachments" ||
      resource === "Guides")
  ) {
    console.log(params)

    if (params.data.files) {
      const newFile = params.data.files

      console.log(newFile)

      return convertFileToBase64(newFile).then((base64File) => {
        console.log(base64File)

        const nuovoJson = {
          ...params,
          data: {
            ...params.data,
            files: {
              ...params.data.files,
              base64: base64File,
              title: `${newFile.title}`,
            },
          },
        }

        console.log(nuovoJson)

        return requestHandler(type, resource, nuovoJson)
      })
    }
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params)
}

export default addUploadFeature
