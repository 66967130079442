import {
  AvatarNameField,
  TelephoneField,
} from "../../Resources/User/customFields"
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Typography,
} from "@material-ui/core"
import { Link as LinkIcon } from "@material-ui/icons"
import {
  Button,
  Datagrid,
  DateField,
  Link,
  ListBase,
  ReferenceField,
  ResourceContextProvider,
} from "react-admin"

export const TowTruckQuoteReview = () => {
  return (
    <Card>
      <CardHeader
        title={
          <Typography variant="h5">Ultime richieste carroattrezzi</Typography>
        }
        action={
          <Button
            size="small"
            color="primary"
            component={Link}
            label="Vai alle richieste"
            to={{
              pathname: "/TowTruckQuotes",
            }}
          >
            <LinkIcon />
          </Button>
        }
      />
      <CardContent>
        <ResourceContextProvider resource="TowTruckQuotes">
          <ListBase
            basePath="/TowTruckQuotes"
            resource="TowTruckQuotes"
            filter={{
              called: false,
            }}
            perPage={10}
            sort={{ field: "date", order: "DESC" }}
          >
            <Datagrid rowClick="show">
              <DateField label="Data" source="date" showTime />
              <ReferenceField
                label="Utente"
                source="driver_ID"
                reference="Drivers"
                link="show"
              >
                <AvatarNameField label="Utente" />
              </ReferenceField>
              <TelephoneField label="Telefono" countryField="phoneCode" />
            </Datagrid>
          </ListBase>
        </ResourceContextProvider>
      </CardContent>
    </Card>
  )
}
