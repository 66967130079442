import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import { Cancel as IconCancel, LockOpen as IconLock } from "@material-ui/icons"
import { Fragment, useState } from "react"
import {
  Button,
  FormWithRedirect,
  PasswordInput,
  required,
  SaveButton,
  useNotify,
  useRefresh,
  useUpdate,
} from "react-admin"

const validateData = (values) => {
  const errors = {}
  if (values.repeat_password && values.password !== values.repeat_password) {
    errors.repeat_password = ["Le due password non combaciano"]
  }
  return errors
}

const OperatorChangePasswordButton = ({ onChange, record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [edit, { loading }] = useUpdate("Operators", record.id)
  const notify = useNotify()
  const refresh = useRefresh()
  const handleClick = (e) => {
    setShowDialog(true)
    e.stopPropagation()
  }

  const handleCloseClick = (e) => {
    setShowDialog(false)
    e.stopPropagation()
  }

  const handleSubmit = async (values) => {
    edit(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false)
          notify("Password modificata con successo", "info", {}, false)
          onChange()
          refresh()
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <Fragment>
      <Button onClick={handleClick} label="Cambia password">
        <IconLock />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        onClick={(e) => e.stopPropagation()}
        aria-label="Modifica password operatore"
      >
        <DialogTitle>Cambia password</DialogTitle>

        {record && (
          <FormWithRedirect
            resource="Operators"
            validate={validateData}
            initialValues={{
              id: record.id,
              name: record.name,
              surname: record.surname,
            }}
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <DialogContent>
                  <PasswordInput
                    source="password"
                    label="Nuova password"
                    validate={required()}
                    fullWidth
                    inputProps={{ minLength: 8 }}
                  />
                  <PasswordInput
                    source="repeat_password"
                    label="Ripeti password"
                    validate={required()}
                    fullWidth
                    inputProps={{ minLength: 8 }}
                  />
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                    disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            )}
          />
        )}
      </Dialog>
    </Fragment>
  )
}

export default OperatorChangePasswordButton
