import { OpenInNew as OpenInNewIcon } from "@material-ui/icons"
import { Button, FunctionField, Link, ReferenceField } from "react-admin"

export const EmailQuoteButton = ({ record, ...props }) => {
  return !record ? null : record.quote_ID ? (
    <ReferenceField
      source="quote_ID"
      reference="Quotes"
      link="show"
      record={record}
      {...props}
    >
      <FunctionField
        render={(currRec) => (
          <Button
            component={Link}
            label={`Richiesta #${currRec.quoteIdentifier ?? "-"}`}
            to={{
              pathname: `/Quotes/${currRec.id ?? "-"}/show`,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <OpenInNewIcon />
          </Button>
        )}
        InputProps={{ textAlign: "center" }}
      />
    </ReferenceField>
  ) : (
    "-"
  )
}
