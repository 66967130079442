import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  SelectField,
  TextField,
} from "react-admin"

export const GlobalsList = (props) => (
  <List {...props} bulkActionButtons={false} exporter={false}>
    <Datagrid rowClick="show">
      <TextField label="Descrizione" source="description" />
      <SelectField
        label="Tipo di dato"
        source="type"
        choices={[
          { id: "array", name: "Lista" },
          { id: "number", name: "Valore numerico" },
          { id: "string", name: "Testo" },
          { id: "object", name: "Oggetto" },
        ]}
      />
      <FunctionField
        label="Valore"
        render={(currentRecord) => {
          let value = currentRecord.value.toString()
          if (currentRecord.type === "array")
            value = currentRecord.value.length.toString() + " sezioni"
          else if (currentRecord.type === "number") {
            if (currentRecord.id.toLowerCase().includes("percentage"))
              value = (currentRecord.value * 100).toFixed(2) + "%"
            if (currentRecord.id.toLowerCase().includes("amount"))
              value = currentRecord.value.toFixed(2) + " €"
          } else if (currentRecord.type === "object") {
            value = Object.keys(currentRecord.value).join(", ")
          } else {
            value =
              currentRecord.value.length > 25
                ? `${currentRecord.value.substring(0, 23)}...`
                : currentRecord.value
          }
          return value
        }}
      />
      <EditButton label="Modifica" />
    </Datagrid>
  </List>
)
