import { ChipCustomField } from "../../Customs/chipCustomField"
import { EmailRowStyle } from "../Email/components/emailSettings"
import { Box, Divider } from "@material-ui/core"
import {
  Datagrid,
  Labeled,
  Pagination,
  ReferenceManyField,
  SelectField,
  TextField,
} from "react-admin"

export const BillingAddressGrid = ({ record }) => (
  <ReferenceManyField
    target="driver_ID"
    reference="BillingAddresses"
    sort={{ field: "date", order: "DESC" }}
    addLabel={false}
    pagination={<Pagination />}
    perPage={10}
    record={record}
  >
    <Datagrid rowStyle={EmailRowStyle} expand={<BillingAddressPanel />}>
      <ChipCustomField label="Tipo">
        <SelectField
          source="isCompany"
          emptyText="Nessun tipo"
          choices={[
            { id: true, name: "Azienda" },
            { id: false, name: "Privato" },
          ]}
        />
      </ChipCustomField>
      <TextField source="fullName" allowEmpty emptyText="-" />
      <TextField source="address" allowEmpty emptyText="-" />
      <TextField source="secondLine" allowEmpty emptyText="-" />
      <TextField source="ZIPcode" allowEmpty emptyText="-" />
      <TextField source="city" allowEmpty emptyText="-" />
      <TextField source="province" allowEmpty emptyText="-" />
      <TextField source="country" allowEmpty emptyText="-" />
    </Datagrid>
  </ReferenceManyField>
)

const BillingAddressPanel = ({ record, ...props }) =>
  record && (
    <Box ml={"3.5em"}>
      <Labeled label="Info aggiuntive">
        <TextField
          source="secondLine"
          emptyText="-"
          record={record}
          {...props}
        />
      </Labeled>
      {record.isCompany === true && (
        <>
          <Divider />
          <Box display="flex">
            <Box flex={1} mr={"0.5em"}>
              <Labeled label="Codice Fiscale">
                <TextField
                  source="fiscalCode"
                  emptyText="-"
                  record={record}
                  {...props}
                />
              </Labeled>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box flex={1} ml={"0.5em"} mr={"0.5em"}>
              <Labeled label="Partita IVA">
                <TextField
                  source="VAT"
                  emptyText="-"
                  record={record}
                  {...props}
                />
              </Labeled>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box flex={1} ml={"0.5em"} mr={"0.5em"}>
              <Labeled label="Codice Fatturazione elettronica">
                <TextField
                  source="eiCode"
                  emptyText="-"
                  record={record}
                  {...props}
                />
              </Labeled>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box flex={1} ml={"0.5em"}>
              <Labeled label="PEC">
                <TextField
                  source="certifiedEmail"
                  emptyText="-"
                  record={record}
                  {...props}
                />
              </Labeled>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
