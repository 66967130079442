import { dateParser } from "../../Helpers/date"
import { regexTelephone } from "../../Helpers/regex"
import { Box, Divider, Typography } from "@material-ui/core"
import { useEffect } from "react"
import {
  AutocompleteInput,
  Create,
  DateTimeInput,
  FormDataConsumer,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetOne,
} from "react-admin"
import { useForm } from "react-final-form"

const TowTruckCreateForm = ({ resource }) => (
  <Box p="0.5em">
    <Typography variant="h6">Generali</Typography>
    <Box display="flex">
      <Box flex={1} mr="0.5em">
        <DateTimeInput
          fullWidth
          parse={dateParser}
          resource={resource}
          source="date"
          validate={required()}
        />
      </Box>
      <Box flex={2} ml="0.5em" mr="0.5em">
        <ReferenceInput
          fullWidth
          resource={resource}
          source="driver_ID"
          reference="Drivers"
          perPage={5000}
          validate={required()}
          sort={{ field: "surname", order: "ASC" }}
        >
          <AutocompleteInput
            optionText={(rec) => `${rec?.surname} ${rec?.name} (${rec?.email})`}
          />
        </ReferenceInput>
      </Box>
      <FormDataConsumer>
        {({ formData }) => (
          <DriverTelephone formData={formData} resource={resource} />
        )}
      </FormDataConsumer>
    </Box>
    <TextInput
      fullWidth
      resource={resource}
      source="location"
      validate={required()}
      multiline
      helperText="Indirizzo completo del luogo in cui si trova il veicolo"
    />
    <Divider style={{ marginBottom: "1em", marginTop: "1em" }} />
    <Typography variant="h6">Dettagli</Typography>

    <TextInput
      fullWidth
      resource={resource}
      source="details"
      hiddenLabel
      rows={3}
      multiline
      validate={required()}
      helperText="Tutti i dettagli forniti dell'utente in merito al sinistro o danno"
    />
    <SelectInput
      resource={resource}
      source="called"
      choices={[
        { name: "No", id: false },
        { name: "Sì", id: true },
      ]}
      helperText="Indicare se è già stato contattato per il recupero"
    />
  </Box>
)

const DriverTelephone = ({ formData, resource }) => {
  const { data } = useGetOne("Drivers", formData.driver_ID ?? 0)
  const form = useForm()
  useEffect(() => {
    if (data) {
      form.change("phoneCode", data.countryPhonecode ?? "+39")
      form.change("telephone", data.telephone.replace(" ", ""))
    }
  }, [data, form])
  return (
    <>
      <Box flex={0.5} ml="0.5em" mr="0.5em">
        <TextInput
          fullWidth
          resource={resource}
          source="phoneCode"
          disabled={!formData.driver_ID}
          validate={required()}
        />
      </Box>
      <Box flex={1} ml="0.5em">
        <TextInput
          fullWidth
          resource={resource}
          source="telephone"
          disabled={!formData.driver_ID}
          validate={(required(), regex(regexTelephone))}
        />
      </Box>
    </>
  )
}

export const TowTruckQuoteCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      redirect="show"
      initialValues={{
        phoneCode: "+39",
        date: new Date(),
        called: false,
      }}
    >
      <TowTruckCreateForm />
    </SimpleForm>
  </Create>
)
