import { ChipCustomField } from "../../Customs/chipCustomField"
import { AvatarNameField } from "../User/customFields"
import { EmailRowStyle } from "./components/emailSettings"
import { useEffect, useState } from "react"
import {
  BooleanField,
  Datagrid,
  DateField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SelectField,
  useGetOne,
} from "react-admin"

export const EmailGrid = ({ record, resource }) => {
  const { data, loading, error } = useGetOne("Globals", "emailTypes")
  const [emailSettings, setEmailSettings] = useState([])

  useEffect(() => {
    if (!loading && !error) setEmailSettings(data?.value || [])
  }, [data, loading, error])
  return (
    <>
      <ReferenceManyField
        target={resource === "Drivers" ? "driver_ID" : "quote_ID"}
        reference="SibEmails"
        sort={{ field: "date", order: "DESC" }}
        addLabel={false}
        pagination={<Pagination />}
        perPage={10}
        record={record}
      >
        <Datagrid rowStyle={EmailRowStyle}>
          <ChipCustomField label="Tipo">
            <SelectField
              source="template"
              emptyText="Nessun tipo"
              choices={emailSettings}
            />
          </ChipCustomField>
          <ReferenceField
            source="driver_ID"
            reference="Drivers"
            link="show"
            allowEmpty
            emptyText="Tutti gli utenti"
          >
            <AvatarNameField />
          </ReferenceField>

          <DateField
            source={record.plannedAt ? "plannedAt" : "date"}
            showTime={true}
            allowEmpty
            emptyText="-"
          />
          <BooleanField
            source="sent"
            valueLabelTrue="Inviata"
            valueLabelFalse="Non inviata"
          />
          <BooleanField
            source="received"
            valueLabelTrue="Ricevuta"
            valueLabelFalse="Non ricevuta"
          />
          <BooleanField
            source="open"
            valueLabelTrue="Aperta"
            valueLabelFalse="Non aperta"
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  )
}
