import { Show, SimpleShowLayout, TextField } from "react-admin"

export const AddOnShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="name" emptyText="-" />
      <TextField source="details" emptyText="-" />
    </SimpleShowLayout>
  </Show>
)
