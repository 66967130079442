import { SideField } from "../../Customs/customFields"
import { Box, Typography } from "@material-ui/core"
import {
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  ShowButton,
  TextField,
} from "react-admin"

export const PanelShow = ({ filterValues, ...props }) => {
  return filterValues.status === 1 ? (
    <OffersPanelShow filterValues {...props} />
  ) : filterValues.status === 2 ? (
    <PaymentsPanelShow filterValues {...props} />
  ) : (
    <DamagesPanelShow filterValues {...props} />
  )
}

const OffersPanelShow = ({ ...props }) => (
  <Box>
    <Typography variant="h6" gutterBottom align="center">
      Offerte elaborate
    </Typography>
    <ReferenceManyField
      target="quote_ID"
      reference="Offers"
      addLabel={false}
      sort={{ field: "discountTotal", order: "ASC" }}
      {...props}
    >
      <Datagrid rowClick="show" optimized>
        <BooleanField source="chosen" label="" />
        <ReferenceField
          label="Carrozzeria"
          source="repShop_ID"
          reference="RepairShops"
          link="show"
        >
          <FunctionField
            render={(currRecord) => `${currRecord.name} (${currRecord.city})`}
          />
        </ReferenceField>
        <DateField source="pickupTime" showTime label="Data ritiro" />
        <DateField source="pickupTime" showTime label="Data restituzione" />
        <NumberField
          source="total"
          label="Totale"
          options={{ style: "currency", currency: "EUR" }}
          allowEmpty
        />
        <NumberField
          source="discountTotal"
          label="Totale scontato"
          options={{ style: "currency", currency: "EUR" }}
          allowEmpty
        />
      </Datagrid>
    </ReferenceManyField>
  </Box>
)

const DamagesPanelShow = ({ ...props }) => (
  <Box>
    <Typography variant="h6" gutterBottom align="center">
      Danni evidenziati
    </Typography>

    <ReferenceManyField
      target="quote_ID"
      reference="Damages"
      addLabel={false}
      sort={{ field: "partName", order: "ASC" }}
      {...props}
    >
      <Datagrid>
        <ReferenceField
          label="Lato"
          source="carPart_ID"
          reference="CarParts"
          link={false}
        >
          <SideField source="side" />
        </ReferenceField>
        <ReferenceField
          label="Parte danneggiata"
          source="carPart_ID"
          reference="CarParts"
          link={false}
        >
          <TextField source="partName" />
        </ReferenceField>
        <TextField label="Descrizione" source="description" />
        <ShowButton label="Mostra" />
      </Datagrid>
    </ReferenceManyField>
  </Box>
)
const PaymentsPanelShow = ({ ...props }) => (
  <Box>
    <Typography variant="h6" gutterBottom align="center">
      Pagamenti
    </Typography>
    <ReferenceManyField
      target="quote_ID"
      reference="Payments"
      addLabel={false}
      sort={{ field: "date", order: "DESC" }}
      {...props}
    >
      <Datagrid rowClick="show" optimized>
        <BooleanField source="confirmed" label="" />
        <DateField
          source="date"
          showTime
          label="Data pagamento"
          emptyText="-"
        />
        <TextField
          label="Metodo di pagamento"
          source="paymentMethod"
          emptyText="-"
        />

        <NumberField
          source="paidNet"
          label="Totale netto"
          options={{ style: "currency", currency: "EUR" }}
          allowEmpty
        />
        <NumberField
          source="creditUsed"
          label="Credito usato"
          options={{ style: "currency", currency: "EUR" }}
          allowEmpty
        />
        <NumberField
          source="paidTotal"
          label="Totale"
          options={{ style: "currency", currency: "EUR" }}
          allowEmpty
        />
      </Datagrid>
    </ReferenceManyField>
  </Box>
)
