import { Box, Card, CardContent, Typography } from "@material-ui/core"
import { useVersion } from "ra-core"
import { useGetList, useRefresh } from "react-admin"

export const DashBoardTotals = () => {
  return (
    <>
      <UsersBox index={0} />
      <QuotesBox index={1} />
      <TowTrucksBox index={2} />
      <PaymentsBox index={3} />
    </>
  )
}

const UsersBox = (props) => {
  const refresh = useRefresh()
  const version = useVersion()
  const { total } = useGetList(
    "Drivers",
    { page: 1, perPage: 1 },
    { field: "id", order: "ASC" },
    {},
    {
      version,
      onFailure: () => {
        refresh()
      },
    }
  )
  return <SingleBox data={total ?? 0} subtitle="Utenti Iscritti" {...props} />
}
const TowTrucksBox = (props) => {
  const refresh = useRefresh()
  const version = useVersion()
  const { total } = useGetList(
    "TowTruckQuotes",
    { page: 1, perPage: 1 },
    { field: "id", order: "ASC" },
    {},
    {
      version,
      onFailure: () => {
        refresh()
      },
    }
  )
  return (
    <SingleBox
      data={total ?? 0}
      subtitle="Richieste di preventivo"
      {...props}
    />
  )
}
const QuotesBox = (props) => {
  const refresh = useRefresh()
  const version = useVersion()
  const { total } = useGetList(
    "Quotes",
    { page: 1, perPage: 1 },
    { field: "id", order: "ASC" },
    {},
    {
      version,
      onFailure: () => {
        refresh()
      },
    }
  )
  return (
    <SingleBox
      data={total ?? 0}
      subtitle="Richieste di carroattrezzi"
      {...props}
    />
  )
}
const PaymentsBox = (props) => {
  const refresh = useRefresh()
  const version = useVersion()
  const { total } = useGetList(
    "Payments",
    { page: 1, perPage: 1 },
    { field: "id", order: "ASC" },
    { paid: true },
    {
      version,
      onFailure: () => {
        refresh()
      },
    }
  )
  return (
    <SingleBox data={total ?? 0} subtitle="Pagamenti ricevuti" {...props} />
  )
}
const SingleBox = ({ data, subtitle, index }) => (
  <Box
    p="0em"
    flex={1}
    mr={(index !== 3 ? 0.5 : 0).toFixed(1) + "em"}
    ml={(index !== 0 ? 0.5 : 0).toFixed(1) + "em"}
  >
    <Card p="0.5em">
      <CardContent>
        <Typography variant="h4" align="right">
          {data}
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="right">
          {subtitle}
        </Typography>
      </CardContent>
    </Card>
  </Box>
)
