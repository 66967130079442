import red from "@material-ui/core/colors/red"
import { createTheme } from "@material-ui/core/styles"

export const myTheme = createTheme({
  palette: {
    primary: { 500: "#C09205" },
    secondary: { A400: "#F2BC14" },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        color: "black", // Some CSS
      },
    },
  },
  spacing: 0,
})
