import { TelephoneField } from "../User/customFields"
import {
  Datagrid,
  EditButton,
  Filter,
  FilterLiveSearch,
  List,
  TextField,
  TextInput,
} from "react-admin"

const RepairShopFilter = (props) => (
  <Filter {...props} title="Filtri">
    <FilterLiveSearch source="q" alwaysOn />
    <TextInput label="CAP da" source="ZIPfrom" alwaysOn />
    <TextInput label="CAP fino" source="ZIPto" alwaysOn />
  </Filter>
)

export const RepairShopList = (props) => (
  <List
    {...props}
    filters={<RepairShopFilter />}
    exporter={false}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <TextField label="Codice" source="intIdentifier" emptyText="-" />
      <TextField label="Nome" source="name" />
      <TextField label="Città" source="city" />
      <TextField label="Indirizzo email" source="emailAddress" emptyText="-" />
      <TelephoneField
        label="Telefono fisso"
        countryField="phoneCode"
        phoneField="telephone"
      />
      <TelephoneField
        label="Cellulare"
        countryField="waCountryPhonecode"
        phoneField="waTelephone"
      />
      <EditButton />
    </Datagrid>
  </List>
)
