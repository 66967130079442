export const isValid = (d, m, y) => {
  if (m < 1 || m > 12) return false
  if (d < 1 || d > 31) return false
  if (m === 2) {
    if (isLeap(y)) return d <= 29
    else return d <= 28
  }
  if (m === 4 || m === 6 || m === 9 || m === 11) return d <= 30

  return true
}

export const dateSplitter = (isadate) => {
  var splittedDate = isadate.split("-")
  return splittedDate
}

function isLeap(year) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
}

export const dateParser = (v) => {
  // v is a string of "YYYY-MM-DD" format
  const match = /(\d{4})-(\d{2})-(\d{2})/.exec(v)
  if (match === null) return
  const d = new Date(match[1], parseInt(match[2], 10) - 1, match[3])
  if (isNaN(d)) return
  return d
}
