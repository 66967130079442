import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import {
  Cancel as IconCancel,
  Send as IconContentAdd,
} from "@material-ui/icons"
import { useState } from "react"
import {
  Button,
  FormWithRedirect,
  SaveButton,
  TextInput,
  useCreate,
  useNotify,
  useRefresh,
} from "react-admin"

const WhatsappMessageCreateButton = ({
  onChange,
  disabled,
  repShop_ID,
  conversation_ID,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("WhatsappMessages")
  const notify = useNotify()
  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }
  const handleSubmit = async (values) => {
    create(
      {
        payload: {
          data: {
            message: values.message,
            from: "operator",
            to: "repShop",
            date: new Date(),
            repShop_ID: repShop_ID,
            conversation_ID: conversation_ID,
          },
        },
      },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false)
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          onChange()
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button onClick={handleClick} label="Invia" disabled={disabled}>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Invia messaggio"
      >
        <DialogTitle>Invia messaggio</DialogTitle>

        <FormWithRedirect
          resource="WhatsappMessages"
          initialValues={{}}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => {
            return (
              <>
                <DialogContent>
                  <TextInput
                    source="message"
                    multiline
                    rows={6}
                    label="Messaggio"
                    fullWidth
                  />
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                    disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                    label="Invia"
                    icon={<IconContentAdd />}
                  />
                </DialogActions>
              </>
            )
          }}
        />
      </Dialog>
    </>
  )
}

export default WhatsappMessageCreateButton
