import { withStyles } from "@material-ui/core"
import {
  Datagrid,
  EditButton,
  Filter,
  FilterLiveSearch,
  ImageField,
  List,
  TextField,
} from "react-admin"

const AddOnFilter = (props) => (
  <Filter {...props} title="Filtri">
    <FilterLiveSearch source="q" alwaysOn />
  </Filter>
)
const styles = {
  image: { maxHeight: "2.3rem", padding: "0", margin: "0" },
}

export const AddOnList = withStyles(styles)(({ classes, ...props }) => (
  <List
    {...props}
    filters={<AddOnFilter />}
    exporter={false}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="show">
      <ImageField source="iconUrl" emptyText="-" label="" classes={classes} />
      <TextField source="name" emptyText="-" />
      <TextField source="details" emptyText="-" />
      <EditButton />
    </Datagrid>
  </List>
))
