import { StatusField } from "../../Customs/customFields"
import { ExpandMenuButton } from "../../Helpers/expandButton"
import { CreateRelatedQuoteButton } from "../Quote/components/createRelatedQuote"
import { AvatarNameField } from "../User/customFields"
import { CarTypeField } from "./components/carTypeField"
import { Box, Divider, Typography } from "@material-ui/core"
import {
  Datagrid,
  DateField,
  EditButton,
  Labeled,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin"

const CarTitle = ({ record }) => {
  return <span>{record ? `Dettagli Auto` : ""}</span>
}

const CustomActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ExpandMenuButton>
      {data && <CreateRelatedQuoteButton record={data} resource={resource} />}
    </ExpandMenuButton>
    <EditButton resource={resource} basePath={basePath} record={data} />
  </TopToolbar>
)

const DataTab = ({ record, ...rest }) => (
  <Box>
    <Typography gutterBottom variant="h5" align="center">
      Dati veicolo
    </Typography>

    <Box>
      <Box display="flex">
        <Box flex={2} mr="0.5em">
          <Labeled label="Utente">
            <ReferenceField
              source="driver_ID"
              reference="Drivers"
              link="show"
              record={record}
              {...rest}
            >
              <AvatarNameField />
            </ReferenceField>
          </Labeled>
        </Box>
      </Box>
      <Divider />
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <Labeled label="Targa">
            <TextField source="plate" record={record} {...rest} emptyText="-" />
          </Labeled>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flex={1} ml="0.5em">
          <Labeled label="Tipo veicolo">
            <ReferenceField
              source="carType_ID"
              reference="CarTypes"
              link={false}
              record={record}
              {...rest}
            >
              <CarTypeField />
            </ReferenceField>
          </Labeled>
        </Box>
        <Divider orientation="vertical" flexItem />

        <Box flex={1} mr="0.5em" ml="0.5em">
          <Labeled label="Marchio">
            <TextField source="brand" record={record} {...rest} emptyText="-" />
          </Labeled>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flex={1} mr="0.5em" ml="0.5em">
          <Labeled label="Modello">
            <TextField source="model" record={record} {...rest} emptyText="-" />
          </Labeled>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flex={1} mr="0.5em" ml="0.5em">
          <Labeled label="Versione">
            <TextField
              source="version"
              record={record}
              {...rest}
              emptyText="-"
            />
          </Labeled>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flex={1} ml="0.5em">
          <Labeled label="Cilindrata">
            <TextField
              source="engine_displacement"
              record={record}
              {...rest}
              emptyText="-"
            />
          </Labeled>
        </Box>
      </Box>
    </Box>
    <Divider />
    <Typography
      gutterBottom
      variant="h5"
      style={{ marginTop: "2em" }}
      align="center"
    >
      Richieste di preventivo
    </Typography>
    <ReferenceManyField
      target="car_ID"
      reference="Quotes"
      sort={{ field: "requestDate", order: "DESC" }}
      addLabel={false}
      pagination={<Pagination />}
      perPage={10}
      record={record}
      {...rest}
    >
      <Datagrid rowClick="show">
        <DateField
          label="Data richiesta"
          source="requestDate"
          showTime
          emptyText="-"
        />
        <TextField
          label="Identificativo"
          source="quoteIdentifier"
          emptyText="-"
        />
        <DateField
          label="Data di scadenza"
          source="expDate"
          allowEmpty
          emptyText="-"
        />
        <StatusField label="Stato" source="status" />
      </Datagrid>
    </ReferenceManyField>
  </Box>
)
export const CarShow = (props) => (
  <Show {...props} title={<CarTitle />} actions={<CustomActions />}>
    <SimpleShowLayout>
      <DataTab />
    </SimpleShowLayout>
  </Show>
)
