import { AvatarNameField } from "../User/customFields"
import { CarTypeSelect } from "./components/carTypeField"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import { Cancel as IconCancel, Add as IconContentAdd } from "@material-ui/icons"
import { useState } from "react"
import {
  Button,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
} from "react-admin"
import { useForm } from "react-final-form"

const CarQuickCreateButton = ({ onChange, disabled, driver_ID }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("Cars")
  const notify = useNotify()
  const form = useForm()
  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }
  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false)
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          form.change("car_ID", data.id)
          onChange()
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label="ra.action.create"
        disabled={disabled}
      >
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Create post"
      >
        <DialogTitle>Aggiungi veicolo</DialogTitle>

        <FormWithRedirect
          resource="Cars"
          initialValues={{ driver_ID: driver_ID }}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <ReferenceInput
                  fullWidth
                  label="Utente"
                  source="driver_ID"
                  reference="Drivers"
                  perPage={5000}
                  sort={{ field: "surname", order: "ASC" }}
                  disabled
                >
                  <SelectInput
                    fullWidth
                    optionText={(currentRecord) => (
                      <AvatarNameField record={currentRecord} />
                    )}
                  />
                </ReferenceInput>
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextInput
                      source="plate"
                      label="Targa"
                      validate={required()}
                      fullWidth
                      inputProps={{ maxLenght: 7 }}
                    />
                  </Box>
                  <Box flex={2} mr="0.5em">
                    <ReferenceInput
                      fullWidth
                      label="Tipo veicolo"
                      source="carType_ID"
                      reference="CarTypes"
                      perPage={5000}
                      sort={{ field: "typeName", order: "ASC" }}
                    >
                      <CarTypeSelect />
                    </ReferenceInput>
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextInput source="brand" label="Marchio" fullWidth />
                  </Box>
                  <Box flex={1} mr="0.5em">
                    <TextInput source="model" label="Modello" fullWidth />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box flex={1} mr="0.5em">
                    <TextInput source="version" label="Versione" fullWidth />
                  </Box>
                  <Box flex={1} mr="0.5em">
                    <TextInput
                      source="engine_displacement"
                      label="Cilindrata"
                      fullWidth
                    />
                  </Box>
                </Box>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

export default CarQuickCreateButton
