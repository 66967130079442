import WhatsappMessageCreateButton from "./whatsappMessageCreate"
import {
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core"
import { useEffect, useRef } from "react"
import {
  Show,
  SimpleShowLayout,
  TopToolbar,
  useGetManyReference,
  useRefresh,
} from "react-admin"

const ListActions = ({ basePath, data }) => {
  const refresh = useRefresh()

  return (
    <TopToolbar>
      {data && (
        <WhatsappMessageCreateButton
          repShop_ID={data.repShop_ID}
          conversation_ID={data.id}
          onChange={() => {
            refresh()
          }}
        />
      )}
    </TopToolbar>
  )
}
const ChatList = (props) => {
  const refresh = useRefresh()
  const scrollRef = useRef(null)
  const { data, isLoading, error } = useGetManyReference(
    "WhatsappMessages",
    "conversation_ID",
    props.record.id,
    { page: 1, perPage: 5000 },
    { field: "date", order: "DESC" }
  )
  useEffect(() => {
    const interval = setInterval(() => refresh(), 5000)
    return () => {
      clearInterval(interval)
    }
  }, [])
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: "smooth" })
    }
  }, [data])
  return (
    <div style={{ maxHeight: 450, overflow: "auto", border: 0 }}>
      <List>
        {!data ? (
          <>
            {isLoading && (
              <Typography variant="subtitle1">
                Caricamento in corso...
              </Typography>
            )}
            {error && (
              <Typography variant="subtitle1">
                Si è verificato un errore, riprovare...
              </Typography>
            )}
          </>
        ) : Object.values(data).length > 0 ? (
          Object.values(data).map((item, i) => (
            <ListItem alignItems="center" key={i}>
              <ListItemText
                primary={
                  <Grid
                    justify="space-between" // Add it here :)
                    container
                    spacing={24}
                  >
                    {item.from !== "operator" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Chip label={item.message} color="primary" />
                        <Typography
                          variant="caption"
                          mt={0.5}
                          style={{ color: "#949494" }}
                        >
                          {new Intl.DateTimeFormat("it-IT", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: false,
                          }).format(new Date(item.date))}
                        </Typography>
                      </div>
                    ) : (
                      <div />
                    )}
                    {item.from === "operator" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Chip label={item.message} />
                        <Typography
                          variant="caption"
                          mt={0.5}
                          style={{ color: "#949494" }}
                        >
                          {new Intl.DateTimeFormat("it-IT", {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            hour12: false,
                          }).format(new Date(item.date))}
                        </Typography>
                      </div>
                    ) : (
                      <div />
                    )}
                  </Grid>
                }
              />
            </ListItem>
          ))
        ) : (
          <Typography variant="subtitle1">Nessun elemento presente</Typography>
        )}
        <div ref={scrollRef} />
      </List>
    </div>
  )
}
export const WhatsappConversationShow = (props) => (
  <Show {...props} actions={<ListActions />}>
    <SimpleShowLayout>
      <ChatList />
    </SimpleShowLayout>
  </Show>
)
