import { CarTypeSelect } from "./components/carTypeField"
import { Box } from "@material-ui/core"
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"

const DataForm = ({ record, resource }) => {
  return (
    <Box display="flex">
      <Box flex={2}>
        <ReferenceInput
          fullWidth
          source="driver_ID"
          reference="Drivers"
          validate={required()}
          resource={resource}
          record={record}
          perPage={5000}
          disabled
          sort={{ field: "surname", order: "ASC" }}
        >
          <AutocompleteInput
            optionText={(rec) => `${rec?.surname} ${rec?.name} (${rec?.email})`}
          />
        </ReferenceInput>
        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <TextInput
              source="plate"
              validate={required()}
              resource={resource}
              record={record}
              fullWidth
              inputProps={{ maxLength: 7 }}
              disabled
            />
          </Box>
          <Box flex={2} mr="0.5em">
            <ReferenceInput
              fullWidth
              source="carType_ID"
              reference="CarTypes"
              resource={resource}
              record={record}
              perPage={5000}
              validate={required()}
              disabled
              sort={{ field: "typeName", order: "ASC" }}
            >
              <CarTypeSelect />
            </ReferenceInput>
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <TextInput
              source="brand"
              validate={required()}
              fullWidth
              resource={resource}
              record={record}
            />
          </Box>
          <Box flex={1} mr="0.5em">
            <TextInput
              source="model"
              validate={required()}
              fullWidth
              resource={resource}
              record={record}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box flex={1} mr="0.5em">
            <TextInput
              source="version"
              fullWidth
              resource={resource}
              record={record}
            />
          </Box>
          <Box flex={1} mr="0.5em">
            <TextInput
              source="engine_displacement"
              fullWidth
              resource={resource}
              record={record}
            />
          </Box>
        </Box>
      </Box>
      <Box flex={2}></Box>
    </Box>
  )
}
export const CarEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <DataForm />
    </SimpleForm>
  </Edit>
)
