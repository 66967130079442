import { ReferenceArrayInput, SelectArrayInput, SimpleForm } from "react-admin"

const validateShareLinkToRepairShopsCreate = (values) => {
  const errors = {}
  if (!values.RepairShop_ids) {
    errors.RepairShop_ids = ["Inserire almeno una carrozzeria"]
  }
  return errors
}

/*   MuiFormControl-root-123 MuiFormControl-marginNormal-124 SelectArrayInput-root-394 FormInput-input-330 */

export const ShareLinkToRepairShopsCreate = (props) => (
  <SimpleForm
    validate={validateShareLinkToRepairShopsCreate}
    redirect={"/Quotes"}
    onSubmit={(data) => {}}
  >
    <ReferenceArrayInput
      label="Scegli Carrozzerie a cui inviare il preventivo"
      source="RepairShop_ids"
      reference="RepairShops"
    >
      <SelectArrayInput
        className="selectSShareLinkToRepairShopsCreate"
        optionText="name"
      />
    </ReferenceArrayInput>
  </SimpleForm>
)
