import { AvatarNameField } from "../User/customFields"
import WhatsappConversationCreateButton from "./whatsappConversationCreate"
import {
  Datagrid,
  Filter,
  FilterLiveSearch,
  FunctionField,
  List,
  ReferenceField,
  ShowButton,
  TextField,
  TopToolbar,
} from "react-admin"

const WhatsappMessageFilter = (props) => (
  <Filter {...props} title="Filtri">
    <FilterLiveSearch source="q" alwaysOn />
  </Filter>
)
const ListActions = ({ basePath, data }) => (
  <TopToolbar>
    <WhatsappConversationCreateButton basePath={basePath} record={data} />
  </TopToolbar>
)
export const WhatsappConversationList = (props) => (
  <List
    {...props}
    filters={<WhatsappMessageFilter />}
    exporter={false}
    bulkActionButtons={false}
    actions={<ListActions />}
  >
    <Datagrid rowClick="show">
      <ReferenceField
        label="Carrozziere"
        source="repShop_ID"
        reference="RepairShops"
        link="show"
      >
        <FunctionField
          render={(currRecord) => `${currRecord.name} - ${currRecord.city}`}
        />
      </ReferenceField>

      <TextField label="Numero" source="number" emptyText="-" />

      <ShowButton />
    </Datagrid>
  </List>
)
