import { CustomImageField } from "../../Customs/customImageField"
import { RatingField } from "../../Customs/ratingField"
import { Box, Divider } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import {
  BooleanField,
  Labeled,
  ReferenceManyField,
  RichTextField,
  SingleFieldList,
  useGetManyReference,
} from "react-admin"

export const QuoteFeedbackShow = ({ record, ...props }) => {
  const { ids, data } = useGetManyReference(
    "Feedbacks",
    "quote_ID",
    record.id,
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    {}
  )
  if (!record) {
    return (
      <Alert severity="error">
        Il cliente non ha ancora fornito un feedback per questo preventivo.
      </Alert>
    )
  }
  return data && Object.values(data).length > 0 ? (
    <Box>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <Labeled label="Rating">
            <RatingField source="stars" record={data[ids[0]]} max={5} />
          </Labeled>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flex={1} mr="0.5em" ml="0.5em">
          <Labeled label="Valutazione allineamento">
            <BooleanField
              record={data[ids[0]]}
              source="alignEvaluation"
              allowEmpty
            />
          </Labeled>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flex={1} mr="0.5em" ml="0.5em">
          <Labeled label="Valutazione verniciatura">
            <BooleanField
              record={data[ids[0]]}
              source="paintEvaluation"
              allowEmpty
            />
          </Labeled>
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box flex={3} ml="0.5em">
          <Labeled label="Valutazione">
            <RichTextField record={data[ids[0]]} source="review" />
          </Labeled>
        </Box>
      </Box>
      <Divider />
      <Labeled label="Immagini">
        <ReferenceManyField
          target="originId"
          reference="Attachments"
          addLabel={false}
          record={data[ids[0]]}
          filter={{ originType: "Feedback" }}
          {...props}
        >
          <SingleFieldList linkType={false}>
            <CustomImageField source="fileUrl" link={false} />
          </SingleFieldList>
        </ReferenceManyField>
      </Labeled>
    </Box>
  ) : (
    <Alert severity="error">
      Il cliente non ha ancora fornito un feedback per questo preventivo.
    </Alert>
  )
}
