import { API_URL } from "../Helpers/variabiliGlobali"

export const queryStringCreator = (resource, query, ...metodo) => {
  var queryString = API_URL + "/" + resource
  var firstObject = true

  //Nel caso in cui la richiesta sia un get_one la richiesta deve essere del tipo risorsa/id... (Es. Drivers/50access_token=ddsd2213 ...)
  if (metodo != null && metodo[0] === "GET_ONE") {
    queryString += "/" + query["filter"]["id"]
  }

  for (var key in query) {
    //Solamante prima del primo filtro (Solitamente l'access token) deve esserci il carattere '?'
    if (firstObject) {
      queryString += "?"
      firstObject = false
    } else {
      queryString += "&"
    }

    switch (key) {
      case "access_token":
        queryString += "access_token=" + query[key]
        break
      case "order":
        queryString += "filter[order]=" + query[key][0] + "%20" + query[key][1]
        break
      case "range":
        queryString +=
          "filter[skip]=" + query[key][0] + "&filter[limit]=" + query[key][1]
        break
      case "filter":
        for (var filterKey in query[key]) {
          if (
            filterKey === "quoteIdentifier" ||
            filterKey === "surname" ||
            filterKey === "name" ||
            filterKey === "city"
          ) {
            let str = query[key][filterKey]
            str = str.replace(/\s/g, "%25")
            queryString +=
              "filter[where][and][0][" +
              filterKey +
              "][like]=%25" +
              str +
              "%25&"
          } else if (filterKey === "requestDate") {
            let prevDate = query[key][filterKey] + "T00:00:00.000Z"
            let nextDate = query[key][filterKey] + "T23:59:59.999Z"
            queryString +=
              "filter[where][requestDate][between][0]=" + prevDate + "&"
            queryString +=
              "filter[where][requestDate][between][1]=" + nextDate + "&"
          } else if (filterKey === "ZIPfrom") {
            let prevZIP = query[key][filterKey]
            if (query[key]["ZIPto"]) {
              //Se è già stato impostato un cap successivo faccio il filtro da ZIPfrom a ZIPto
              queryString += "filter[where][ZIP][between][0]=" + prevZIP + "&"
            } else {
              const maxZIP = 99999
              queryString += `filter[where][ZIP][between][0]=${prevZIP}&filter[where][ZIP][between][1]=${maxZIP}&`
            }
          } else if (filterKey === "ZIPto") {
            let nextZIP = query[key][filterKey]
            if (query[key]["ZIPfrom"]) {
              //Se è già stato impostato il cap precedente faccio il filtro da ZIPfrom a ZIPto
              queryString += "filter[where][ZIP][between][1]=" + nextZIP + "&"
            } else {
              const minZIP = 0
              queryString += `filter[where][ZIP][between][0]=${minZIP}&filter[where][ZIP][between][1]=${nextZIP}&`
            }
          } else if (
            Array.isArray(query[key][filterKey]) &&
            query[key][filterKey].length > 1
          ) {
            // Caso di tabelle ManyToMany ( Da tabella di relazione a seconda tabella)
            console.log(query[key][filterKey])
            for (let i = 0; i < query[key][filterKey].length; i++) {
              queryString +=
                "filter[where][" +
                filterKey +
                "][inq]=" +
                query[key][filterKey][i] +
                "&"
            }
          } else {
            //CASO GENERALE
            queryString +=
              "filter[where][" + filterKey + "]=" + query[key][filterKey] + "&"
          }
        }
        break
      default:
        break
    }
  }

  /*
    Non mostro gli addOns e i carrozzieri cancellati, poichè se vengono cancellati da console rimangono nel db ma con
    il campo deleted = true, in modo da mantenere le relazioni con i preventivi e le altre entità
  */
  if (resource === "AddOns" || resource === "RepairShops") {
    queryString += "filter[where][deleted]=false"
  }

  return queryString
}
