import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"
import { FileField, useGetList } from "react-admin"

var association = ["In elaborazione", "Analizzato", "Pagato", "Rifiutato"]
var classes = [
  " status pending",
  " status waiting",
  " status paid",
  " status rejected",
]

var firstClass = "MuiTypography-root-86 MuiTypography-body1-95"

export const statusFormatter = (v) => {
  if (v !== undefined) {
    if (v.length === 2) {
      return true
    } else if (v.length === 4) {
      return false
    }
  } else return false
}

export const statusParser = (v) => {
  if (v === true) {
    return [0, 1]
  } else {
    return [0, 1, 2, 3]
  }
}

export const StatusField = ({ source, record = {} }) => {
  let concat = firstClass + classes[record[source]]

  if (record && record.deleted) {
    concat = firstClass + " status deleted"
  }

  const testo = record.deleted ? "Cancellato" : association[record[source]]

  let spanStyle = {
    whiteSpace: "nowrap",
  }
  return (
    <span className={concat} style={spanStyle}>
      {testo}
    </span>
  )
}

StatusField.defaultProps = {
  label: "Stato",
  addLabel: true,
}

StatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export const SideField = ({ source, record = {} }) => {
  var sideAssoc = {
    F: "Anteriore",
    L: "Sinistro",
    R: "Destro",
    B: "Posteriore",
  }

  let to_be_returned = sideAssoc[record[source]]

  return <span className={firstClass}>{to_be_returned}</span>
}

SideField.defaultProps = {
  label: "Lato",
  addLabel: true,
}

SideField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export const ShowPaymentFileField = ({ record, ...rest }) => {
  console.log(record)

  console.log(rest)
  if (record.type === "invoice") {
    rest.title = "Fattura"
  } else {
    rest.title = "File"
  }

  const originType = record.originType

  return (
    originType === "Payment" && (
      <>
        <i style={{ paddingRight: 10 }} className="fa fa-file icon"></i>
        <FileField record={record} {...rest} />
      </>
    )
  )
}

ShowPaymentFileField.defaultProps = {
  label: "File",
  addLabel: true,
}

ShowPaymentFileField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export const ShowGuideFileField = ({ record, ...rest }) => {
  console.log(record)

  const originType = record.originType

  return (
    originType === "Guide" && <FileField title="" record={record} {...rest} />
  )
}

ShowGuideFileField.defaultProps = {
  label: "File",
  addLabel: true,
}

ShowGuideFileField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}

export const TotPaymentField = ({ record = {}, originField, options }) => {
  const idOffer = record.id
  const { ids, data } = useGetList(
    "OfferAddOns",
    { page: 1, perPage: 100 },
    { field: "offer_ID", order: "ASC" },
    { offer_ID: idOffer }
  )
  console.log(data)
  return (
    <Typography component="span" body1="body1">
      {(data
        ? (record[originField] ?? 0) +
          ids
            .map((id) => data[id])
            .reduce((totale, offer) => totale + offer.price, 0)
        : 0
      ).toLocaleString("it-IT", options)}
    </Typography>
  )
}
