import { ChipCustomField } from "../../Customs/chipCustomField"
import { AvatarNameField } from "../User/customFields"
import { NotificationDeleteButton } from "./components/notificationDeleteButton"
import { NotificationQuoteButton } from "./components/notificationQuoteButton"
import {
  NotificationRowStyle,
  NotificationSettings,
} from "./components/notificationSettings"
import { NotificationEditButton } from "./notificationEdit"
import { Divider, Tab, Tabs } from "@material-ui/core"
import { cloneElement, Fragment, useCallback, useEffect, useState } from "react"
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  FilterLiveSearch,
  List,
  ListContextProvider,
  ReferenceField,
  SelectField,
  SelectInput,
  TopToolbar,
  useGetList,
  useListContext,
} from "react-admin"

const tabs = [
  { id: 0, name: "Da inviare" },
  { id: 1, name: "Inviate" },
]

const useGetTotals = (filterValues) => {
  const { total: totUnsent } = useGetList(
    "Notifications",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    {
      ...filterValues,
      sent: false,
    }
  )
  const { total: totSent } = useGetList(
    "Notifications",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, sent: true }
  )
  return {
    0: totUnsent,
    1: totSent,
  }
}

const NotificationListEmbed = (props) => {
  const listContext = useListContext()
  const { ids, filterValues, setFilters, displayedFilters } = listContext
  const [sent, setSent] = useState([])
  const [unsent, setUnsent] = useState([])
  const totals = useGetTotals(filterValues)

  useEffect(() => {
    if (ids && ids !== filterValues.sent) {
      if (filterValues.sent === false) setUnsent(ids)
      else setSent(ids)
    }
  }, [ids, filterValues])

  const handleChange = useCallback(
    (event, value) => {
      setFilters &&
        setFilters({ ...filterValues, sent: value !== 0 }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )
  const selectedIds = filterValues.sent === false ? unsent : sent
  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.sent === false ? 0 : 1}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.name}
            label={
              totals[choice.id]
                ? `${choice.name} (${totals[choice.id]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        <ListContextProvider
          value={{
            ...listContext,
            ids: selectedIds,
          }}
        >
          <Datagrid {...props} rowStyle={NotificationRowStyle}>
            <ChipCustomField label="Tipo">
              <SelectField
                source="type"
                emptyText="Nessun tipo"
                choices={NotificationSettings}
              />
            </ChipCustomField>
            <ReferenceField
              source="driver_ID"
              reference="Drivers"
              link="show"
              allowEmpty
              emptyText="Tutti gli utenti"
            >
              <AvatarNameField />
            </ReferenceField>
            <NotificationQuoteButton label="Richiesta" />
            <DateField source="date" showTime={true} allowEmpty emptyText="-" />
            {({ record }) =>
              record.sent ? (
                <>
                  <BooleanField
                    source="sent"
                    valueLabelTrue="Inviata"
                    valueLabelFalse="Non inviata"
                    record={record}
                  />
                  <BooleanField
                    source="received"
                    valueLabelTrue="Ricevuta"
                    valueLabelFalse="Non ricevuta"
                    record={record}
                  />
                  <BooleanField
                    source="actionFired"
                    valueLabelTrue="Aperta"
                    valueLabelFalse="Non aperta"
                    record={record}
                  />
                </>
              ) : (
                <>
                  <NotificationEditButton />
                  <NotificationDeleteButton />
                </>
              )
            }
          </Datagrid>
        </ListContextProvider>
      </div>
    </Fragment>
  )
}
const CustomActions = ({
  resource,
  filters,
  displayedFilters,
  filterValues,
  showFilter,
}) => (
  <TopToolbar>
    {filters &&
      cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: "button",
      })}
    {/*<NotificationCreateButton
      resource={resource}
      basePath={basePath}
      record={data}
    />*/}
  </TopToolbar>
)

export const NotificationList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={
      <Filter {...props} title="Filtri">
        <FilterLiveSearch source="q" alwaysOn />
        <SelectInput
          source="received"
          choices={[
            { name: "No", id: false },
            { name: "Sì", id: true },
          ]}
          allowNull
        />
        <SelectInput
          source="actionFired"
          choices={[
            { name: "No", id: false },
            { name: "Sì", id: true },
          ]}
          allowNull
        />
      </Filter>
    }
    exporter={false}
    filterDefaultValues={{ sent: false }}
    sort={{ field: "date", order: "DESC" }}
    actions={<CustomActions />}
  >
    <NotificationListEmbed />
  </List>
)
