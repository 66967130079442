import { API_URL } from "../Helpers/variabiliGlobali"
import inMemoryJWTManager from "./jwtRefreshProvider"

const authProvider = {
  login: ({ username, password }) => {
    const request = new Request(`${API_URL}/Operators/login?include=user`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    inMemoryJWTManager.setRefreshTokenEndpoint(
      `${API_URL}/Operators/refreshAccessToken`
    )
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ id, ttl, user: { id: userId, name, surname, username } }) => {
        localStorage.setItem("token", id)
        if (userId) localStorage.setItem("userId", userId)
        if (userId) localStorage.setItem("username", username)
        if (name && surname)
          localStorage.setItem("userFullName", `${name} ${surname}`)
        return inMemoryJWTManager.setToken(id, ttl)
      })
  },
  checkAuth: () => {
    return inMemoryJWTManager.waitForTokenRefresh().then(() => {
      return inMemoryJWTManager.getToken()
        ? Promise.resolve()
        : Promise.reject()
    })
  },
  checkError: (error) => {
    const status = error.status
    if (status === 401 || status === 403) {
      inMemoryJWTManager.eraseToken()
      localStorage.removeItem("userId")
      localStorage.removeItem("userFullName")
      localStorage.removeItem("username")
      return Promise.reject()
    }
    return Promise.resolve()
  },
  logout: () => {
    const request = new Request(
      `${API_URL}/Operators/logout` +
        "?access_token=" +
        inMemoryJWTManager.getToken(),
      {
        method: "POST",
        body: null,
        headers: new Headers({ "Content-Type": "application/json" }),
      }
    )
    return fetch(request).then(() => {
      localStorage.removeItem("token")
      localStorage.removeItem("userId")
      localStorage.removeItem("userFullName")
      localStorage.removeItem("username")
      inMemoryJWTManager.eraseToken()
      return Promise.resolve("/login")
    })
  },
  getIdentity: () => {
    try {
      const id = localStorage.getItem("userOperatorId")
      const name = localStorage.getItem("userFullName")
      return Promise.resolve({
        id: id ?? null,
        fullName: name ?? null,
        avatar: null,
      })
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  },
  getPermissions: () => {
    const role = localStorage.getItem("username")
    return role ? Promise.resolve(role) : Promise.reject()
  },
}
export default authProvider
