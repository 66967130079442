import { blue, grey, red } from "@material-ui/core/colors"

/* export const EmailSettings = [
  { type: "resetPassword1", id: 4, name: "Reset password" },
  { type: "confirmSignUp", id: 3, name: "Conferma registrazione" },
  { type: "deleteAccount", id: 6, name: "Cancella account" },
  { type: "reactivateAccount", id: 8, name: "Riattiva account" },
  { type: "resetPassword2", id: 5, name: "Reset password" },
  { type: "receiptRepShop", id: 10, name: "Riepilogo Carrozziere" },
  { type: "receiptUser", id: 10, name: "Riepilogo Utente" },
  { type: "welcomeMessage", id: 9, name: "Messaggio benvenuto" },
  { type: "reminderMessage", id: 11, name: "Reminder messaggio" },
] */

export const EmailRowStyle = (record, index, defaultStyle = {}) => {
  return {
    ...defaultStyle,
    borderLeftColor: record?.error
      ? red[500]
      : record?.sent === false && record?.plannedAt !== null
      ? blue[300]
      : "transparent",
    borderLeftWidth: 5,
    borderLeftStyle: "solid",
    backgroundColor: record?.error
      ? grey[100]
      : record?.sent === false && record?.plannedAt !== null
      ? blue[300]
      : "transparent",
  }
}
