import { Menu, MenuItem, withStyles } from "@material-ui/core"
import { ExpandMore as ExpandIcon } from "@material-ui/icons"
import { Children, useState } from "react"
import { Button } from "react-admin"

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

export const ExpandMenuButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button label="Collega" onClick={handleClick}>
        <ExpandIcon />
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {Children.map(props.children, (element) => (
          <MenuItem>{element}</MenuItem>
        ))}
      </StyledMenu>
    </div>
  )
}
