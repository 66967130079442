import { Box, Typography } from "@material-ui/core"
import { diff } from "deep-object-diff"
import {
  Edit,
  email,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from "react-admin"

const OperatorEditForm = ({ resource }) => (
  <Box p="1em">
    <Typography variant="h6" gutterBottom>
      Informazioni operatore
    </Typography>
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput
          source="name"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={2} ml="0.5em" mr="0.5em">
        <TextInput
          source="surname"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={2} ml="0.5em"></Box>
    </Box>
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <TextInput
          source="email"
          fullWidth
          validate={(required(), email("Email non valida"))}
          resource={resource}
        />
      </Box>
      <Box flex={1.5} ml="0.5em" mr="0.5em"></Box>
      <Box flex={1.5} ml="0.5em"></Box>
    </Box>
  </Box>
)

const OperatorEditToolbar = ({ record, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        submitOnEnter={true}
        transform={(data) => diff(record, data)}
      />
    </Toolbar>
  )
}

export const OperatorEdit = (props) => (
  <Edit {...props}>
    <SimpleForm
      redirect="list"
      warnWhenUnsavedChanges
      toolbar={<OperatorEditToolbar />}
    >
      <OperatorEditForm />
    </SimpleForm>
  </Edit>
)
