import { dateParser } from "../../Helpers/date"
import { regexTelephone } from "../../Helpers/regex"
import { Box, Divider, Typography } from "@material-ui/core"
import { useEffect } from "react"
import {
  AutocompleteInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useGetOne,
  useNotify,
  useRedirect,
} from "react-admin"
import { useForm } from "react-final-form"

const TowTruckEditForm = ({ record, resource, ...props }) => {
  const notify = useNotify()
  const redirect = useRedirect()
  if (record && record.quote_ID) {
    redirect("show", props.basePath, record.id)
    notify("Richiesta non modificabile", "error")
  }
  return (
    <Box p="0.5em">
      <Typography variant="h6">Generali</Typography>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <DateTimeInput
            fullWidth
            parse={dateParser}
            resource={resource}
            source="date"
            validate={required()}
            record={record}
          />
        </Box>
        <Box flex={2} ml="0.5em" mr="0.5em">
          <ReferenceInput
            fullWidth
            resource={resource}
            source="driver_ID"
            reference="Drivers"
            perPage={5000}
            validate={required()}
            sort={{ field: "surname", order: "ASC" }}
            record={record}
          >
            <AutocompleteInput
              optionText={(rec) =>
                `${rec?.surname} ${rec?.name} (${rec?.email})`
              }
            />
          </ReferenceInput>
        </Box>
        <FormDataConsumer>
          {({ formData }) => (
            <DriverTelephone
              formData={formData}
              resource={resource}
              record={record}
            />
          )}
        </FormDataConsumer>
      </Box>
      <TextInput
        fullWidth
        resource={resource}
        source="location"
        validate={required()}
        multiline
        helperText="Indirizzo completo del luogo in cui si trova il veicolo"
        record={record}
      />
      <Divider style={{ marginBottom: "1em", marginTop: "1em" }} />
      <Typography variant="h6">Dettagli</Typography>

      <TextInput
        fullWidth
        resource={resource}
        source="details"
        hiddenLabel
        rows={3}
        multiline
        validate={required()}
        helperText="Tutti i dettagli forniti dell'utente in merito al sinistro o danno"
        record={record}
      />
      <SelectInput
        resource={resource}
        source="called"
        choices={[
          { name: "No", id: false },
          { name: "Sì", id: true },
        ]}
        helperText="Indicare se è già stato contattato per il recupero"
        record={record}
      />
    </Box>
  )
}

const DriverTelephone = ({ formData, resource, record }) => {
  const { data } = useGetOne("Drivers", formData.driver_ID ?? 0)
  const form = useForm()
  useEffect(() => {
    if (data) {
      form.change("phoneCode", data.countryPhonecode ?? "+39")
      form.change("telephone", data.telephone.replace(" ", ""))
    }
  }, [data, form])
  return (
    <>
      <Box flex={0.5} ml="0.5em" mr="0.5em">
        <TextInput
          fullWidth
          resource={resource}
          source="phoneCode"
          disabled={!formData.driver_ID}
          validate={required()}
          record={record}
        />
      </Box>
      <Box flex={1} ml="0.5em">
        <TextInput
          fullWidth
          resource={resource}
          source="telephone"
          disabled={!formData.driver_ID}
          validate={(required(), regex(regexTelephone))}
          record={record}
        />
      </Box>
    </>
  )
}

export const TowTruckQuoteEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TowTruckEditForm />
    </SimpleForm>
  </Edit>
)
