import { conditionQuoteActive } from "../Helpers/variabiliGlobali"
import {
  DateField,
  DateInput,
  EditButton,
  ImageField,
  Labeled,
  NumberField,
  NumberInput,
  TextField,
} from "react-admin"

export const ConditionalEditButton = ({ record, ...rest }) => {
  return conditionQuoteActive(record) ? (
    <EditButton record={record} {...rest} />
  ) : (
    <span>COMPLETATO</span>
  )
}

export const ConditionalNumberInput = ({ record, ...rest }) =>
  conditionQuoteActive(record) ? (
    <NumberInput record={record} {...rest} />
  ) : (
    <Labeled record={record} {...rest}>
      <NumberField
        options={{ style: "currency", currency: "EUR" }}
        record={record}
        {...rest}
      />
    </Labeled>
  )

export const ConditionalDateInput = ({ record, ...rest }) =>
  conditionQuoteActive(record) ? (
    <DateInput record={record} {...rest} />
  ) : (
    <Labeled record={record} {...rest}>
      <DateField
        options={{ year: "numeric", month: "long", day: "numeric" }}
        record={record}
        {...rest}
      />
    </Labeled>
  )

export const ConditionalPaymentEditButtot = ({ record }) => {
  const pagamentoBonifico = "bonifico"

  return record &&
    (record.paymentMethod === pagamentoBonifico ||
      record.paymentMethod === "" ||
      record.paymentMethod === null) ? (
    <EditButton label="Modifica" />
  ) : (
    <span>Non Modificabile</span>
  )
}

/* Field condizionali dell'utente in base se è un'azienda o meno*/

export const ConditionalUserTextField = ({ record, ...rest }) => {
  return record && record.isCompany === false ? (
    <TextField record={record} {...rest}></TextField>
  ) : null
}

ConditionalUserTextField.defaultProps = {
  addLabel: true,
}

export const ConditionalUserDateField = ({ record, ...rest }) => {
  return record && record.isCompany === false ? (
    <DateField record={record} {...rest}></DateField>
  ) : null
}

ConditionalUserDateField.defaultProps = {
  addLabel: true,
}

export const ConditionalUserImageField = ({ record, ...rest }) => {
  return record && record.isCompany === false ? (
    <ImageField record={record} {...rest}></ImageField>
  ) : null
}

ConditionalUserImageField.defaultProps = {
  addLabel: true,
}
