import { RepairShopForm } from "./components/repairShopForm"
import { Edit, SimpleForm } from "react-admin"

export const RepairShopEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <RepairShopForm />
    </SimpleForm>
  </Edit>
)
