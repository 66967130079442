import { regexTelephone, regexZIP } from "../../../Helpers/regex"
import { Box, Divider, Typography } from "@material-ui/core"
import { email, regex, required, TextInput } from "react-admin"

export const RepairShopForm = ({ resource }) => (
  <Box p="1em">
    <Typography variant="h6" gutterBottom>
      Informazioni carrozzeria
    </Typography>
    <Box display="flex">
      <Box flex={0.5} mr="0.5em">
        <TextInput source="intIdentifier" fullWidth resource={resource} />
      </Box>
      <Box flex={3} ml="0.5em" mr="0.5em">
        <TextInput
          source="name"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={1} ml="0.5em">
        <TextInput source="VAT" fullWidth resource={resource} />
      </Box>
    </Box>
    <Box display="flex">
      <Box flex={4} mr="0.5em">
        <TextInput
          source="address"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={3} ml="0.5em" mr="0.5em">
        <TextInput
          source="city"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={1} ml="0.5em" mr="0.5em">
        <TextInput
          source="ZIP"
          fullWidth
          validate={(required(), regex(regexZIP))}
          inputProps={{ maxLength: 5, minLength: 5 }}
          resource={resource}
        />
      </Box>
      <Box flex={1} ml="0.5em">
        <TextInput
          source="province"
          fullWidth
          inputProps={{ maxLength: 2, minLength: 2 }}
          validate={required()}
          resource={resource}
        />
      </Box>
    </Box>
    <Divider variant="middle" style={{ marginBottom: "1em" }} />
    <Typography variant="h6" gutterBottom>
      Contatti
    </Typography>
    <Box display="flex">
      <Box flex={3} mr="0.5em">
        <TextInput
          source="emailAddress"
          fullWidth
          validate={(required(), email("Email non valida"))}
          resource={resource}
        />
      </Box>
      <Box flex={0.5} ml="0.5em" mr="0.5em">
        <TextInput
          source="phoneCode"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={1} ml="0.5em" mr="0.5em">
        <TextInput
          source="telephone"
          fullWidth
          validate={(required(), regex(regexTelephone))}
          resource={resource}
        />
      </Box>
      <Box flex={0.5} ml="0.5em" mr="0.5em">
        <TextInput
          source="waCountryPhonecode"
          fullWidth
          validate={required()}
          resource={resource}
        />
      </Box>
      <Box flex={1} ml="0.5em">
        <TextInput
          source="waTelephone"
          fullWidth
          validate={(required(), regex(regexTelephone))}
          resource={resource}
        />
      </Box>
    </Box>
  </Box>
)
