import { Dashboard } from "./Dashboard/dashboard"
import addUploadFeature from "./Helpers/addUploadFeature"
import italianMessages from "./Layout/i18n"
import CustomLayout from "./Layout/layout"
import { MyLoginPage } from "./loginPage/myLoginPage"
import authProvider from "./Provider/authProvider"
import dataProvider from "./Provider/dataProvider"
import { AddOnCreate } from "./Resources/AddOn/addOnCreate"
import { AddOnEdit } from "./Resources/AddOn/addOnEdit"
import { AddOnList } from "./Resources/AddOn/addOnList"
import { AddOnShow } from "./Resources/AddOn/addOnShow"
import { CarCreate } from "./Resources/Car/carCreate"
import { CarEdit } from "./Resources/Car/carEdit"
import { CarList } from "./Resources/Car/carList"
import { CarShow } from "./Resources/Car/carShow"
import { DamageShow } from "./Resources/Damage/damageShow"
import { EmailList } from "./Resources/Email/emailList"
import { GlobalsEdit } from "./Resources/Global/globalEdit"
import { GlobalsList } from "./Resources/Global/globalList"
import { GlobalsShow } from "./Resources/Global/globalShow"
import { InvoiceCreate, InvoiceEdit } from "./Resources/invoice"
import { NotificationList } from "./Resources/Notification/notificationList"
import { OfferCreate } from "./Resources/Offer/offerCreate"
import { OfferEdit } from "./Resources/Offer/offerEdit"
import { OfferShow } from "./Resources/Offer/offerShow"
import { OperatorCreate } from "./Resources/Operator/operatorCreate"
import { OperatorEdit } from "./Resources/Operator/operatorEdit"
import { OperatorList } from "./Resources/Operator/operatorList"
import { OperatorShow } from "./Resources/Operator/operatorShow"
import { PaymentList } from "./Resources/Payment/paymentList"
import { PaymentShow } from "./Resources/Payment/paymentShow"
import { QuoteCreate } from "./Resources/Quote/quoteCreate"
import { QuoteEdit } from "./Resources/Quote/quoteEdit"
import { QuoteList } from "./Resources/Quote/quoteList"
import { QuoteShow } from "./Resources/Quote/quoteShow"
import { RepairShopCreate } from "./Resources/RepairShop/repairShopCreate"
import { RepairShopEdit } from "./Resources/RepairShop/repairShopEdit"
import { RepairShopList } from "./Resources/RepairShop/repairShopList"
import { RepairShopShow } from "./Resources/RepairShop/repairShopShow"
import { ShareLinkToRepairShopsCreate } from "./Resources/shareLinkToRepairShops"
import { shareOfferNotificationToUserCreate } from "./Resources/shareOfferNotificationToUser"
import { TowTruckQuoteIcon } from "./Resources/TowTruckQuote/components/towTruckQuoteIcon"
import { TowTruckQuoteCreate } from "./Resources/TowTruckQuote/towTruckQuoteCreate"
import { TowTruckQuoteEdit } from "./Resources/TowTruckQuote/towTruckQuoteEdit"
import { TowTruckQuoteList } from "./Resources/TowTruckQuote/towTruckQuoteList"
import { TowTruckQuoteShow } from "./Resources/TowTruckQuote/towTruckQuoteShow"
import { UserCreate } from "./Resources/User/userCreate"
import { UserEdit } from "./Resources/User/userEdit"
import { UserList } from "./Resources/User/userList"
import { UserShow } from "./Resources/User/userShow"
import { WhatsappConversationList } from "./Resources/WhatsappConversation/whatsappConversationList"
import { WhatsappConversationShow } from "./Resources/WhatsappConversation/whatsappConversationShow"
import { myTheme } from "./theme"
import {
  PostAdd as AddOnsIcon,
  DirectionsCar as CarIcon,
  Email as EmailIcon,
  Euro as EuroIcon,
  Build as MechIcon,
  Notifications as NotificationIcon,
  Receipt as QuoteIcon,
  Settings as SettingIcon,
  Group as UserIcon,
  WhatsApp as WhatsappIcon,
} from "@material-ui/icons"
import polyglotI18nProvider from "ra-i18n-polyglot"
import { Admin, Resource } from "react-admin"

/* import { GuideList, GuideCreate, GuideEdit } from "./Resources/guide"; */
const i18nProvider = polyglotI18nProvider(() => italianMessages, "it")

const App = () => (
  <>
    <Admin
      i18nProvider={i18nProvider}
      theme={myTheme}
      layout={CustomLayout}
      dataProvider={addUploadFeature(dataProvider)}
      authProvider={authProvider}
      loginPage={MyLoginPage}
      dashboard={Dashboard}
    >
      <Resource
        name="Quotes"
        list={QuoteList}
        show={QuoteShow}
        edit={QuoteEdit}
        create={QuoteCreate}
        icon={QuoteIcon}
      />
      <Resource
        name="TowTruckQuotes"
        list={TowTruckQuoteList}
        show={TowTruckQuoteShow}
        edit={TowTruckQuoteEdit}
        create={TowTruckQuoteCreate}
        icon={TowTruckQuoteIcon}
      />
      <Resource
        name="Cars"
        show={CarShow}
        list={CarList}
        icon={CarIcon}
        create={CarCreate}
        edit={CarEdit}
      />
      <Resource
        name="Drivers"
        list={UserList}
        show={UserShow}
        icon={UserIcon}
        create={UserCreate}
        edit={UserEdit}
      />
      <Resource
        name="RepairShops"
        list={RepairShopList}
        show={RepairShopShow}
        create={RepairShopCreate}
        edit={RepairShopEdit}
        icon={MechIcon}
      />
      <Resource
        name="AddOns"
        list={AddOnList}
        create={AddOnCreate}
        edit={AddOnEdit}
        show={AddOnShow}
        icon={AddOnsIcon}
      />
      {/*<Resource
        name="Guides"
        list={GuideList}
        create={GuideCreate}
        edit={GuideEdit}
        options={{ label: "Guide" }}
      />*/}
      <Resource
        name="Notifications"
        list={NotificationList}
        icon={NotificationIcon}
      />
      <Resource name="SibEmails" list={EmailList} icon={EmailIcon} />
      <Resource
        name="Operators"
        list={OperatorList}
        show={OperatorShow}
        create={OperatorCreate}
        edit={OperatorEdit}
        icon={UserIcon}
      />
      <Resource
        name="Globals"
        list={GlobalsList}
        edit={GlobalsEdit}
        show={GlobalsShow}
        icon={SettingIcon}
      />
      <Resource
        name="Payments"
        show={PaymentShow}
        list={PaymentList}
        icon={EuroIcon}
      />
      <Resource name="Invoices" edit={InvoiceEdit} create={InvoiceCreate} />
      <Resource
        name="Offers"
        show={OfferShow}
        create={OfferCreate}
        edit={OfferEdit}
      />
      <Resource name="Damages" show={DamageShow} />
      <Resource name="WhatsappMessages" />
      <Resource
        name="WhatsappConversations"
        list={WhatsappConversationList}
        show={WhatsappConversationShow}
        icon={WhatsappIcon}
      />
      <Resource name="DamagePictures" />
      <Resource name="CarParts" />
      <Resource name="CarTypes" />
      <Resource name="QuoteAddOns" />
      <Resource name="Feedbacks" />
      <Resource name="Histories" />
      <Resource name="OfferAddOns" />
      <Resource
        name="ShareLinkToRepairShops"
        create={ShareLinkToRepairShopsCreate}
      />
      <Resource
        name="shareOfferNotificationToUser"
        create={shareOfferNotificationToUserCreate}
      />
      <Resource name="Attachments" />
      <Resource name="PhoneCalls" />
      <Resource name="BillingAddresses" />
      <Resource name="Histories" />
    </Admin>
  </>
)

export default App
