import Avatar from "@material-ui/core/Avatar"
import { makeStyles } from "@material-ui/core/styles"
import { FunctionField } from "react-admin"

export const AvatarField = ({
  record,
  size = "25",
  className,
  source,
  style = {},
}) =>
  record ? (
    <Avatar
      src={`${record[source]}`}
      style={{
        width: parseInt(size, 10),
        height: parseInt(size, 10),
        ...style,
      }}
      className={className}
    />
  ) : (
    "-"
  )

export const FullNameField = ({ record }) =>
  record ? record.surname + " " + record.name : "-"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
  },
}))

export const AvatarNameField = ({ record, size }) => {
  const classes = useStyles()
  return record ? (
    <div className={classes.root}>
      <AvatarField
        className={classes.avatar}
        record={record}
        size={size}
        source="profilePicture"
      />
      <FullNameField record={record} />
    </div>
  ) : (
    "-"
  )
}
export const TelephoneField = ({
  record,
  inShow = false,
  countryField = "countryPhonecode",
  phoneField = "telephone",
  ...rest
}) => {
  const data = (rec) =>
    !rec
      ? null
      : !rec[phoneField]
      ? "-"
      : `(${rec[countryField] ?? "-"}) ${rec[phoneField] ?? "-"}`
  return inShow ? (
    <FunctionField
      render={(currentRecord) => data(currentRecord)}
      {...rest}
      record={record}
    />
  ) : (
    data(record)
  )
}
