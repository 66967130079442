import { PaymentAttachmentChoices } from "./components/attachmentSettings"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import {
  Cancel as IconCancel,
  Publish as IconContentAdd,
} from "@material-ui/icons"
import { useState } from "react"
import {
  Button,
  DateInput,
  FileField,
  FileInput,
  FormDataConsumer,
  FormWithRedirect,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  useCreate,
  useNotify,
} from "react-admin"
import { useForm } from "react-final-form"

const validateCreation = (values) => {
  const errors = {}

  const strCampoObbligatorio = "Campo obbligatorio"

  if (values.toAll === false && values.driver_ID === null) {
    errors.driver_ID = [strCampoObbligatorio]
  }
  return errors
}
export const AttachmentCreateButton = ({ record, originType }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("Attachments")
  //const form = useForm()
  const notify = useNotify()
  const handleClick = (e) => {
    setShowDialog(true)
    e.stopPropagation()
  }
  /*if (!record) return null;
    const {ids, data} = useGetManyReference(
        "Attachments",
        "quote_ID",
        record.id,
        {perPage: 5000, page: 1},
        {field: "id", order: "ASC"},
        {}
    );
    const dati = ids.map((id) => data[id]);
    const totallyPayed =
        dati.filter((e) => e.confirmed === true).length === dati.length;*/

  const handleCloseClick = (e) => {
    setShowDialog(false)
    e.stopPropagation()
  }
  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false)
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button onClick={handleClick} label="Carica file">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        onClick={(e) => e.stopPropagation()}
        aria-label="Carica allegato"
      >
        <DialogTitle>Carica allegato</DialogTitle>
        <Divider />
        <FormWithRedirect
          resource="Attachments"
          initialValues={{
            originId: record.id,
            originType: originType,
            type: record.invoiceUploaded === true ? "invoice" : null,
          }}
          validate={validateCreation}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const form = useForm()
            return (
              <>
                <DialogContent>
                  <SelectInput
                    label="Tipo documento"
                    source="type"
                    choices={PaymentAttachmentChoices}
                    fullWidth
                    disabled={record.invoiceUploaded === true}
                    validate={required()}
                    onChange={() => {
                      if (form.getFieldState("type").value === "other") {
                        form.change("number", null)
                        form.change("date", null)
                      }
                    }}
                  />
                  <FormDataConsumer>
                    {({ formData }) =>
                      formData.type === "invoice" && (
                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <NumberInput
                              label="Numero fattura"
                              source="number"
                              fullWidth
                              validate={required()}
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <DateInput
                              label="Data di fatturazione"
                              source="date"
                              fullWidth
                              validate={required()}
                            />
                          </Box>
                        </Box>
                      )
                    }
                  </FormDataConsumer>
                  <FileInput
                    source="files"
                    label="File"
                    multiple={false}
                    accept="application/pdf"
                    helperText={"Sono accettati solo file PDF"}
                    validate={required()}
                  >
                    <FileField source="fileUrl" title="title" />
                  </FileInput>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                    disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            )
          }}
        />
      </Dialog>
    </>
  )
}
