import docs from "./docs"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
  Typography,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { forwardRef, useEffect, useState } from "react"
import ReactMarkdown from "react-markdown"

const useStyles = makeStyles(() => ({
  appBar: {
    position: "relative",
  },
  toolbar: {
    justifyContent: "space-between",
  },
  img: {
    "max-width": "100%",
  },
}))

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function DocumentationDialog({
  resourceName,
  openState,
  closeDialog,
}) {
  const classes = useStyles()
  const [accordions, setAccordions] = useState(["# Loading"])
  const [expanded, setExpanded] = useState(0)

  useEffect(() => {
    docs(resourceName)
      .then((sections) => setAccordions(sections))
      .catch((err) => setAccordions([err.message]))
  }, [resourceName])

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={openState}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <AppBar
          className={classes.appBar}
          color="secondary"
          elevation={0}
          style={{
            padding: "8px 0px",
            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 0.05)",
          }}
        >
          <Toolbar variant="dense" className={classes.toolbar}>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Guida all&apos;uso
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <DialogContent
          style={{ backgroundColor: "#fbfbfb", padding: "20px 20px" }}
        >
          {accordions.map((acc, i) => {
            const titleContentRegexp = new RegExp(/# (.+)[\s]*([\s\S]*)/g)
            // eslint-disable-next-line no-unused-vars
            const [_, title, text] = titleContentRegexp.exec(acc)
            const isOpen = i === expanded
            return (
              <Accordion
                key={`${i}-acc-key`}
                expanded={isOpen}
                onChange={() => setExpanded(isOpen ? false : i)}
                elevation={0}
                style={{
                  borderBottom:
                    i === accordions.length - 1 ? "0px" : "1px solid #efefef",
                  boxShadow:
                    "0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)",
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  classes={{ padding: "0px 25px !important" }}
                >
                  <Typography variant="subtitle1">{title}</Typography>
                </AccordionSummary>
                <AccordionDetails
                  style={{
                    borderTop: "1px solid #efefef",
                    backgroundColor: "#f8f8f8",
                  }}
                >
                  <Typography style={{ padding: "10px 25px" }}>
                    <ReactMarkdown
                      components={{
                        h1: "h2",
                        img: (props) => (
                          <img alt="" className={classes.img} {...props} />
                        ),
                      }}
                      className={classes.content}
                    >
                      {text}
                    </ReactMarkdown>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </DialogContent>
      </Dialog>
    </div>
  )
}
