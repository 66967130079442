import { AvatarNameField } from "../User/customFields"
import { CarTypeField, CarTypeSelect } from "./components/carTypeField"
import {
  Datagrid,
  EditButton,
  Filter,
  FilterLiveSearch,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from "react-admin"

export const CarList = (props) => (
  <List
    {...props}
    filters={
      <Filter {...props} title="Filtri">
        <FilterLiveSearch source="q" alwaysOn />
        <ReferenceInput
          source="carType_ID"
          reference="CarTypes"
          allowEmpty
          alwaysOn
        >
          <CarTypeSelect />
        </ReferenceInput>
      </Filter>
    }
    exporter={false}
  >
    <Datagrid rowClick="show">
      <TextField source="plate" emptyText="-" />
      <ReferenceField
        source="driver_ID"
        reference="Drivers"
        link="show"
        allowEmpty
        emptyText="-"
      >
        <AvatarNameField />
      </ReferenceField>
      <ReferenceField
        source="carType_ID"
        reference="CarTypes"
        link={false}
        allowEmpty
        emptyText="-"
      >
        <CarTypeField />
      </ReferenceField>
      <TextField source="brand" emptyText="-" />
      <TextField source="model" emptyText="-" />
      <TextField source="version" emptyText="-" />
      <EditButton />
    </Datagrid>
  </List>
)
