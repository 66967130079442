import { Box } from "@material-ui/core"
import {
  ArrayField,
  Datagrid,
  FunctionField,
  Labeled,
  NumberField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin"

export const GlobalsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField label="Descrizione" source="description" />
      <SelectField
        label="Tipo di dato"
        source="type"
        choices={[
          { id: "array", name: "Lista" },
          { id: "number", name: "Valore numerico" },
          { id: "string", name: "Testo" },
          { id: "object", name: "Oggetto" },
        ]}
      />
      <ValueField {...props} />
    </SimpleShowLayout>
  </Show>
)
const ValuePanel = ({ record, resource, ...props }) => (
  <Box>
    {record &&
      Object.keys(record)
        .filter((e) => e !== "order")
        .map((e) => (
          <>
            <Labeled label={`resources.${resource}.fields.array_values.${e}`}>
              <TextField
                key={record.order?.toString() + "-" + e}
                source={e}
                record={record}
                resource={resource}
                emptyText="-"
                {...props}
              />
            </Labeled>
            <br />
          </>
        ))}
  </Box>
)

const ValueField = ({ record = {}, ...props }) => {
  return record.type === "array" ? (
    <ArrayField source="value" record={record} {...props}>
      <Datagrid expand={<ValuePanel />}>
        <FunctionField render={(rec) => `Sezione ${rec.order + 1}`} />
      </Datagrid>
    </ArrayField>
  ) : record.type === "number" ? (
    <Labeled label="Valore">
      <NumberField
        source="value"
        record={record}
        {...props}
        options={
          record.id.toLowerCase().includes("percentage")
            ? { style: "percent" }
            : record.id.toLowerCase().includes("amount")
            ? { style: "currency", currency: "EUR" }
            : {}
        }
      />
    </Labeled>
  ) : (
    <Labeled label="Valore">
      <TextField source="value" record={record} {...props} />
    </Labeled>
  )
}
