import {
  ImageField,
  ReferenceManyField,
  Show,
  SingleFieldList,
} from "react-admin"

const DamageTitle = ({ record }) => {
  return <span>{record ? `Danno` : ""}</span>
}

export const DamageShow = (props) => (
  <Show {...props} title={<DamageTitle />}>
    <ReferenceManyField
      target="damage_ID"
      reference="DamagePictures"
      addLabel={false}
    >
      <SingleFieldList>
        <ImageField source="pictureUrl" />
      </SingleFieldList>
    </ReferenceManyField>
  </Show>
)
