import { ConditionalDateInput } from "../../Customs/customConditionalFields"
import { Edit, SimpleForm, TextInput } from "react-admin"

const QuoteTitle = ({ record }) => {
  return (
    <span>
      {record.quoteIdentifier
        ? `Preventivo numero: ${record.quoteIdentifier}`
        : "Nessun identificativo"}
    </span>
  )
}

export const QuoteEdit = (props) => (
  <Edit {...props} title={<QuoteTitle />}>
    <SimpleForm>
      <TextInput disabled label="Identificativo" source="quoteIdentifier" />
      <ConditionalDateInput label="Data di scadenza" source="expDate" />
      <TextInput multiline label="Dettagli utente" source="userDetails" />
      <TextInput multiline label="Posizione" source="actualAddress" />
    </SimpleForm>
  </Edit>
)
