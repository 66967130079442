import { AttachmentCreateButton } from "../Attachment/attachmentCreate"
import { PaymentCreateButton } from "./paymentCreate"
import { PaymentEditButton } from "./paymentEdit"
import {
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  ReferenceManyField,
  TextField,
} from "react-admin"

export const PaymentGrid = ({ record }) => {
  return (
    <>
      <PaymentCreateButton record={record} />
      <ReferenceManyField
        target="quote_ID"
        reference="Payments"
        addLabel={false}
        record={record}
      >
        <Datagrid
          rowClick={(id, basePath, record) =>
            record.confirmed === true ? "show" : null
          }
        >
          <BooleanField source="confirmed" label="Conferma" />
          <TextField label="Metodo di pagamento" source="paymentMethod" />
          <TextField source="paymentType" label="Tipo di pagamento" />
          <DateField
            source="date"
            label="Data ricevuta pagamento"
            emptyText="-"
            showTime={true}
          />

          <NumberField
            source="paidTotal"
            label="Totale"
            options={{ style: "currency", currency: "EUR" }}
            allowEmpty
          />
          <PaymentEditButton />
          <AttachmentCreateButton />
        </Datagrid>
      </ReferenceManyField>
    </>
  )
}
