import { ChipCustomField } from "../../Customs/chipCustomField"
import { AvatarNameField } from "../User/customFields"
import { EmailQuoteButton } from "./components/emailQuoteButton"
import { EmailRowStyle } from "./components/emailSettings"
import { Divider, Tab, Tabs } from "@material-ui/core"
import { Fragment, useCallback, useEffect, useState } from "react"
import {
  BooleanField,
  Datagrid,
  DateField,
  DeleteButton,
  Filter,
  FilterLiveSearch,
  List,
  ListContextProvider,
  ReferenceField,
  SelectField,
  SelectInput,
  useGetList,
  useGetOne,
  useListContext,
} from "react-admin"

const tabs = [
  { id: 0, name: "Da inviare" },
  { id: 1, name: "Inviate" },
]

const useGetTotals = (filterValues) => {
  const { total: totUnsent } = useGetList(
    "SibEmails",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    {
      ...filterValues,
      sent: false,
    }
  )
  const { total: totSent } = useGetList(
    "SibEmails",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, sent: true }
  )
  return {
    0: totUnsent,
    1: totSent,
  }
}

const EmailListEmbed = (props) => {
  const listContext = useListContext()
  const { ids, filterValues, setFilters, displayedFilters } = listContext
  const [sent, setSent] = useState([])
  const [unsent, setUnsent] = useState([])
  const totals = useGetTotals(filterValues)

  useEffect(() => {
    if (ids && ids !== filterValues.sent) {
      if (filterValues.sent === false) setUnsent(ids)
      else setSent(ids)
    }
  }, [ids, filterValues])

  const handleChange = useCallback(
    (event, value) => {
      setFilters &&
        setFilters({ ...filterValues, sent: value !== 0 }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )
  const selectedIds = filterValues.sent === false ? unsent : sent
  const { data, loading, error } = useGetOne("Globals", "emailTypes")
  const [emailSettings, setEmailSettings] = useState([])

  useEffect(() => {
    if (!loading && !error) setEmailSettings(data?.value || [])
  }, [data, loading, error])

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.sent === false ? 0 : 1}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.name}
            label={
              totals[choice.id]
                ? `${choice.name} (${totals[choice.id]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        <ListContextProvider
          value={{
            ...listContext,
            ids: selectedIds,
          }}
        >
          <Datagrid {...props} rowStyle={EmailRowStyle}>
            <ChipCustomField label="Tipo">
              <SelectField
                source="template"
                emptyText="Nessun tipo"
                choices={emailSettings}
              />
            </ChipCustomField>
            <ReferenceField
              source="driver_ID"
              reference="Drivers"
              link="show"
              allowEmpty
              emptyText="-"
            >
              <AvatarNameField />
            </ReferenceField>
            <EmailQuoteButton label="Richiesta" />
            <DateField source="date" showTime={true} allowEmpty emptyText="-" />
            <BooleanField
              source="sent"
              valueLabelTrue="Inviata"
              valueLabelFalse="Non inviata"
            />
            <BooleanField
              source="received"
              valueLabelTrue="Ricevuta"
              valueLabelFalse="Non ricevuta"
            />
            <BooleanField
              source="open"
              valueLabelTrue="Aperta"
              valueLabelFalse="Non aperta"
            />
            {({ record }) =>
              !record.sent && <DeleteButton record={record} undoable={false} />
            }
          </Datagrid>
        </ListContextProvider>
      </div>
    </Fragment>
  )
}
export const EmailList = (props) => (
  <List
    {...props}
    bulkActionButtons={false}
    filters={
      <Filter {...props} title="Filtri">
        <FilterLiveSearch source="q" alwaysOn />
        <SelectInput
          source="received"
          choices={[
            { name: "No", id: false },
            { name: "Sì", id: true },
          ]}
          allowNull
        />
        <SelectInput
          source="open"
          choices={[
            { name: "No", id: false },
            { name: "Sì", id: true },
          ]}
          allowNull
        />
      </Filter>
    }
    exporter={false}
    filterDefaultValues={{ sent: false }}
    sort={{ field: "date", order: "DESC" }}
  >
    <EmailListEmbed />
  </List>
)
