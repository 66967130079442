import {
  BooleanField,
  ChipField,
  Datagrid,
  NumberField,
  ReferenceField,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin"

export const OfferShow = (props) => (
  <Show {...props} title="Offerta">
    <TabbedShowLayout>
      <Tab label="Dettagli">
        <NumberField
          label="Importo Totale"
          source="total"
          options={{ style: "currency", currency: "EUR" }}
          allowEmpty
        />
        <NumberField
          label="Importo Scontato"
          source="discountTotal"
          options={{ style: "currency", currency: "EUR" }}
          allowEmpty
        />
        <BooleanField
          source="chosen"
          valueLabelTrue="Offerta scelta"
          valueLabelFalse="Offerta non selezionata"
        />
        <TextField label="Dettagli" source="details" />
        <ReferenceManyField
          label="Servizi Aggiuntivi Offerti"
          reference="OfferAddOns"
          target="offer_ID"
        >
          <Datagrid>
            <ReferenceField
              link={false}
              label="Nome"
              source="addOn_ID"
              reference="AddOns"
            >
              <ChipField link={false} source="name" />
            </ReferenceField>
            <TextField label="Ulteriori dettagli" source="furtherDetails" />
            <TextField
              label="Prezzo"
              options={{ style: "currency", currency: "EUR" }}
              source="price"
            />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
      <Tab label="Carrozziera" path="repair-shop">
        <ReferenceField
          label="Nome"
          source="repShop_ID"
          reference="RepairShops"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          label="Città"
          source="repShop_ID"
          reference="RepairShops"
          link={false}
        >
          <TextField source="city" />
        </ReferenceField>
        <ReferenceField
          label="Indirizzo"
          source="repShop_ID"
          reference="RepairShops"
          link={false}
        >
          <TextField source="address" />
        </ReferenceField>
        <ReferenceField
          label="Provincia"
          source="repShop_ID"
          reference="RepairShops"
          link={false}
        >
          <TextField source="province" />
        </ReferenceField>
        <ReferenceField
          label="E-Mail"
          source="repShop_ID"
          reference="RepairShops"
          link={false}
        >
          <TextField source="emailAddress" />
        </ReferenceField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)
