import { googleMapsKey } from "../variabiliGlobali"
import "./Map.css"
import { withStyles } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import RoomIcon from "@material-ui/icons/Room"
import GoogleMapReact from "google-map-react"

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)

const LocationPin = ({ text = "" }) => (
  <LightTooltip title={text} interactive arrow>
    <RoomIcon color={"primary"} fontSize={"large"} />
  </LightTooltip>
)

const Map = ({
  locationField = "actualLocation",
  locationString = "address",
  record,
  zoomLevel = 14,
}) => {
  const location = record[locationField]
  return location ? (
    <div className="map">
      <div className="google-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: googleMapsKey }}
          defaultCenter={location}
          defaultZoom={zoomLevel}
          draggable={false}
        >
          <LocationPin
            lat={location.lat}
            lng={location.lng}
            text={record[locationString]}
          />
        </GoogleMapReact>
      </div>
    </div>
  ) : null
}

export default Map
