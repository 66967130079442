import { NotificationSettings } from "./components/notificationSettings"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import {
  Cancel as IconCancel,
  Edit as IconContentEdit,
} from "@material-ui/icons"
import { Alert } from "@material-ui/lab"
import { useState } from "react"
import {
  AutocompleteInput,
  Button,
  DateTimeInput,
  FormDataConsumer,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useNotify,
  useUpdate,
} from "react-admin"

const validateEdit = (values) => {
  const errors = {}

  const strCampoObbligatorio = "Campo obbligatorio"

  if (values.toAll === false && values.driver_ID === null) {
    errors.driver_ID = [strCampoObbligatorio]
  }
  return errors
}
export const NotificationEditButton = ({ onChange, record }) => {
  console.log(record)
  const [showDialog, setShowDialog] = useState(false)
  const [edit, { loading }] = useUpdate("Notifications")
  //const form = useForm()
  const notify = useNotify()
  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }
  const handleSubmit = async (values) => {
    edit(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false)
          // Update the comment form to target the newly editd post
          // Updating the ReferenceInput value will force it to reload the available posts
          onChange()
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    record && (
      <>
        <Button
          onClick={handleClick}
          label="ra.action.edit"
          disabled={record && record.sent === true}
        >
          <IconContentEdit />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          maxWidth={"md"}
          onClose={handleCloseClick}
          aria-label="Modifica notifica"
        >
          <DialogTitle>Invia notifica</DialogTitle>
          <Divider />
          <FormWithRedirect
            resource="Notifications"
            initialValues={{
              ...record,
              toAll: record.driver_ID === null,
              schedule: true,
            }}
            validate={validateEdit}
            save={handleSubmit}
            render={({ handleSubmitWithRedirect, pristine, saving }) => (
              <>
                <DialogContent>
                  <FormDataConsumer>
                    {({ formData }) => {
                      //const form = useForm()

                      return (
                        <>
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <SelectInput
                                source="toAll"
                                record={record}
                                label="Destinatari"
                                validate={required()}
                                fullWidth
                                disabled
                                onChange={() => {
                                  console.log(
                                    formData.getFieldState("toAll").value
                                  )
                                  if (
                                    formData.getFieldState("toAll").value ===
                                    true
                                  )
                                    formData.change("driver_ID", null)
                                }}
                                choices={[
                                  {
                                    id: false,
                                    name: "Singolo utente",
                                  },
                                  {
                                    id: true,
                                    name: "Tutti gli utenti",
                                  },
                                ]}
                              />
                            </Box>
                            <Box flex={2} ml="0.5em">
                              <ReferenceInput
                                fullWidth
                                source="driver_ID"
                                record={record}
                                reference="Drivers"
                                label="Utente"
                                perPage={5000}
                                allowNull
                                disabled={formData.toAll === true}
                                sort={{
                                  field: "surname",
                                  order: "ASC",
                                }}
                              >
                                <AutocompleteInput
                                  optionText={(rec) =>
                                    `${rec.surname} ${rec.name} (${rec.email})`
                                  }
                                  allowNull
                                  resettable
                                />
                              </ReferenceInput>
                            </Box>
                          </Box>
                          {formData.driver_ID !== null && (
                            <ReferenceInput
                              fullWidth
                              source="quote_ID"
                              reference="Quotes"
                              label="Richiesta di preventivo"
                              perPage={5000}
                              allowNull
                              disabled={formData.toAll === true}
                              filter={{
                                driver_ID: formData.driver_ID,
                              }}
                              sort={{
                                field: "requestDate",
                                order: "DESC",
                              }}
                            >
                              <SelectInput
                                optionText={(rec) =>
                                  `Richiesta #${rec.quoteIdentifier} del ${rec.requestDate}`
                                }
                                allowNull
                                resettable
                              />
                            </ReferenceInput>
                          )}
                          <Box display="flex">
                            <Box flex={1} mr="0.5em">
                              <SelectInput
                                source="type"
                                validate={required()}
                                label="Tipo"
                                fullWidth
                                record={record}
                                choices={NotificationSettings}
                                disabled
                              />
                            </Box>
                            <Box flex={1} ml="0.5em" mr="0.5em">
                              <SelectInput
                                source="schedule"
                                record={record}
                                label="Quando inviare la notifica"
                                validate={required()}
                                disabled
                                fullWidth
                                onChange={() => {
                                  if (
                                    formData.getFieldState("schedule").value ===
                                    false
                                  )
                                    formData.change("plannedAt", new Date())
                                }}
                                choices={[
                                  { id: false, name: "Ora" },
                                  {
                                    id: true,
                                    name: "Orario Programmato",
                                  },
                                ]}
                              />
                            </Box>
                            <Box flex={1} ml="0.5em">
                              <DateTimeInput
                                source="plannedAt"
                                record={record}
                                label="Data di invio"
                                fullWidth
                                disabled={formData.schedule === false}
                              />
                            </Box>
                          </Box>

                          <TextInput
                            source="title"
                            label="Titolo"
                            record={record}
                            fullWidth
                            validate={required()}
                          />
                          <TextInput
                            source="body"
                            record={record}
                            label="Descrizione"
                            validate={required()}
                            fullWidth
                            multiline
                            rows={4}
                          />
                          {formData.toAll === true && (
                            <Alert severity="info">
                              La notifica verrà inviata a tutti gli utenti
                            </Alert>
                          )}
                        </>
                      )
                    }}
                  </FormDataConsumer>
                </DialogContent>
                <Divider />
                <DialogActions>
                  <Button
                    label="ra.action.cancel"
                    onClick={handleCloseClick}
                    disabled={loading}
                  >
                    <IconCancel />
                  </Button>
                  <SaveButton
                    handleSubmitWithRedirect={handleSubmitWithRedirect}
                    pristine={pristine}
                    saving={saving}
                    disabled={loading}
                  />
                </DialogActions>
              </>
            )}
          />
        </Dialog>
      </>
    )
  )
}
