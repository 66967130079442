import { ExpandMenuButton } from "../../Helpers/expandButton"
import Map from "../../Helpers/Map/Map"
import { CreateRelatedQuoteButton } from "../Quote/components/createRelatedQuote"
import { AvatarNameField, TelephoneField } from "../User/customFields"
import { FlagCalledButton } from "./components/flagCalledButton"
import { Box, Divider } from "@material-ui/core"
import { Call as CallIcon } from "@material-ui/icons"
import { Alert } from "@material-ui/lab"
import {
  DateField,
  EditButton,
  FunctionField,
  Labeled,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
} from "react-admin"

const CustomActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ExpandMenuButton>
      {data && <CreateRelatedQuoteButton record={data} resource={resource} />}
    </ExpandMenuButton>
    <EditButton
      disabled={data && data.quote_ID}
      basePath={basePath}
      record={data}
    />
  </TopToolbar>
)
export const TowTruckQuoteShow = (props) => (
  <Show {...props} actions={<CustomActions />}>
    <SimpleShowLayout>
      <DataTab />
    </SimpleShowLayout>
  </Show>
)
const DataTab = ({ record, ...rest }) => (
  <Box>
    <Alert
      icon={<CallIcon />}
      severity={record && record.called ? "success" : "error"}
      style={{ marginBottom: "1em" }}
      action={
        record &&
        !record.called && <FlagCalledButton record={record} {...rest} />
      }
    >
      L&apos;utente {record && record.called ? "è già" : "non è ancora"} stato
      contattato
    </Alert>
    <Box display="flex">
      <Box flex={2} mr="0.5em">
        <Box>
          <Box display="flex">
            <Box flex={1} mr="0.5em">
              <Labeled label="Data">
                <DateField source="date" record={record} showTime {...rest} />
              </Labeled>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box flex={3} mr="0.5em" ml="0.5em">
              <Labeled label="Utente">
                <ReferenceField
                  source="driver_ID"
                  reference="Drivers"
                  link="show"
                  record={record}
                  {...rest}
                >
                  <AvatarNameField />
                </ReferenceField>
              </Labeled>
            </Box>
            <Divider orientation="vertical" flexItem />
            <Box flex={1} ml="0.5em">
              <Labeled label="Telefono">
                <TelephoneField
                  countryField="phoneCode"
                  phoneField="telephone"
                  record={record}
                  {...rest}
                  emptyText="-"
                />
              </Labeled>
            </Box>
          </Box>
        </Box>
        {record && record.quote_ID && (
          <>
            <Divider />
            <Labeled label="Richiesta di preventivo collegata">
              <ReferenceField
                source="quote_ID"
                reference="Quotes"
                link="show"
                record={record}
                {...rest}
              >
                <FunctionField
                  render={(currRec) =>
                    `Richiesta #${currRec.quoteIdentifier ?? "-"}`
                  }
                  InputProps={{ textAlign: "center" }}
                />
              </ReferenceField>
            </Labeled>
          </>
        )}
        <Divider />
        <Box>
          <Labeled label="Dettagli">
            <TextField
              source="details"
              record={record}
              {...rest}
              emptyText="-"
            />
          </Labeled>
        </Box>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Box flex={1} ml="0.5em">
        <Box>
          <Labeled label="Indirizzo">
            <TextField
              source="location"
              record={record}
              {...rest}
              emptyText="-"
            />
          </Labeled>
        </Box>
        <Map record={record} locationField="actualLocation" />
      </Box>
    </Box>
  </Box>
)
