import { NotificationSettings } from "./components/notificationSettings"
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core"
import { Cancel as IconCancel, Add as IconContentAdd } from "@material-ui/icons"
import { Alert } from "@material-ui/lab"
import { useState } from "react"
import {
  AutocompleteInput,
  Button,
  DateTimeInput,
  FormDataConsumer,
  FormWithRedirect,
  ReferenceInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  useCreate,
  useNotify,
} from "react-admin"
import { useForm } from "react-final-form"

const validateCreation = (values) => {
  const errors = {}

  const strCampoObbligatorio = "Campo obbligatorio"

  if (values.toAll === false && values.driver_ID === null) {
    errors.driver_ID = [strCampoObbligatorio]
  }
  return errors
}
export const NotificationCreateButton = ({
  label,
  onChange,
  disabled,
  driver_ID,
  quote_ID,
  type,
  icon,
}) => {
  const [showDialog, setShowDialog] = useState(false)
  const [create, { loading }] = useCreate("Notifications")
  //const form = useForm()
  const notify = useNotify()
  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }
  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: () => {
          setShowDialog(false)
          // Update the comment form to target the newly created post
          // Updating the ReferenceInput value will force it to reload the available posts
          onChange()
        },
        onFailure: ({ error }) => {
          notify(error.message, "error")
        },
      }
    )
  }

  return (
    <>
      <Button
        onClick={handleClick}
        label={label ?? "ra.action.create"}
        disabled={disabled}
      >
        {icon ?? <IconContentAdd />}
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        maxWidth={"md"}
        onClose={handleCloseClick}
        aria-label="Invia notifiche"
      >
        <DialogTitle>Invia notifica</DialogTitle>
        <Divider />
        <FormWithRedirect
          resource="Notifications"
          initialValues={{
            schedule: false,
            type: type ?? "generalInfo_notification",
            plannedAt: new Date(),
            toAll: false,
            driver_ID: driver_ID,
            quote_ID: quote_ID,
          }}
          validate={validateCreation}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <FormDataConsumer>
                  {({ formData }) => {
                    // eslint-disable-next-line
                    const form = useForm()
                    return (
                      <>
                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <SelectInput
                              source="toAll"
                              label="Destinatari"
                              validate={required()}
                              fullWidth
                              onChange={() => {
                                if (form.getFieldState("toAll").value === true)
                                  form.change("driver_ID", null)
                              }}
                              choices={[
                                {
                                  id: false,
                                  name: "Singolo utente",
                                },
                                {
                                  id: true,
                                  name: "Tutti gli utenti",
                                },
                              ]}
                            />
                          </Box>
                          <Box flex={2} ml="0.5em">
                            <ReferenceInput
                              fullWidth
                              source="driver_ID"
                              reference="Drivers"
                              label="Utente"
                              perPage={5000}
                              allowNull
                              disabled={formData.toAll === true}
                              sort={{
                                field: "surname",
                                order: "ASC",
                              }}
                            >
                              <AutocompleteInput
                                optionText={(rec) =>
                                  `${rec?.surname} ${rec?.name} (${rec?.email})`
                                }
                                allowNull
                                resettable
                              />
                            </ReferenceInput>
                          </Box>
                        </Box>
                        {form.driver_ID !== null && (
                          <ReferenceInput
                            fullWidth
                            source="quote_ID"
                            reference="Quotes"
                            label="Richiesta di preventivo"
                            perPage={5000}
                            allowNull
                            disabled={form.toAll === true}
                            filter={{
                              driver_ID: form.driver_ID,
                            }}
                            sort={{
                              field: "requestDate",
                              order: "DESC",
                            }}
                          >
                            <SelectInput
                              optionText={(rec) =>
                                `Richiesta #${rec.quoteIdentifier} del ${rec.requestDate}`
                              }
                              allowNull
                              resettable
                            />
                          </ReferenceInput>
                        )}
                        <Box display="flex">
                          <Box flex={1} mr="0.5em">
                            <SelectInput
                              source="type"
                              validate={required()}
                              label="Tipo"
                              fullWidth
                              choices={NotificationSettings}
                              disabled
                            />
                          </Box>
                          <Box flex={1} ml="0.5em" mr="0.5em">
                            <SelectInput
                              source="schedule"
                              label="Quando inviare la notifica"
                              validate={required()}
                              fullWidth
                              onChange={() => {
                                if (
                                  form.getFieldState("schedule").value === false
                                )
                                  form.change("plannedAt", new Date())
                              }}
                              choices={[
                                { id: false, name: "Ora" },
                                {
                                  id: true,
                                  name: "Orario Programmato",
                                },
                              ]}
                            />
                          </Box>
                          <Box flex={1} ml="0.5em">
                            <DateTimeInput
                              source="plannedAt"
                              label="Data di invio"
                              fullWidth
                              disabled={form.schedule === false}
                            />
                          </Box>
                        </Box>
                        <TextInput
                          source="title"
                          label="Titolo"
                          fullWidth
                          validate={required()}
                        />
                        <TextInput
                          source="body"
                          label="Descrizione"
                          validate={required()}
                          fullWidth
                          multiline
                          rows={4}
                        />
                        {form.toAll === true && (
                          <Alert severity="info">
                            La notifica verrà inviata a tutti gli utenti
                          </Alert>
                        )}
                      </>
                    )
                  }}
                </FormDataConsumer>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}
