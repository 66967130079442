import { ChipCustomField } from "../../Customs/chipCustomField"
import { AvatarNameField } from "../User/customFields"
import { NotificationDeleteButton } from "./components/notificationDeleteButton"
import {
  NotificationRowStyle,
  NotificationSettings,
} from "./components/notificationSettings"
import { NotificationCreateButton } from "./notificationCreate"
import { NotificationEditButton } from "./notificationEdit"
import {
  BooleanField,
  Datagrid,
  DateField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  SelectField,
} from "react-admin"

export const NotificationGrid = ({ record }) => (
  <>
    <NotificationCreateButton
      driver_ID={record.driver_ID}
      quote_ID={record.id}
      label="Crea Notifica"
    />
    <ReferenceManyField
      target="quote_ID"
      reference="Notifications"
      sort={{ field: "date", order: "DESC" }}
      addLabel={false}
      pagination={<Pagination />}
      perPage={10}
      record={record}
    >
      <Datagrid rowStyle={NotificationRowStyle}>
        <ChipCustomField label="Tipo">
          <SelectField
            source="type"
            emptyText="Nessun tipo"
            choices={NotificationSettings}
          />
        </ChipCustomField>
        <ReferenceField
          source="driver_ID"
          reference="Drivers"
          link="show"
          allowEmpty
          emptyText="Tutti gli utenti"
        >
          <AvatarNameField />
        </ReferenceField>

        <DateField
          source={record.plannedAt ? "plannedAt" : "date"}
          showTime={true}
          allowEmpty
          emptyText="-"
        />
        <BooleanField
          source="sent"
          valueLabelTrue="Inviata"
          valueLabelFalse="Non inviata"
        />
        <BooleanField
          source="received"
          valueLabelTrue="Ricevuta"
          valueLabelFalse="Non ricevuta"
        />
        <BooleanField
          source="actionFired"
          valueLabelTrue="Aperta"
          valueLabelFalse="Non aperta"
        />
        <NotificationEditButton />
        <NotificationDeleteButton />
      </Datagrid>
    </ReferenceManyField>
  </>
)
