import { ChipCustomField } from "../../Customs/chipCustomField"
import { AttachmentCreateButton } from "../Attachment/attachmentCreate"
import { PaymentMethodTypes } from "./components/paymentSettings"
import { PaymentEditButton } from "./paymentEdit"
import { Box, Tab, Tabs } from "@material-ui/core"
import { useEffect, useState } from "react"
import {
  BooleanField,
  Datagrid,
  DateField,
  Filter,
  List,
  NumberField,
  SelectField,
  TextField,
  useListContext,
} from "react-admin"

const PaymentsActions = () => {
  const listContext = useListContext()
  const [tabToShow, setTabToShow] = useState(0)
  const tabs = [
    { id: 0, name: "Ricevuti" },
    { id: 1, name: "In attesa" },
  ]

  useEffect(() => {
    listContext?.setFilters({ confirmed: !tabToShow })
  }, [tabToShow, listContext])

  return (
    <Box width="100%">
      <Tabs
        variant="fullWidth"
        centered
        value={tabToShow}
        indicatorColor="primary"
        onChange={(e, value) => setTabToShow(value)}
      >
        {tabs.map((choice) => (
          <Tab key={choice.id} label={choice.name} value={choice.id} />
        ))}
      </Tabs>
    </Box>
  )
}
export const PaymentList = (props) => (
  <List
    {...props}
    title="Pagamenti"
    exporter={false}
    bulkActionButtons={false}
    sort={{ field: "date", order: "DESC" }}
    filters={
      <Filter {...props} title="Filtri">
        <SelectField
          choices={PaymentMethodTypes}
          source="paymentMethod"
          alwaysOn
        />
      </Filter>
    }
    actions={<PaymentsActions />}
  >
    <Datagrid
      optimized
      rowClick={(id, basePath, record) =>
        record.confirmed === true ? "show" : null
      }
    >
      <BooleanField source="confirmed" label="Conferma" />
      <ChipCustomField>
        <TextField label="Metodo di pagamento" source="paymentMethod" />
      </ChipCustomField>
      <TextField source="paymentType" label="Tipo di pagamento" />
      <DateField
        source="date"
        label="Data ricevuta pagamento"
        emptyText="-"
        showTime={true}
      />

      <NumberField
        source="paidTotal"
        label="Totale"
        options={{ style: "currency", currency: "EUR" }}
        allowEmpty
      />
      <PaymentEditButton />
      <AttachmentCreateButton />
    </Datagrid>
  </List>
)
