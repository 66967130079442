import { CreateRelatedQuoteButton } from "../Quote/components/createRelatedQuote"
import { AvatarNameField, TelephoneField } from "../User/customFields"
import { FlagCalledButton } from "./components/flagCalledButton"
import { Divider, Tab, Tabs } from "@material-ui/core"
import { OpenInNew as OpenInNewIcon } from "@material-ui/icons"
import { Fragment, useCallback, useEffect, useState } from "react"
import {
  Button,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FilterLiveSearch,
  FunctionField,
  Link,
  List,
  ListContextProvider,
  ReferenceField,
  useGetList,
  useListContext,
} from "react-admin"

const TowTruckQuoteFilter = (props) => (
  <Filter {...props} title="Filtri">
    <FilterLiveSearch source="q" alwaysOn />
  </Filter>
)

const CustomAction = ({ record, ...props }) => {
  return !record ? null : record.quote_ID ? (
    <ReferenceField
      source="quote_ID"
      reference="Quotes"
      link="show"
      record={record}
      {...props}
    >
      <FunctionField
        render={(currRec) => (
          <Button
            component={Link}
            label={`Richiesta #${currRec.quoteIdentifier ?? "-"}`}
            to={{
              pathname: `/Quotes/${currRec.id ?? "-"}/show`,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <OpenInNewIcon />
          </Button>
        )}
        InputProps={{ textAlign: "center" }}
      />
    </ReferenceField>
  ) : (
    <CreateRelatedQuoteButton record={record} {...props} />
  )
}

const EditButtonCondition = ({ record, ...props }) => (
  <EditButton record={record} {...props} disabled={record && record.quote_ID} />
)

const tabs = [
  { id: 0, name: "Da Chiamare" },
  { id: 1, name: "Chiamati" },
]

const useGetTotals = (filterValues) => {
  const { total: totToCall } = useGetList(
    "TowTruckQuotes",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, called: false }
  )
  const { total: totCalled } = useGetList(
    "TowTruckQuotes",
    { perPage: 1, page: 1 },
    { field: "id", order: "ASC" },
    { ...filterValues, called: true }
  )
  return {
    0: totToCall,
    1: totCalled,
  }
}

const TowTruckQuoteListEmbed = (props) => {
  const listContext = useListContext()
  const { ids, filterValues, setFilters, displayedFilters } = listContext
  const [toCall, setToCall] = useState([])
  const [called, setCalled] = useState([])
  const totals = useGetTotals(filterValues)

  useEffect(() => {
    if (ids && ids !== filterValues.called) {
      if (filterValues.called === false) setToCall(ids)
      else setCalled(ids)
    }
  }, [ids, filterValues])

  const handleChange = useCallback(
    (event, value) => {
      setFilters &&
        setFilters({ ...filterValues, called: value !== 0 }, displayedFilters)
    },
    [displayedFilters, filterValues, setFilters]
  )
  const selectedIds = filterValues.called === false ? toCall : called
  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues.called === true ? 1 : 0}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map((choice) => (
          <Tab
            key={choice.name}
            label={
              totals[choice.id]
                ? `${choice.name} (${totals[choice.id]})`
                : choice.name
            }
            value={choice.id}
          />
        ))}
      </Tabs>
      <Divider />
      <div>
        <ListContextProvider
          value={{
            ...listContext,
            ids: selectedIds,
          }}
        >
          <Datagrid rowClick="show" {...props}>
            {filterValues.called == false && <FlagCalledButton inList={true} />}
            <DateField label="Data" source="date" showTime />
            <ReferenceField
              label="Utente"
              source="driver_ID"
              reference="Drivers"
              link="show"
            >
              <AvatarNameField label="Utente" />
            </ReferenceField>
            <TelephoneField label="Telefono" countryField="phoneCode" />
            <CustomAction label="Preventivo" />
            <EditButtonCondition label="Modifica" />
          </Datagrid>
        </ListContextProvider>
      </div>
    </Fragment>
  )
}
export const TowTruckQuoteList = (props) => (
  <List
    filters={<TowTruckQuoteFilter />}
    {...props}
    sort={{ field: "date", order: "DESC" }}
    exporter={false}
    filter={{}}
    filterDefaultValues={{ called: false }}
  >
    <TowTruckQuoteListEmbed />
  </List>
)
