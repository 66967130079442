import { dateParser } from "../../Helpers/date"
import { regexZIP } from "../../Helpers/regex"
import CarQuickCreateButton from "../Car/addQuickCar"
import { Box, Divider, makeStyles, Typography } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import {
  ArrayInput,
  AutocompleteInput,
  Create,
  DateInput,
  DateTimeInput,
  FormDataConsumer,
  FormTab,
  ImageField,
  ImageInput,
  ReferenceArrayInput,
  ReferenceInput,
  regex,
  required,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useGetOne,
  useNotify,
  useRedirect,
} from "react-admin"
import { useForm } from "react-final-form"

const validateQuoteCreation = (values) => {
  const errors = {}
  if (!values.damages || values.damages.length <= 0)
    errors.damages = ["Aggiungi almeno un danno"]
  return errors
}

const QuoteCreateForm = ({ record, resource }) => {
  const form = useForm()
  console.log(record)
  return (
    <Box p="0.5em">
      <Typography variant="h6">Generali</Typography>
      <Box display="flex">
        <Box flex={0.5} mr="0.5em">
          <DateTimeInput
            fullWidth
            parse={dateParser}
            resource={resource}
            label="Data della richiesta"
            source="requestDate"
            validate={required()}
            record={record}
          />
        </Box>
        <Box flex={1} ml="0.5em" mr="0.5em">
          <ReferenceInput
            fullWidth
            resource={resource}
            label="Utente"
            source="driver_ID"
            reference="Drivers"
            perPage={5000}
            disabled={record.driver_ID}
            validate={required()}
            onChange={() => {
              form.change("car_ID", null)
            }}
            sort={{ field: "surname", order: "ASC" }}
            record={record}
            filterToQuery={(searchText) => {
              searchText
            }}
          >
            <AutocompleteInput
              optionText={(rec) =>
                `${rec?.surname} ${rec?.name} (${rec?.email})`
              }
              resettable
            />
          </ReferenceInput>
        </Box>

        <FormDataConsumer>
          {({ formData }) => (
            <Box flex={1.5} ml="0.5em">
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <ReferenceInput
                    fullWidth
                    disabled={record ? record.car_ID : !formData.driver_ID}
                    resource={resource}
                    label="Veicolo"
                    source="car_ID"
                    reference="Cars"
                    perPage={5000}
                    validate={required()}
                    sort={{ field: "plate", order: "ASC" }}
                    filter={{ driver_ID: formData.driver_ID }}
                    onChange={() => {
                      form.change("damages", [])
                    }}
                    record={record}
                    filterToQuery={(searchText) => {
                      searchText
                    }}
                  >
                    <AutocompleteInput
                      resettable
                      optionText={(rec) => {
                        if (rec === null) return ""

                        let text = rec.plate
                        if (rec.brand !== null && rec.model !== null)
                          text += ` - ${rec.brand} ${rec.model}`
                        else if (rec.brand !== null && rec.model === null)
                          text += ` - ${rec.brand}`
                        else if (rec.brand === null && rec.model !== null)
                          text += ` - ${rec.model}`
                        return text
                      }}
                    />
                  </ReferenceInput>
                </Box>
                <Box ml="0.5em" pt="1em">
                  <CarQuickCreateButton
                    disabled={!formData.driver_ID}
                    driver_ID={formData.driver_ID}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </FormDataConsumer>
      </Box>
      <TextInput
        source="userDetails"
        resource={resource}
        multiline
        label="Dettagli sull'accaduto"
        helperText="Tutte le informazioni fornite dall'utente"
        fullWidth
        rows={3}
        record={record}
      />
      <Divider style={{ margin: "1em 0em 1em 0em" }} />
      <Typography variant="h6">Dati di ritiro</Typography>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <TextInput
            source="actualCAP"
            validate={[required(), regex(regexZIP, "CAP non valido")]}
            resource={resource}
            multiline
            label="CAP"
            fullWidth
            inputProps={{ maxLength: 5 }}
            record={record}
          />
        </Box>
        <Box flex={3} ml="0.5em" mr="0.5em">
          <TextInput
            validate={required()}
            source="actualAddress"
            resource={resource}
            multiline
            label="Indirizzo"
            helperText="Luogo dove si trova il veicolo al momento"
            fullWidth
            rows={2}
            record={record}
          />
        </Box>
        <Box flex={2} ml="0.5em">
          <TextInput
            source="locationNotes"
            resource={resource}
            multiline
            label="Note sul luogo"
            helperText="Informazioni aggiuntive per capire dove ritirare il veicolo"
            fullWidth
            rows={2}
            record={record}
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box flex={1} mr="0.5em">
          <DateInput
            fullWidth
            parse={dateParser}
            validate={required()}
            resource={resource}
            label="Ritiro dal"
            source="pickupDateFrom"
            record={record}
          />
        </Box>
        <Box flex={1} ml="0.5em" mr="0.5em">
          <DateInput
            fullWidth
            validate={required()}
            parse={dateParser}
            resource={resource}
            label="Ritiro al"
            source="pickupDateTo"
            record={record}
          />
        </Box>
        <Box flex={2} ml="0.5em" mr="0.5em">
          <SelectInput
            choices={[
              { id: 0, name: "Nessun rumore" },
              { id: 1, name: "Lieve" },
              { id: 2, name: "Medio" },
              { id: 3, name: "Grave" },
            ]}
            label="Rumore del veicolo"
            fullWidth
            validate={required()}
            source="soundLevel"
            record={record}
          />
        </Box>
        <Box flex={4} ml="0.5em">
          <ReferenceArrayInput
            fullWidth
            label="Servizi Aggiuntivi Richiesti"
            source="addOns"
            reference="AddOns"
            resource={resource}
            record={record}
          >
            <SelectArrayInput optionText="name" fullWidth />
          </ReferenceArrayInput>
        </Box>
      </Box>
    </Box>
  )
}
const useImageFieldStyles = makeStyles(() => ({
  image: {
    // This will override the style of the <img> inside the <div>
    width: 170,
  },
}))
const DamageCreateForm = ({ formData }) => {
  const { data } = useGetOne("Cars", formData.car_ID)
  const { data: carTypeData } = useGetOne("CarTypes", data?.carType_ID)
  const classes = useImageFieldStyles()

  return (
    <ArrayInput label="Danni" source="damages">
      <SimpleFormIterator>
        <FormDataConsumer>
          {({
            formData,
            scopedFormData, // The data for this item of the ArrayInput
            getSource,
          }) => {
            return (
              <Box display="flex">
                <Box flex={1} mr="0.5em">
                  <ImageField
                    record={carTypeData}
                    classes={classes}
                    source={`imagePath_${
                      formData?.damages[
                        parseInt(getSource("side").split("[")[1].split("]")[0])
                      ]?.side ?? "F"
                    }`}
                  />
                </Box>
                <Box flex={3} ml="0.5em">
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <SelectInput
                        choices={[
                          { id: "F", name: "Anteriore" },
                          { id: "R", name: "Laterale Destro" },
                          { id: "L", name: "Laterale Sinistro" },
                          { id: "B", name: "Posteriore" },
                        ]}
                        label="Lato del veicolo"
                        fullWidth
                        validate={required()}
                        source={getSource("side")}
                        record={scopedFormData}
                      />
                    </Box>
                    <Box flex={1} ml="0.5em">
                      <ReferenceInput
                        fullWidth
                        label="Parte danneggiata"
                        record={scopedFormData}
                        source={getSource("carPart_ID")}
                        reference="CarParts"
                        perPage={5000}
                        validate={required()}
                        sort={{ field: "partIdentifier", order: "ASC" }}
                        filter={{
                          carType_ID: data.carType_ID ?? null,
                          side:
                            formData?.damages[
                              parseInt(
                                getSource("side").split("[")[1].split("]")[0]
                              )
                            ]?.side ?? null,
                        }}
                      >
                        <SelectInput
                          optionText={(currRec) =>
                            `${currRec.partIdentifier} - ${currRec.partName}`
                          }
                        />
                      </ReferenceInput>
                    </Box>
                  </Box>
                  <Box display="flex">
                    <Box flex={1} mr="0.5em">
                      <SelectInput
                        choices={[
                          { id: "Bollatura", name: "Bollatura" },
                          { id: "Ammaccatura", name: "Ammaccatura" },
                          { id: "Rottura", name: "Rottura" },
                          { id: "Altro", name: "Altro" },
                        ]}
                        fullWidth
                        validate={required()}
                        source={getSource("damageType")}
                        record={scopedFormData}
                        label="Tipo di danno"
                      />
                    </Box>
                    <Box flex={1} ml="0.5em">
                      <TextInput
                        fullWidth
                        source={getSource("description")}
                        record={scopedFormData}
                        label="Descrizione"
                        defaultValue={null}
                        multiline
                        rows={2}
                      />
                    </Box>
                  </Box>
                  <ImageInput
                    multiple
                    fullWidth
                    validate={(values) =>
                      values && values.length === 3
                        ? undefined
                        : "Le foto caricate devono essere esattamente 3"
                    }
                    source={getSource("pictures")}
                    record={scopedFormData}
                    label={false}
                    accept="image/*"
                    placeholder={<p>Carica le tue immagini qui</p>}
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                </Box>
              </Box>
            )
          }}
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export const QuoteCreate = (props) => {
  const notify = useNotify()
  const redirect = useRedirect()
  return (
    <Create
      {...props}
      onSuccess={() => {
        notify(
          "La richiesta è in creazione, a breve sarà disponibile sul portale.",
          "info",
          {},
          false
        )
        redirect("list", props.basePath)
      }}
    >
      <TabbedForm
        redirect="list"
        warnWhenUnsavedChanges
        validate={validateQuoteCreation}
        initialValues={{
          requestDate: new Date(),
          pickupDateFrom: new Date(),
          pickupDateTo: new Date(),
          locationNotes: null,
          userDetails: null,
        }}
      >
        <FormTab label="Riepilogo">
          <QuoteCreateForm />
        </FormTab>
        <FormTab label="Danni">
          <FormDataConsumer>
            {({ formData }) =>
              formData.car_ID ? (
                <DamageCreateForm formData={formData} />
              ) : (
                <Alert severity="error">
                  Devi prima selezionare un veicolo
                </Alert>
              )
            }
          </FormDataConsumer>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}
